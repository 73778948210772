import { Database } from "@mwater/ui-builder"
import { ReactChildren, useEffect, useState, Fragment, ReactChild } from "react"
import React from "react"

/** Component which changes key, forcing a reload if database changes */
export const RefreshOnDatabaseChange = (props: { database: Database; children: ReactChild }) => {
  const [key, setKey] = useState(0)

  useEffect(() => {
    const changeListener = () => {
      setKey((k) => k + 1)
    }
    props.database.addChangeListener(changeListener)
    return () => {
      props.database.removeChangeListener(changeListener)
    }
  })

  return <Fragment key={key}>{props.children}</Fragment>
}
