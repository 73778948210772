import moment from "moment"

/** Data for the impact tracker summary */
export interface ImpactTrackerSummary {
  targetLoans: number
  targetPeople: number
  targetCapital: number
  targetCapitalUsd: number

  targetToDateLoans: number
  targetToDatePeople: number
  targetToDateCapital: number
  targetToDateCapitalUsd: number

  actualLoans: number
  actualPeople: number
  actualCapital: number
  actualCapitalUsd: number

  disqualLoans: number
  disqualPeople: number
  disqualCapital: number
  disqualCapitalUsd: number
}

/** Snapshot of impact */
export interface Snapshot {
  id: string
  date: string
  notes: string
}

/** Snapshot data */
export interface SnapshotData {
  actuals: Actual[]
  forecasts: Forecast[]
  targets: Target[]
}

/** Actual impact */
export interface Actual {
  /** e.g. 2020-03-01 */
  month: string

  /** Beneficiary type id (since can be broken down by type) */
  beneficiaryType?: number

  loans: number

  people: number

  capital: number
}

/** Forecast impact */
export interface Forecast {
  /** Primary key in wo_achibeneficiary. undefined if not inserted yet or aggregate */
  id?: number

  /** e.g. 2020-03-01 */
  month: string

  loans: number | null

  people: number | null

  capital: number | null
}

/** Target impact */
export interface Target {
  /** Primary key in wo_planbeneficiary. undefined if not inserted yet or if aggregate */
  id?: number

  /** e.g. 2020-03-01 */
  month: string

  loans: number | null

  people: number | null

  capital: number | null
}

/** Date range */
export interface Period {
  /** Start date (inclusive) e.g. 2021-02-01 */
  start: string

  /** End date (inclusive) e.g. 2021-05-31 */
  end: string

  /** Months in period */
  months: string[]
}

/** Calculate periods that include start and end dates */
export function calculatePeriods(startDate: string, endDate: string, dateMode: "month" | "quarter"): Period[] {
  const periods: Period[] = []
  if (dateMode == "month") {
    const date = moment(startDate, "YYYY-MM-DD").startOf("month")

    while (date.isBefore(moment(endDate, "YYYY-MM-DD"))) {
      periods.push({
        start: date.format("YYYY-MM-DD"),
        end: moment(date).endOf("month").format("YYYY-MM-DD"),
        months: [date.format("YYYY-MM-DD")]
      })
      date.add(1, "month")
    }
  } else {
    const date = moment(startDate, "YYYY-MM-DD").startOf("quarter")

    while (date.isBefore(moment(endDate, "YYYY-MM-DD"))) {
      const month1 = date.format("YYYY-MM-DD")
      const month2 = moment(date).add(1, "month").format("YYYY-MM-DD")
      const month3 = moment(date).add(2, "month").format("YYYY-MM-DD")

      const months = []
      // If should be included
      if (month1.substr(0, 7) >= startDate.substr(0, 7) && month1 < endDate) {
        months.push(month1)
      }
      // If should be included
      if (month2.substr(0, 7) >= startDate.substr(0, 7) && month2 < endDate) {
        months.push(month2)
      }
      // If should be included
      if (month3.substr(0, 7) >= startDate.substr(0, 7) && month3 < endDate) {
        months.push(month3)
      }

      periods.push({
        start: date.format("YYYY-MM-DD"),
        end: moment(date).endOf("quarter").format("YYYY-MM-DD"),
        months
      })
      date.add(1, "quarter")
    }
  }

  return periods
}
