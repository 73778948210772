import React, { useMemo, useState } from "react"
import ActionCancelModal from "@mwater/react-library/lib/ActionCancelModalComponent"
import { FormGroup, TextInput } from "@mwater/react-library/lib/bootstrap"
import { SiteDesign } from "../common/SiteDesign"
import { User } from "../common/User"
import { createSchema } from "../common/utils"
import { createDatabase } from "./ClientDatabase"
import { ClientDataSource } from "./ClientDataSource"

/** Modal that allows reporting a problem */
export function ReportProblemModal(props: {
  loginUser: User
  viewUser: User
  onClose: () => void
  siteDesign: SiteDesign
}) {
  const [summary, setSummary] = useState("")
  const [details, setDetails] = useState("")
  const [sending, setSending] = useState(false)
  const [screenshot, setScreenshot] = useState(null);
  const [image, setImage] = useState(null);
  // Create schema, data source and database
  const schema = useMemo(() => createSchema(props.siteDesign), [])
  const dataSource = useMemo(() => new ClientDataSource(), [])
  const database = useMemo(() => createDatabase(schema, dataSource), [])
  const date    = new Date();
  const year    = date.getFullYear();
  const month   = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day     = String(date.getDate()).padStart(2, '0');
  const hours   = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  async function handleSend() {
    try {
      setSending(true)

      // Create record to add
      const txn = database.transaction()
      await txn.addRow("problem_reports", {
        datetime: `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
        summary,
        details,
        screenshot,
        login_user: props.loginUser.user_id,
        view_user: props.viewUser.user_id,
        url: window.location.href,
        debug_info: ""
      })
      await txn.commit()
      props.onClose()
    } catch (err: any) {
      alert("Error reporting problem: " + err.message)
    } finally {
      setSending(false)
    }
  }


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result.split(",")[1];
                const image = reader.result;
                setImage(reader.result);
                setScreenshot(base64);
               // console.log('base64 is ' + base64)
               // console.log('reader.result is '+reader.result)
            };
            reader.readAsDataURL(file);
        }
    };

  return (
    <ActionCancelModal
      actionLabel="Send"
      onAction={handleSend}
      onCancel={props.onClose}
      actionBusy={sending}
      size="large"
      title="Request feature or report problem"
    >
      <FormGroup label="Summary of Feature Request or Problem">
        <TextInput value={summary} onChange={setSummary} placeholder="One-line summary of feature request or the problem" />
      </FormGroup>

      <FormGroup label="Details of Feature Request or Problem">
        <textarea
          className="form-control"
          value={details}
          onChange={(ev) => setDetails(ev.target.value)}
          rows={5}
          placeholder={`Please provide as much information as possible about the feature you are requesting or the problem you are encountering.`}
        />
          </FormGroup>
          <FormGroup label="Send an image (optional)">
              <input type="file" accept="image/*" onChange={handleFileChange} />
              {image && <img src={image} alt="Preview" style={{ marginTop: '20px', maxWidth: '100%' }} />}

          </FormGroup>
    </ActionCancelModal>
  )
}
