import { Page, HashHistoryPageStackDisplay } from "@mwater/ui-builder"
import { useMemo } from "react"
import { ClientDataSource } from "../ClientDataSource"
import React from "react"
import { useCreateBaseCtx } from "../uiBuilderSetup"
import { createSchema } from "../../common/utils"
import { SiteDesign } from "../../common/SiteDesign"
import { createGlobalContextVars, createGlobalContextVarValues } from "../../common/variables"
import { User } from "../../common/User"
import { HashHistory, HashLocation } from "@mwater/react-library/lib/HashHistory"
import UrlPattern from "url-pattern"
import querystring from "querystring"

/** Displays a widget based on the current route. It looks up the
 * route in the SiteDesign routes and displays the appropriate widget in a new
 * page stack. If the page opens a modal, that goes on the same stack, but otherwise
 * it constructs the new URL and pushes it using hash history.
 * 
 * Note: the optional hash part of the hash url is ignored in routing.
 */
export function WidgetRoutePage(props: {
  siteDesign: SiteDesign

  hashHistory: HashHistory

  /** Current login user */
  loginUser: User

  /** Current view user (different from login user if crossed over) */
  viewUser: User
}) {
  const { siteDesign, hashHistory } = props

  // Create schema
  const schema = useMemo(() => createSchema(siteDesign), [siteDesign])
  const dataSource = useMemo(() => new ClientDataSource(), [])

  const baseCtx = useCreateBaseCtx({
    schema,
    dataSource,
    locale: "en",
    widgetLibrary: siteDesign.widgetLibrary
  })

  function locationToPage(location: HashLocation): Page | null {
    // Convert location to a proto-page
    const protoPage = locationToProtoPage(location, siteDesign)
    if (!protoPage) {
      return null
    }

    // Create context variables
    const globalContextVarValues = createGlobalContextVarValues(props.loginUser, props.viewUser)
    const contextVarValues = { ...globalContextVarValues, ...protoPage.contextVarValues }

    // Create page
    const page: Page = {
      type: "normal",
      database: baseCtx.database,
      widgetId: protoPage.widgetId,
      contextVarValues,
      title: protoPage.title,
    }

    return page
  }

  function pageToLocation(page: Page): string {
    return convertPageToLocation(page, siteDesign)
  }

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <HashHistoryPageStackDisplay 
        hashHistory={hashHistory} 
        baseCtx={baseCtx} 
        locationToPage={locationToPage}
        pageToLocation={pageToLocation}
      />
    </div>
  )
}

/** Convert a page to a URI */
function convertPageToLocation(page: Page, siteDesign: SiteDesign): string {
  // Remove global variables
  const contextVarValues = { ...page.contextVarValues }
  for (const globalVar of createGlobalContextVars()) {
    delete contextVarValues[globalVar.id]
  }

  // Find route
  for (const route of siteDesign.routes || []) {
    if (route.widgetId == page.widgetId) {
      let uri = route.pattern
      let query: any = {}

      // Title is encoded as query param _title
      if (page.title) {
        query._title = page.title
      }

      // Substitute params
      for (const param of route.params || []) {
        if (param.type == "path") {
          uri = uri.replace(":" + param.paramId, encodeURIComponent(contextVarValues[param.contextVarId]))
        }
        if (param.type == "query") {
          const value = contextVarValues[param.contextVarId]
          query[param.paramId] = "" + value
        }
      }

      return uri + (querystring.stringify(query) ? "?" + querystring.stringify(query) : "")
    }
  }

  // Fallback
  const query = contextVarValues

  // Title is encoded as query param _title
  if (page.title) {
    query._title = page.title
  }
  return `/widgets/${page.widgetId}?vars=${encodeURIComponent(JSON.stringify(query))}`
}

/** Convert to location to page */
function locationToProtoPage(
  location: HashLocation,
  siteDesign: SiteDesign
): {
  widgetId: string
  contextVarValues: {
    [contextVarId: string]: any
  },
  title?: string
} | null {
  // Parse query parameters
  const query = querystring.parse(location.search ? location.search.substr(1) : "")

  // To check for match
  const match = (pattern: string) => new UrlPattern(pattern).match(location.pathname)

  // Check routes
  for (const route of siteDesign.routes || []) {
    let result = match(route.pattern)
    if (result) {
      // Parse parameters to pass to page
      const widgetId = route.widgetId
      const contextVarValues: { [contextVarId: string]: any } = {}
      const title = query._title as string | undefined

      for (const param of route.params || []) {
        // Get variable type
        const widget = siteDesign.widgetLibrary.widgets[widgetId]
        const contextVar = widget.contextVars.find((cv) => cv.id == param.contextVarId)
        if (!contextVar) {
          throw new Error(`Context variable ${param.contextVarId} not found`)
        }

        // Get raw value
        var rawValue: any
        if (param.type == "path") {
          rawValue = result[param.paramId]
        } else {
          rawValue = query[param.paramId]
        }

        // Parse raw value
        if (param.parseAs == "number" && rawValue != null) {
          rawValue = parseFloat(rawValue)
        }

        // Map raw value
        if (contextVar.type == "row") {
          contextVarValues[contextVar.id] = rawValue
        } else if (contextVar.type == "text" || contextVar.type == "number") {
          contextVarValues[contextVar.id] = { type: "literal", valueType: contextVar.type, value: rawValue }
        } else {
          throw new Error(`Unsupported context variable type ${contextVar.type}`)
        }
      }

      return { widgetId, contextVarValues, title }
    }
  }

  // Fallback
  let result = match("/widgets/:widgetId")
  if (result) {
    // Parse contextVarValues
    const contextVarValues = JSON.parse(query["vars"] as string)

    // "_title" is special encoding in query params
    const title = contextVarValues._title
    delete contextVarValues._title

    return { widgetId: result.widgetId, contextVarValues, title }
  }

  return null
}
