import _ from "lodash"
import { Schema, ExprCompiler, Expr } from "@mwater/expressions"
import { ContextVar, createExprVariables, createExprVariableValues, Filter, InstanceCtx } from "@mwater/ui-builder"
import { JsonQLFilter } from "@mwater/visualization"

/** Compute filters for a dashboard/widget */
function computeFilters({
  schema,
  contextVars,
  contextVarValues,
  filterContextVars,
  getFilters,
  excludeFilterId
}: {
  schema: Schema
  contextVars: ContextVar[]
  contextVarValues: {
    [contextVarId: string]: any
  }
  filterContextVars?: string[]
  getFilters(contextVarId: string): Filter[]
  /** Filter id to exclude if present. Used to prevent widgets from filtering themselves */
  excludeFilterId?: string
}) {
  // Compute filters
  const filters: JsonQLFilter[] = []

  for (const filterContextVarId of filterContextVars || []) {
    // Get value of rowset
    const value = contextVarValues[filterContextVarId]
    const filterContextVar = contextVars.find((cv) => cv.id == filterContextVarId)

    if (filterContextVar) {
      const exprCompiler = new ExprCompiler(
        schema.addVariables(createExprVariables(contextVars), createExprVariableValues(contextVars, contextVarValues))
      )

      // Compile rowset filter
      if (filterContextVar.type === "rowset") {
        const cvFilters = getFilters(filterContextVarId).filter((f) => f.id != excludeFilterId)

        // Add filters that are applied
        const filterExpr: Expr = {
          type: "op",
          op: "and",
          table: filterContextVar.table!,
          exprs: _.compact([value].concat(cvFilters.map((f) => f.expr)))
        }

        if (filterExpr.exprs.length > 0) {
          filters.push({
            table: filterContextVar.table!,
            jsonql: exprCompiler.compileExpr({ expr: filterExpr, tableAlias: "{alias}" })!
          })
        }
      } else if (filterContextVar.type === "row" && value) {
        // For a row variable, the value is the primary key, so make an expression that filters to it
        const expr: Expr = {
          type: "op",
          op: "=",
          table: filterContextVar.table!,
          exprs: [
            { type: "id", table: filterContextVar.table! },
            { type: "literal", valueType: "id", idTable: filterContextVar.table!, value: value }
          ]
        }
        filters.push({
          table: filterContextVar.table!,
          jsonql: exprCompiler.compileExpr({ expr, tableAlias: "{alias}" })!
        })
      }
    }
  }

  return filters
}

export default computeFilters
