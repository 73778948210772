import React from "react"
import UrlPattern from "url-pattern"
import { SiteDesign } from "../common/SiteDesign"
import querystring from "querystring"
import { WidgetRoutePage } from "./pages/WidgetRoutePage"
import { User } from "../common/User"
import { UploadReportPage } from "./pages/UploadReportPage"
import { ReportPage } from "./pages/reports/ReportPage"
import { ImpactTrackerPage } from "./pages/impact_tracker/ImpactTrackerPage"
import LoadingComponent from "@mwater/react-library/lib/LoadingComponent"
import { HashHistory } from "@mwater/react-library/lib/HashHistory"

/** Render the route which is the main pane */
export function renderRoute(options: {
  siteDesign: SiteDesign
  setSiteDesign: (design: SiteDesign) => void
  hashHistory: HashHistory
  loginUser: User
  viewUser: User
}) {
  const { siteDesign, setSiteDesign, hashHistory, loginUser, viewUser } = options

  const location = hashHistory.getLocation()

  // Check if partner
  const isPartner = options.loginUser.roles.includes(21)

  // Parse query parameters
  const query = querystring.parse(location.search ? location.search.substr(1) : "")

  // Check for match
  const match = (pattern: string) => new UrlPattern(pattern).match(location.pathname)

  let result: any

  // TODO Partners need better filtering
  if (isPartner) {
    let allowed = false

    if (
      match("/") ||
      match("/partner_settings") ||
      match("/watercredit_metrics") ||
      match("/upload_report") ||
      match("/review_reports") ||
      match("/reports/:id") ||
      match("/resource_library") ||
      match("/notifications") ||
      match("/wcpartner") ||
      match("/borrowertype") ||
      match("/country_regions(/*)") ||
      match("/country_attributes(/*)") ||
      match("/peoplereachedtypes") ||
      match("/producttypes") ||
      match("/location_of_improvement") ||
      match("/enterprise_impact")
    ) {
      allowed = true
    }

    if (!allowed) {
      return <div className="alert alert-warning">Not available to partners</div>
    }
  }

  // Legacy my_dashboard redirect TODO Remove
  if (match("/my_dashboard")) {
    setTimeout(() => {
      hashHistory.replace(`/my_dashboard/${viewUser.user_id}`)
    }, 0)
    return <div/>
  }

  // Main page
  if (match("/")) {
    // Send to correct page
    setTimeout(() => {
      // If partner
      if (loginUser.roles.includes(21)) {
        hashHistory.replace("/wcpartner")
      } else {
        hashHistory.replace("/global_dash")
      }
    })
    return <LoadingComponent />
  }

  if (match("/impact_tracker")) {
    return (
      <ImpactTrackerPage
        siteDesign={siteDesign}
        interventionId={parseInt(query["intervention"] as string)}
        partnerId={parseInt(query["partner"] as string)}
        snapshot={query["snapshot"] as string}
        loginUser={loginUser}
      />
    )
  }

  if (match("/upload_report")) {
    return (
      <div className="container">
        <UploadReportPage
          interventionId={parseInt(query.intervention as string)}
          partnerId={parseInt(query.partner as string)}
          loginUser={loginUser}
          viewUser={viewUser}
        />
      </div>
    )
  }

  result = match("/reports/:controlId")
  if (result) {
    return (
      <div className="container">
        <ReportPage
          siteDesign={siteDesign}
          loginUser={loginUser}
          viewUser={viewUser}
          controlId={parseInt(result.controlId)}
        />
      </div>
    )
  }

  return <WidgetRoutePage siteDesign={siteDesign} loginUser={loginUser} viewUser={viewUser} hashHistory={hashHistory} />
}
