import DatePicker from "react-datepicker"
import ReactDOM from "react-dom"
import moment from "moment"
import React from "react"

/** Simple YYYY-MM-DD date control */
export function DateControl(props: { value?: string; onChange?: (value: string) => void }) {
  function handleChange(date: moment.Moment) {
    props.onChange!(date.format("YYYY-MM-DD"))
  }

  return (
    <div>
      <style>{`
/* For https://github.com/Hacker0x01/react-datepicker/issues/624 */
.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

/* https://github.com/Hacker0x01/react-datepicker/issues/1141 */
.react-datepicker-popper {
  width: max-content;
}    
    `}</style>
      <DatePicker
        isClearable={false}
        disabled={!props.onChange}
        selected={props.value ? moment(props.value, "YYYY-MM-DD") : null}
        onChange={handleChange}
        showTimeSelect={false}
        dateFormat="YYYY-MM-DD"
        className="form-control"
        popperContainer={createPopperContainer}
        showMonthDropdown
        showYearDropdown
      />
    </div>
  )
}

// https://github.com/Hacker0x01/react-datepicker/issues/1366
function createPopperContainer(props: { children: React.ReactNode[] }): React.ReactNode {
  return ReactDOM.createPortal(<div style={{ zIndex: 10000 }}>{props.children}</div>, document.body)
}
