import _ from "lodash"
import React, { useState, useMemo, useEffect } from "react"
import { TablesEditor } from "./TablesEditor"
import { ClientDataSource } from "../ClientDataSource"
import { WidgetLibraryDesigner, WidgetLibrary } from "@mwater/ui-builder"
import { Schema, DataSource } from "@mwater/expressions"
import produce from "immer"
import FillDownwardComponent from "@mwater/react-library/lib/FillDownwardComponent"
import { useCreateBaseCtx, createBlockPaletteEntries } from "../uiBuilderSetup"
import { SiteDesign } from "../../common/SiteDesign"
import { createSchema } from "../../common/utils"
import { RoutesTab } from "./RoutesTab"
import { AdvancedTab } from "./AdvancedTab"
import { compare } from "fast-json-patch"
import { performJsonAPICall } from "../api"
import ErrorBoundary from "@mwater/ui-builder/lib/designer/ErrorBoundary"
import { HashHistory } from "@mwater/react-library/lib/HashHistory"

/**
 * Allows editing of widgets, tables, routes, etc.
 */
export const SiteDesignPage = (props: {
  siteDesign: SiteDesign
  onSiteDesignChange: (siteDesign: SiteDesign) => void
  hashHistory: HashHistory
}) => {
  // Current tab
  const [tab, setTab] = useState("widgets")

  /** True when saving */
  const [saving, setSaving] = useState(false)

  /** True when refreshing */
  const [refreshing, setRefreshing] = useState(false)

  // Current site design
  const [currentSiteDesign, setCurrentSiteDesign] = useState(props.siteDesign)

  const schema = useMemo(() => createSchema(currentSiteDesign), [currentSiteDesign])
  const dataSource = useMemo(() => new ClientDataSource(), [])

  // Prevent leaving before save
  useEffect(() => {
    return props.hashHistory.addBlockerListener(() => {
      if (currentSiteDesign != props.siteDesign) {
        return !confirm("Leave without saving?")
      }
      return false
    })
  }, [currentSiteDesign, props.siteDesign])

  // Prevent reloading page before save
  useEffect(() => {
    if (currentSiteDesign != props.siteDesign) {
      const eventListener = (e: BeforeUnloadEvent) => {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = ""
      }

      window.addEventListener("beforeunload", eventListener)
      return () => {
        window.removeEventListener("beforeunload", eventListener)
      }
    }
  }, [currentSiteDesign, props.siteDesign])

  const renderTab = () => {
    if (tab == "tables") {
      return (
        <TablesEditor
          tables={currentSiteDesign.tables}
          onChange={(tables) => {
            setCurrentSiteDesign({ ...currentSiteDesign, tables })
          }}
          schema={schema}
          dataSource={dataSource}
        />
      )
    }
    if (tab == "widgets") {
      return (
        <FillDownwardComponent>
          <WidgetLibraryTab
            schema={schema}
            dataSource={dataSource}
            widgetLibrary={currentSiteDesign.widgetLibrary}
            onWidgetLibraryChange={(widgetLibrary) => {
              setCurrentSiteDesign(
                produce(props.siteDesign, (draft) => {
                  draft.widgetLibrary = widgetLibrary
                })
              )
            }}
          />
        </FillDownwardComponent>
      )
    }
    if (tab == "routes") {
      return <RoutesTab design={currentSiteDesign} onDesignChange={setCurrentSiteDesign} />
    }
    if (tab == "advanced") {
      return <AdvancedTab design={currentSiteDesign} onChange={setCurrentSiteDesign} />
    }
  }

  const save = () => {
    setSaving(true)
    updateSiteDesign(currentSiteDesign, props.siteDesign)
      .then((newSiteDesign) => {
        setSaving(false)
        setCurrentSiteDesign(newSiteDesign)
        props.onSiteDesignChange(newSiteDesign)
      })
      .catch((err) => {
        setSaving(false)
        alert("Error saving: " + err.message)
      })
  }

  async function refresh() {
    setRefreshing(true)
    try {
      const response = await fetch("/api/get_site_design")
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const newSiteDesign = await response.json()
      setCurrentSiteDesign(newSiteDesign)
      props.onSiteDesignChange(newSiteDesign)
    } catch (err: any) {
      alert("Failed to refresh")
      return
    } finally {
      setRefreshing(false)
    }
  }

  const needsSave = props.siteDesign != currentSiteDesign

  return (
    <div>
      <div
        key="left"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          width: 200,
          borderRight: "solid 1px #AAA",
          backgroundColor: "#F8F8F8",
          padding: 5
        }}
      >
        <div key="button" style={{ paddingTop: 10, paddingBottom: 20 }}>
          {needsSave ? (
            <div className="d-grid pb-1">
              <button type="button" className={"btn btn-primary"} disabled={saving} onClick={save}>
                {saving ? <i className="fa fa-spinner fa-spin" /> : <i className="fas fa-save" />} Save
              </button>
            </div>
          ) : (
            <div className="d-grid pb-1">
              <button type="button" className="btn btn-secondary" disabled={refreshing} onClick={refresh}>
                <i className="fas fa-sync" /> Refresh
              </button>
            </div>
          )}
        </div>
        <NavPills
          options={[
            { value: "widgets", label: "Widgets" },
            { value: "tables", label: "Tables" },
            { value: "routes", label: "Routes" },
            { value: "advanced", label: "Advanced" }
          ]}
          value={tab}
          onChange={setTab}
        />
      </div>

      <div style={{ marginLeft: 200, paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
        <ErrorBoundary>{renderTab()}</ErrorBoundary>
      </div>
    </div>
  )
}

const   NavPills = (props: {
  options: {
    value: string
    label: string
  }[]
  value: string
  onChange: (value: string) => void
}) => {
  return (
    <ul className="nav nav-pills flex-column">
      {props.options.map((option) => (
        <li
          className="nav-item"
          onClick={() => {
            props.onChange(option.value)
          }}
          style={{ cursor: "pointer" }}
        >
          <a className={props.value == option.value ? "nav-link active" : "nav-link"}>{option.label}</a>
        </li>
      ))}
    </ul>
  )
}

const WidgetLibraryTab = (props: {
  schema: Schema
  dataSource: DataSource
  widgetLibrary: WidgetLibrary
  onWidgetLibraryChange: (widgetLibrary: WidgetLibrary) => void
}) => {
  const [openTabs, setOpenTabs] = useState<string[]>([])
  const baseCtx = useCreateBaseCtx({
    schema: props.schema,
    dataSource: props.dataSource,
    locale: "en",
    widgetLibrary: props.widgetLibrary
  })

  const blockPaletteEntries = useMemo(() => createBlockPaletteEntries(), [])

  return (
    <WidgetLibraryDesigner
      baseCtx={baseCtx}
      blockPaletteEntries={blockPaletteEntries}
      dataSource={props.dataSource}
      openTabs={openTabs}
      onOpenTabsChange={setOpenTabs}
      onWidgetLibraryChange={props.onWidgetLibraryChange}
    />
  )
}

/** Set domain design using patch */
async function updateSiteDesign(newSiteDesign: SiteDesign, oldSiteDesign: SiteDesign): Promise<SiteDesign> {
  // Create patch
  const patch = compare(oldSiteDesign, newSiteDesign, true)

  return performJsonAPICall("/api/patch_site_design", patch)
}
