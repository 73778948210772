import React, { useState, useRef, useEffect } from "react"
import { User } from "../../common/User"
import DatePicker from "react-datepicker"
import { performJsonAPICall, useLoadData } from "../api"
import moment from "moment"
import { ReportStatus } from "../../common/reports"
import { Toggle } from "@mwater/react-library/lib/bootstrap"

/** Upload a report */
export const UploadReportPage = (props: {
  loginUser: User
  viewUser: User
  interventionId: number
  partnerId: number
}) => {
  const [setupData, setupError] = useLoadData("/api/upload_report_setup", {
    interventionId: props.interventionId,
    partnerId: props.partnerId
  })

  const [controlId, setControlId] = useState<number>()

  const [dateFormat, setDateFormat] = useState("MM/DD/YYYY")

  const [reportDate, setReportDate] = useState<Date>(() => {
    const rd = new Date()
    rd.setMonth(new Date().getMonth() - 1)
    return rd
  })

  // Set up username
  const userName = props.loginUser.username

  /** Ref for the file field */
  const fileRef = useRef<HTMLInputElement | null>(null)

  // Flag that is set when waiting for response from server
  const [busy, setBusy] = useState(false)

  async function checkForUpdate() {
    try {
      if (!controlId) {
        return
      }

      const reportStatus = await performJsonAPICall<ReportStatus>("/api/get_report_status", { controlId: controlId })
      if (reportStatus.status != "Uploading") {
        window.location.hash = `/reports/${controlId}`
      }
    } catch (err: any) {
      console.error("Error getting status")
      return
    }
  }

  useEffect(() => {
    const interval = setInterval(checkForUpdate, 1000)
    return () => clearInterval(interval)
  }, [controlId])

  if (setupError) {
    return <div className="alert alert-danger">{setupError.message}</div>
  }
  if (!setupData) {
    return <div>Loading...</div>
  }

  async function handleSubmit() {
    if (!fileRef.current || !fileRef.current.files) {
      alert("File required")
      return
    }

    setBusy(true)

    try {
      // Gather the form data
      let myForm = document.getElementById("myForm") as HTMLFormElement
      let formData = new FormData(myForm)

      formData.append("user", userName)
      formData.append("report_date", reportDate.toISOString().substring(0, 10))
      formData.append("date_format", dateFormat)
      formData.append("file", fileRef.current.files[0])

      // Perform the post
      const res = await fetch(`api/upload_report`, {
        method: "POST",
        body: formData
      })

      // Check if ok
      if (!res.ok) {
        alert("Error uploading. Please check the file")
        return
      }

      // Get the response as JSON
      const body = await res.json()
      setControlId(body.controlId)
    } catch (err: any) {
      // Catch other errors
      console.error(err.message)
      alert("Failed to upload. Please check your internet connection.")
    } finally {
      setBusy(false)
    }
  }

  return (
    <div>
      <style>
        {`
        td {
          padding: 5px;
        }

        /* Style the buttons */
        .dateType {
          border: none;
          outline: none;
          padding: 10px 16px;
          background-color: #1474ac;
          cursor: pointer;
        }
        .dateType:hover, .dateType:focus, .dateType:active, .dateType.active, .open>.dropdown-toggle.btn-primary {
          color: #fff;
          background-color: #105b87;
        
      }
       
      `}
      </style>

      <form id="myForm">
        <input type="hidden" name="programId" value={setupData.intervention.programid} />
        <input type="hidden" name="interventionId" value={setupData.intervention.gid} />
        <input type="hidden" name="partnerId" value={setupData.partner.gid} />
        <input type="hidden" name="reportType" value={setupData.interventionPartner.report_type} />
        <table className="upload">
          <tbody>
            <tr>
              <td className="text-end">
                <h4>Intervention</h4>
              </td>
              <td>
                {" "}
                {setupData.intervention.programid}, {setupData.intervention.name}
              </td>
            </tr>
            <tr>
              <td className="text-end">
                <h4>Partner</h4>
              </td>
              <td> {setupData.partner.poname}</td>
            </tr>
            <tr>
              <td className="text-end">
                <h4>Report Type</h4>
              </td>
              <td> {setupData.interventionPartner.report_type} </td>
            </tr>
            <tr>
              <td className="text-end">
                <h4>Report Templates</h4>
              </td>
              <td>
                <a
                  className="report_template btn btn-primary"
                  target="_blank"
                  href={setupData.interventionPartner.report_template_csv}
                  download
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;CSV
                </a>
                <a
                  className="report_template btn btn-primary"
                  target="_blank"
                  href={setupData.interventionPartner.report_template_xls}
                  download
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;XLS
                </a>
                <a
                  className="report_template btn btn-primary"
                  target="_blank"
                  href={setupData.interventionPartner.report_template_xlsx}
                  download
                >
                  <i className="fa fa-download"></i>&nbsp;&nbsp;XLSX
                </a>
              </td>
            </tr>
            <tr>
              <td className="text-center" colSpan={2}>
                <h4>Actions Required</h4>
              </td>
            </tr>
            <tr>
              <td className="text-end">
                <h4>Step 1: Select Report Date</h4>
              </td>
              <td>
                {" "}
                <label>
                  {" "}
                  <DatePicker
                    className="form-control"
                    selected={moment(reportDate)}
                    onChange={(date) => setReportDate(date!.toDate())}
                  />
                  <i className="date-picker-icon btn btn-primary  fa-3x far fa-calendar" aria-hidden="true"></i>
                </label>
              </td>
            </tr>
            <tr>
              <td className="text-end pe-5">
                <h4>Step 2: Select Report Date Format</h4>
              </td>
              <td>
                <Toggle
                  value={dateFormat}
                  onChange={(value) => setDateFormat(value!)}
                  options={[
                    { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
                    { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
                    { value: "YYYY/MM/DD", label: "YYYY/MM/DD" }
                  ]}
                />
              </td>
            </tr>
            <tr>
              <td className="text-end pe-5">
                <h4>Step 3: Upload Report</h4>
              </td>
              <td>
                <input
                  type="file"
                  className="btn btn-primary"
                  accept=".csv,.xls,.xlsx"
                  ref={fileRef}
                  onChange={handleSubmit}
                  disabled={busy || controlId != null}
                />
                {busy ? (
                  <div>
                    {" "}
                    <button className="btn btn-primary">
                      <i className="fa fa-spinner fa-spin" /> Uploading report...
                    </button>
                  </div>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
        {controlId != null ? (
          <div className="alert alert-info">Processing report... please wait. This may take several minutes</div>
        ) : null}
        <p></p>
      </form>
    </div>
  );
}
