import * as React from "react"
import _ from "lodash"
import produce from "immer"
import { LeafBlock, DesignCtx, BlockDef, InstanceCtx, LabeledProperty, PropertyEditor, BlockStore } from "@mwater/ui-builder"
import {
  WidgetFactory,
  DirectWidgetDataSource,
  JsonQLFilter,
  WidgetScope,
  LocaleContextInjector
} from "@mwater/visualization"
import AutoSizeComponent from "@mwater/react-library/lib/AutoSizeComponent"
import { NumberInput, TextInput, FormGroup, Select, Toggle } from "@mwater/react-library/lib/bootstrap"
import { FilterSelector } from "./FilterSelector"
import computeFilters from "./computeFilters"
import { ListEditorComponent } from "@mwater/react-library/lib/ListEditorComponent"
import { useState, useEffect, useMemo } from "react"
import { RefreshOnDatabaseChange } from "./RefreshOnDatabaseChange"
import { performRowClickAction, RowClickAction, RowClickActionsEditor, validateRowClickActions } from "./rowClickAction"

/** Localizations of the widget for a specific locale */
interface VisualizationWidgetLocalization {
  /** Locale e.g. "es" */
  locale: string
  widgetDesign: object
}

export interface VisualizationWidgetBlockDef extends BlockDef {
  type: "mwater-visualization:widget"
  widgetType: string
  widgetDesign: object

  /** Localizations of the widget. Each is for a specific locale. Overrides the base widget design */
  localizations?: VisualizationWidgetLocalization[]

  /** Array of context variable ids of rowsets to include as filters */
  filterContextVars?: string[]

  /** Aspect ratio (w/h) of widget. Defaults to 4/3 */
  aspectRatio?: number | null

  /** True if auto height (don't set aspect ratio and ignore if set) */
  autoHeight?: boolean

  /** Row click actions */
  rowClickActions?: RowClickAction[]

  /** Width below which scales or scrolls */
  minimumWidth?: number | null

  /** What to do when below minimum width. Default is scale */
  belowMinimumWidth?: "scale" | "scroll" | null
}

export default class VisualizationWidgetBlock extends LeafBlock<VisualizationWidgetBlockDef> {
  apiUrl: string
  client?: string

  /** Optional lookup of string name to value. Used for {{branding}} and other replacement strings in text widget */
  namedStrings?: { [key: string]: string }

  constructor(options: {
    blockDef: VisualizationWidgetBlockDef
    apiUrl: string
    client?: string
    namedStrings?: { [key: string]: string }
  }) {
    super(options.blockDef)
    this.apiUrl = options.apiUrl
    this.client = options.client
    this.namedStrings = options.namedStrings
  }

  renderDesign(ctx: DesignCtx) {
    return (
      <VisualizationWidgetDesigner
        key={this.blockDef.id}
        ctx={ctx}
        blockDef={this.blockDef}
        apiUrl={this.apiUrl}
        client={this.client}
        namedStrings={this.namedStrings}
      />
    )
  }

  renderInstance(ctx: InstanceCtx) {
    const filters = computeFilters({
      schema: ctx.schema,
      contextVars: ctx.contextVars,
      contextVarValues: ctx.contextVarValues,
      filterContextVars: this.blockDef.filterContextVars,
      getFilters: ctx.getFilters,
      excludeFilterId: this.blockDef.id
    })

    return (
      <VisualizationWidgetInstance
        key={this.blockDef.id}
        apiUrl={this.apiUrl}
        blockDef={this.blockDef}
        ctx={ctx}
        filters={filters}
        client={this.client}
        namedStrings={this.namedStrings}
      />
    )
  }

  validate(designCtx: DesignCtx) {
    for (const filterContextVarId of this.blockDef.filterContextVars || []) {
      const filterContextVar = designCtx.contextVars.find((cv) => cv.id === filterContextVarId)
      // Allows non-existent ones as no easy way to remove with UI
      if (filterContextVar && filterContextVar.type !== "rowset" && filterContextVar.type !== "row") {
        return "Invalid filter variable"
      }
    }

    // Validate row click actions
    let error = validateRowClickActions(this.blockDef.rowClickActions || [], designCtx)

    return error
  }

  renderEditor(props: DesignCtx) {
    return (
      <div>
        <LabeledProperty label="Filters to Apply">
          <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="filterContextVars">
            {(value: string[] | undefined, onChange) => (
              <FilterSelector value={value} onChange={onChange} contextVars={props.contextVars} />
            )}
          </PropertyEditor>
        </LabeledProperty>

        <LabeledProperty label="Minimum Width (before scrolling or scaling)">
          <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="minimumWidth">
            {(value, onChange) => <WidthSelector value={value || null} onChange={onChange} sign="< " />}
          </PropertyEditor>
        </LabeledProperty>

        {this.blockDef.minimumWidth != null ? (
          <LabeledProperty label="When Below Minimum Width">
            <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="belowMinimumWidth">
              {(value, onChange) => (
                <Toggle
                  value={value || "scale"}
                  onChange={onChange}
                  options={[
                    { value: "scroll", label: "Scroll" },
                    { value: "scale", label: "Scale" }
                  ]}
                />
              )}
            </PropertyEditor>
          </LabeledProperty>
        ) : null}

        <LabeledProperty label="Localizations" hint="for locales other than the base locale">
          <ListEditorComponent<VisualizationWidgetLocalization>
            createNew={() => ({ locale: "", widgetDesign: this.blockDef.widgetDesign })}
            items={this.blockDef.localizations || []}
            onItemsChange={(items) =>
              props.store.replaceBlock({ ...this.blockDef, localizations: items } as VisualizationWidgetBlockDef)
            }
            renderEditor={(item, onChange) => (
              <LocalizationEditor localization={item as VisualizationWidgetLocalization} onChange={onChange} />
            )}
            renderItem={(item) => <div>{item.locale}</div>}
            validateItem={() => true}
            addLabel="Add Locale"
            deleteConfirmPrompt="Permanently delete locale?"
          />
        </LabeledProperty>

        {this.blockDef.autoHeight ? null : (
          <LabeledProperty label="Aspect Ratio (larger means less tall for width)">
            <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="aspectRatio">
              {(value: number | null | undefined, onChange) => (
                <NumberInput value={value} decimal={true} onChange={onChange} placeholder="1.333" />
              )}
            </PropertyEditor>
          </LabeledProperty>
        )}

        <LabeledProperty label="Row Click Actions">
          <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="rowClickActions">
            {(value, onChange) => (
              <RowClickActionsEditor
                designCtx={props}
                rowClickActions={value || []}
                onRowClickActionsChange={onChange}
              />
            )}
          </PropertyEditor>
        </LabeledProperty>
      </div>
    )
  }
}

/** Filter that was applied by a widget scoping */
interface ScopeFilter {
  contextVarId: string
  scope: WidgetScope | null
}

/** Displays an instance of the widget. */
const VisualizationWidgetInstance = (props: {
  blockDef: VisualizationWidgetBlockDef
  ctx: InstanceCtx
  filters: JsonQLFilter[]
  apiUrl: string
  client?: string
  namedStrings?: { [key: string]: string }
}) => {
  const [scopeFilter, setScopeFilter] = useState<ScopeFilter>()
  const widget = WidgetFactory.createWidget(props.blockDef.widgetType)

  // Stabilize filter value
  const filters = useMemo(() => props.filters, [JSON.stringify(props.filters)])

  // Listen for data refreshes and increment
  const [refreshKey, setRefreshKey] = useState(0)
  useEffect(() => {
    function changeListener() {
      setRefreshKey((rk) => rk + 1)
    }
    props.ctx.database.addChangeListener(changeListener)
    return () => props.ctx.database.removeChangeListener(changeListener)
  }, [props.ctx.database])

  const handleScopeChange = (scope: WidgetScope | null) => {
    // Clear if scope is null
    if (!scope) {
      // If one isn't set, ignore
      if (!scopeFilter) {
        return
      }
      const newScopeFilter = {
        contextVarId: scopeFilter.contextVarId,
        scope
      }
      props.ctx.setFilter(newScopeFilter.contextVarId, {
        id: props.blockDef.id,
        expr: null,
        memo: scope
      })
      setScopeFilter(newScopeFilter)
      return
    } else {
      // Find context variable to scope
      if (!props.blockDef.filterContextVars) {
        return
      }
      const filterCv = props.ctx.contextVars.find(
        (cv) => props.blockDef.filterContextVars!.includes(cv.id) && cv.table == scope.filter.table
      )
      if (!filterCv) {
        return
      }

      const newScopeFilter = {
        contextVarId: filterCv.id,
        scope
      }
      setScopeFilter(newScopeFilter)
      props.ctx.setFilter(filterCv.id, {
        id: props.blockDef.id,
        expr: scope.filterExpr,
        memo: scope
      })
    }
  }

  // Get widget design
  const localization = (props.blockDef.localizations || []).find((l) => l.locale == props.ctx.locale)
  const widgetDesign = localization ? localization.widgetDesign : props.blockDef.widgetDesign

  function renderWidget(width: number) {
    const outerDivStyle: React.CSSProperties = {
      width: "100%"
    }
    const innerDivStyle: React.CSSProperties = {}

    let widgetWidth = width
    let widgetHeight = props.blockDef.autoHeight ? undefined : widgetWidth / (props.blockDef.aspectRatio || 4.0 / 3)

    if (props.blockDef.minimumWidth != null && width < props.blockDef.minimumWidth) {
      widgetWidth = props.blockDef.minimumWidth
      widgetHeight = props.blockDef.autoHeight ? undefined : widgetWidth / (props.blockDef.aspectRatio || 4.0 / 3)

      // Scaling is tricky and requires a height on the outer div if known
      if ((props.blockDef.belowMinimumWidth || "scale") == "scale") {
        const scale = width / props.blockDef.minimumWidth
        innerDivStyle.transform = `scale(${scale})`
        innerDivStyle.width = widgetWidth
        innerDivStyle.transformOrigin = "top left"
        outerDivStyle.height = widgetHeight ? widgetHeight * scale : undefined
      } else if (props.blockDef.belowMinimumWidth == "scroll") {
        innerDivStyle.width = width
        innerDivStyle.overflowX = "scroll"
        widgetWidth = props.blockDef.minimumWidth
      }
    }

    return (
      <div style={outerDivStyle}>
        <div style={innerDivStyle} key={refreshKey}>
          {widget.createViewElement({
            schema: props.ctx.schema,
            dataSource: props.ctx.dataSource!,
            widgetDataSource: new DirectWidgetDataSource({
              widget: widget,
              schema: props.ctx.schema,
              dataSource: props.ctx.dataSource!,
              apiUrl: props.apiUrl,
              client: props.client
            }),
            design: widgetDesign,
            scope: scopeFilter ? scopeFilter.scope : null,
            filters,
            onScopeChange: handleScopeChange,
            onRowClick: (tableId, rowId) => {
              performRowClickAction(props.ctx, props.blockDef.rowClickActions || [], tableId, rowId)
            },
            width: widgetWidth,
            height: widgetHeight,
            namedStrings: props.namedStrings
          })}
        </div>
      </div>
    )
  }

  return (
    <RefreshOnDatabaseChange database={props.ctx.database}>
      <LocaleContextInjector locale={props.ctx.locale}>
        <AutoSizeComponent injectWidth>{({ width }) => renderWidget(width!)}</AutoSizeComponent>
      </LocaleContextInjector>
    </RefreshOnDatabaseChange>
  )
}

/** Edits a single localization, only allowing changes to the locale */
const LocalizationEditor = (props: {
  localization: VisualizationWidgetLocalization
  onChange: (localization: VisualizationWidgetLocalization) => void
}) => {
  return (
    <div>
      <FormGroup label="Code (e.g. 'en')">
        <TextInput
          value={props.localization.locale}
          onChange={(value) =>
            props.onChange(
              produce(props.localization, (draft) => {
                draft.locale = value!
              })
            )
          }
        />
      </FormGroup>
    </div>
  )
}

const VisualizationWidgetDesigner = (props: {
  ctx: DesignCtx
  blockDef: VisualizationWidgetBlockDef
  apiUrl: string
  client?: string
  namedStrings?: { [key: string]: string }
}) => {
  const widget = WidgetFactory.createWidget(props.blockDef.widgetType)

  const ctx = props.ctx

  // Determine current locale (use base locale if null override)
  const [localeOverride, setLocaleOverride] = useState<string | null>(null)
  const locale = localeOverride || ctx.locale

  const filters = useMemo(() => [], [])

  // Get widget design
  const localization = (props.blockDef.localizations || []).find((l) => l.locale == locale)
  const widgetDesign = localization ? localization.widgetDesign : props.blockDef.widgetDesign

  // Ensure that exists
  useEffect(() => {
    if (localeOverride && !localization) {
      setLocaleOverride(null)
    }
  }, [localization, localeOverride])

  function renderWidget(width: number) {
    return (
      <div style={{ position: "relative" }}>
        {widget.createViewElement({
          schema: ctx.schema,
          dataSource: ctx.dataSource,
          widgetDataSource: new DirectWidgetDataSource({
            widget: widget,
            schema: ctx.schema,
            dataSource: ctx.dataSource,
            apiUrl: props.apiUrl,
            client: props.client
          }),
          design: widgetDesign,
          scope: null,
          filters,
          onScopeChange: () => {
            return
          },
          onDesignChange: ctx.store
            ? (design) => {
                ctx.store!.alterBlock(
                  props.blockDef.id,
                  produce((b: VisualizationWidgetBlockDef) => {
                    // If no localization, edit base
                    if (!localization) {
                      b.widgetDesign = design
                    } else {
                      // Override localization
                      b.localizations!.find((l) => l.locale == localization.locale)!.widgetDesign = design
                    }
                    return b
                  }) as (blockDef: BlockDef) => BlockDef | null
                )
              }
            : null,
          width: width,
          height: props.blockDef.autoHeight ? undefined : width! / (props.blockDef.aspectRatio || 4.0 / 3),
          namedStrings: props.namedStrings
        })}
        {(props.blockDef.localizations || []).length > 0 ? (
          <Select
            size="sm"
            options={(props.blockDef.localizations || []).map((l) => ({ value: l.locale, label: l.locale }))}
            onChange={setLocaleOverride}
            nullLabel={ctx.locale}
            value={localeOverride}
            style={{ position: "absolute", top: 0, right: 35, width: "auto", zIndex: 1000 }}
          />
        ) : null}
      </div>
    )
  }

  return (
    <LocaleContextInjector locale={locale}>
      <AutoSizeComponent injectWidth>{({ width }) => renderWidget(width!)}</AutoSizeComponent>
    </LocaleContextInjector>
  )
}

function WidthSelector(props: {
  value: number | null
  onChange: (value: number | null) => void
  /** E.g. >=, <= */
  sign: string
}) {
  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      nullLabel="N/A"
      options={[
        { value: 200, label: `${props.sign}200px (1/2 Phone)` },
        { value: 300, label: `${props.sign}200px (1/2 Small tablet)` },
        { value: 400, label: `${props.sign}400px (Phone or 1/2 Small tablet)` },
        { value: 600, label: `${props.sign}600px (Small tablet)` },
        { value: 800, label: `${props.sign}800px (Tablet)` },
        { value: 1000, label: `${props.sign}1000px (Laptop)` },
        { value: 1200, label: `${props.sign}1200px (Desktop)` },
        { value: 1600, label: `${props.sign}1600px (Wide Desktop)` }
      ]}
    />
  )
}
