import _ from "lodash"
import { ContextVar } from "@mwater/ui-builder/lib/widgets/blocks"
import { Checkbox } from "@mwater/react-library/lib/bootstrap"
import React from "react"

/** Allows selecting filters to include */
export const FilterSelector = (props: {
  value?: string[]
  onChange: (value: string[]) => void
  contextVars: ContextVar[]
}) => {
  const handleChange = (contextVarId: string, value: boolean) => {
    if (value) {
      props.onChange(_.union(props.value || [], [contextVarId]))
    } else {
      props.onChange(_.difference(props.value || [], [contextVarId]))
    }
  }

  return (
    <div>
      {props.contextVars
        .filter((cv) => cv.type === "rowset" || cv.type === "row")
        .map((cv) => {
          return (
            <Checkbox key={cv.id} value={(props.value || []).includes(cv.id)} onChange={handleChange.bind(null, cv.id)}>
              {cv.name}
            </Checkbox>
          )
        })}
    </div>
  )
}
