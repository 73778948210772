import { default as React, useRef } from "react"
import { CollapsibleSection, FormGroup } from "@mwater/react-library/lib/bootstrap"
import FileSaver from "file-saver"
import { SecureTable, SiteDesign } from "../../common/SiteDesign"
import _ from "lodash";
import { WidgetDef } from "@mwater/ui-builder/lib/widgets/widgets";
import produce from "immer";

export function AdvancedTab(props: { design: SiteDesign; onChange: (design: SiteDesign) => void }) {
  const { design, onChange } = props

  function handleExport() {
    const blob = new Blob([JSON.stringify(props.design, null, 2)], { type: "text/json" })
    FileSaver.saveAs(blob, `siteDesign.json`)
  }

  return (
    <div>
      <FormGroup label="Import Design">
        <ImportButton design={design} onChange={onChange} />
      </FormGroup>
      <button type="button" className="btn btn-secondary" onClick={handleExport}>
        Export Design
      </button>
      <br/>
      <hr/>
      <CollapsibleSection initiallyOpen={false} label="Export/Import Widget">
        <FormGroup label="Import Widget From File">
          <ImportWidget design={design} onChange={onChange} />
        </FormGroup>
        <ExportWidget design={design} />
      </CollapsibleSection>
    <hr/>
    <CollapsibleSection initiallyOpen={false} label="Export/Import Table">
      <FormGroup label="Import Table From File">
        <ImportTable design={design} onChange={onChange} />
      </FormGroup>
      <ExportTable design={design} />
    </CollapsibleSection>
    </div>
  );
}

function ImportButton(props: { design: SiteDesign; onChange: (design: SiteDesign) => void }) {
  const handleUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()

    // Closure to capture the file data
    reader.onload = () => {
      if (!reader.result) {
        return
      }

      try {
        const design = JSON.parse(reader.result as string)
        props.onChange(design)
      } catch {
        alert("Invalid design file")
      }
    }

    reader.readAsText(event.target.files![0])
  }

  return <input type="file" className="form-control" onChange={handleUploadChange} />
}

function ImportWidget(props: { design: SiteDesign; onChange: (design: SiteDesign) => void }) {
  const handleUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()

    // Closure to capture the file data
    reader.onload = () => {
      if (!reader.result) {
        return
      }

      try {
        const widget = JSON.parse(reader.result as string)
        if (props.design.widgetLibrary.widgets[widget.id]) {
          alert(`Widget ${widget.id} already exists`)
          return
        }

        const newDesign = produce(props.design, draft => {
          draft.widgetLibrary.widgets[widget.id] = widget
        })

        props.onChange(newDesign)
      } catch {
        alert("Invalid widget file")
      }
    }

    reader.readAsText(event.target.files![0])
  }

  return <input type="file" className="form-control" onChange={handleUploadChange} />
}

function ExportWidget(props: {
  design: SiteDesign
}) {
  const handleExport = (widget: WidgetDef) => {
    const blob = new Blob([JSON.stringify(widget, null, 2)], { type: "text/json" })
    FileSaver.saveAs(blob, `${widget.name}.json`)
  }

  const widgets = _.sortBy(Object.values(props.design.widgetLibrary.widgets), w => w.name)
  
  return <div className="list-group">
    { widgets.map((widget) => {
      return <a key={widget.id} className="list-group-item list-group-item-action" onClick={() => handleExport(widget)}>
        {widget.name}
      </a>
      })
    }
  </div>
}

function ImportTable(props: { design: SiteDesign; onChange: (design: SiteDesign) => void }) {
  const handleUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()

    // Closure to capture the file data
    reader.onload = () => {
      if (!reader.result) {
        return
      }

      try {
        const table = JSON.parse(reader.result as string)
        if (props.design.tables.find(t => t.id === table.id)) {
          alert(`Table ${table.id} already exists`)
          return
        }

        const newDesign = produce(props.design, draft => {
          draft.tables.push(table)
        })

        props.onChange(newDesign)
      } catch {
        alert("Invalid table file")
      }
    }

    reader.readAsText(event.target.files![0])
  }

  return <input type="file" className="form-control" onChange={handleUploadChange} />
}

function ExportTable(props: {
  design: SiteDesign
}) {
  const handleExport = (table: SecureTable) => {
    const blob = new Blob([JSON.stringify(table, null, 2)], { type: "text/json" })
    FileSaver.saveAs(blob, `${table.id}.json`)
  }

  const tables = _.sortBy(Object.values(props.design.tables), w => w.name.en)
  
  return <div className="list-group">
    { tables.map((table) => {
      return <a key={table.id} className="list-group-item list-group-item-action" onClick={() => handleExport(table)}>
        {table.name.en} ({table.id})
      </a>
      })
    }
  </div>
}