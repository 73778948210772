import _ from "lodash"
import React, { useEffect, useState } from "react"
import { SiteHeader } from "./SiteHeader"
import { DragDropContext } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import LoadingComponent from "@mwater/react-library/lib/LoadingComponent"
import { SiteDesign } from "../common/SiteDesign"
import { renderRoute } from "./renderRoute"
import { User } from "../common/User"
import { SiteDesignPage } from "./site_design/SiteDesignPage"
import querystring from "querystring"
import { LoginPage } from "./pages/LoginPage"
import { HashHistory } from "@mwater/react-library/lib/HashHistory"
import { ExternalDashboardPage } from "./pages/ExternalDashboardPage"
import { WaterOrgTableSelectWrapper } from "./WaterOrgTableSelectComponent";
import { CustomColorsContext } from "@mwater/visualization/lib/CustomColorsContext"

/** History allows switching between URLs */
const hashHistory = new HashHistory()

const customColorData = {
  customColors: [
    "rgb(0,44,95)",
    "rgb(76,107,143)",
    "rgb(127,149,175)",
    "rgb(178,191,207)",
    null,
    null, 
    "rgb(99,177,229)",
    "rgb(145,200,237)",
    "rgb(177,216,242)",
    "rgb(208,231,247)",
    null,
    null,
    "rgb(111,111,111)",
    "rgb(154,154,154)",
    "rgb(183,183,183)",
    "rgb(212,212,212)",
    null,
    null,
  ]
}

/** Main app that loads the site design and renders the header and current page */
function App(props: {}) {
  const [location, setLocation] = useState(hashHistory.getLocation())
  const [siteDesign, setSiteDesign] = useState<SiteDesign>()
  const [loginUser, setLoginUser] = useState<User | null>(null)

  /** View user. Can be different from login user if the user is crossed-over */
  const [viewUser, setViewUser] = useState<User | null>(null)

  /** Setup the page, getting current user and site design */
  async function setup() {
    try {
      const loginResp = await (await fetch("/api/get_login")).json()
      setLoginUser(loginResp)
      setViewUser(loginResp)

      const sd = await (await fetch("/api/get_site_design")).json()
      setSiteDesign(sd)

      // Remove splash
      window.document.getElementById("splash")!.remove()
    } catch (err: any) {
      alert("Unable to load")
    }
  }
  useEffect(() => {
    setup()
  }, [])

  // Listen for changes to the current location.
  useEffect(() => hashHistory.addLocationListener(setLocation), [])

  // Record current page for google analytics
  useEffect(() => {
    // Send to google analytics
    ga('set', 'page', location.pathname)
    ga('send', 'pageview')
  }, [location.pathname])

  /** Log the current user out */
  function handleLogout() {
    fetch("/api/logout", { method: "POST" }).then((response) => {
      if (!response.ok) {
        alert("Unable to logout")
      }
      hashHistory.push("/login")

      setTimeout(() => {
        setLoginUser(null)
      }, 100)
    })
  }

  // Can't render until site is loaded
  if (!siteDesign) {
    return <LoadingComponent />
  }

  // Parse query parameters
  const query = querystring.parse(location.search ? location.search.substr(1) : "")

  // Login
  if (location.pathname == "/login") {
    return (
      <LoginPage
        onLogin={(user) => {
          setLoginUser(user)
          setViewUser(user)
          hashHistory.replace((query.next as string) || "/")
        }}
      />
    )
  }

  // External dashboards
  if (location.pathname.startsWith("/external_dashboards/")) {
    return <ExternalDashboardPage
      dashboardId={_.last(location.pathname.split("/"))}
      siteDesign={siteDesign}
    />
  }

  // Force login
  if (!loginUser || !viewUser) {
    hashHistory.push(`/login?${querystring.stringify({ next: location.pathname + location.search })}`)
    return null
  }

  // Admin page
  if (location.pathname == "/site_design") {
    // Only allow admins
    if (!loginUser || !loginUser.roles.includes(9)) {
      return <div className="alert alert-danger">Only for Admins</div>
    }

    return <WaterOrgTableSelectWrapper amAdmin={loginUser.roles.includes(9)}>
      <SiteDesignPage siteDesign={siteDesign} onSiteDesignChange={setSiteDesign} hashHistory={hashHistory} />
    </WaterOrgTableSelectWrapper>
  }

  return (
    <CustomColorsContext.Provider value={customColorData}>
      <WaterOrgTableSelectWrapper amAdmin={loginUser.roles.includes(9)}>
        <div className="main-page">
          <SiteHeader
            onLogout={handleLogout}
            loginUser={loginUser}
            viewUser={viewUser}
            siteDesign={siteDesign}
            onCrossover={(user) => {
              setViewUser(user)
              hashHistory.push("/")
            }}
          />
          <div key={viewUser ? viewUser.user_id : "None"}>
            {renderRoute({ siteDesign, setSiteDesign, hashHistory, loginUser, viewUser })}
          </div>
        </div>
      </WaterOrgTableSelectWrapper>
    </CustomColorsContext.Provider>
  )

}

// React-DnD is used in dashboards and in property editors. This wrapping is necessary
// to allow drag and drop to work within it
export default DragDropContext(HTML5Backend)(App)
