import _ from "lodash"
import FileSaver from "file-saver"
import React from "react"
import moment from "moment"
import produce from "immer"

import {
  ActionDef,
  OrderBy,
  Action,
  DesignCtx,
  createExprVariables,
  InstanceCtx,
  RenderActionEditorProps,
  LabeledProperty,
  PropertyEditor,
  ListEditor,
  LocalizedTextPropertyEditor,
  OrderByArrayEditor,
  ContextVarPropertyEditor,
  getFilteredContextVarValues,
  createExprVariableValues,
  QueryOptions
} from "@mwater/ui-builder"
import { LocalizedString, Expr, ExprValidator, Schema, DataSource, ExprUtils, localizeString } from "@mwater/expressions"
import { ExprComponent } from "@mwater/expressions-ui"

/** Action which exports a set of data from a table */
export interface ExportDataActionDef extends ActionDef {
  type: "waterorg:ExportData"

  /** Download filename */
  filename: LocalizedString | null

  /** Id of context variable of rowset for export to use */
  rowsetContextVarId: string | null

  /** Export columns */
  columns: ExportColumn[]

  where: Expr

  orderBy?: OrderBy[]
}

/** Single column to export */
interface ExportColumn {
  /** Column header */
  header?: LocalizedString | null

  /** Expression that column maps to */
  expr?: Expr
}

export class ExportDataAction extends Action<ExportDataActionDef> {
  validate(designCtx: DesignCtx) {
    // Validate rowset
    const rowsetCV = designCtx.contextVars.find(
      (cv) => cv.id === this.actionDef.rowsetContextVarId && cv.type === "rowset"
    )
    if (!rowsetCV) {
      return "Rowset required"
    }

    let error: string | null

    // Check each export column
    const exprValidator = new ExprValidator(designCtx.schema, createExprVariables(designCtx.contextVars))
    for (const column of this.actionDef.columns) {
      if (!column.expr) {
        return "Expression required"
      }

      // Validate expr
      error = exprValidator.validateExpr(column.expr, { table: rowsetCV.table })
      if (error) {
        return error
      }
    }

    // Validate where
    error = exprValidator.validateExpr(this.actionDef.where, { table: rowsetCV.table })
    if (error) {
      return error
    }

    // Validate orderBy
    for (const orderBy of this.actionDef.orderBy || []) {
      error = exprValidator.validateExpr(orderBy.expr, { table: rowsetCV.table })
      if (error) {
        return error
      }
    }

    return null
  }

  async performAction(instanceCtx: InstanceCtx): Promise<void> {
    // Get rowset context variable
    const rowsetCV = instanceCtx.contextVars.find((cv) => cv.id === this.actionDef.rowsetContextVarId)!
    const rowsetCVValue = instanceCtx.contextVarValues[rowsetCV.id]

    const exprUtils = new ExprUtils(instanceCtx.schema, createExprVariables(instanceCtx.contextVars))

    // Create where
    let where: Expr = {
      type: "op",
      op: "and",
      table: rowsetCV.table!,
      exprs: _.compact([rowsetCVValue].concat(_.map(instanceCtx.getFilters(rowsetCV.id), (f) => f.expr)))
    }

    // Add own where
    if (this.actionDef.where) {
      where.exprs.push(this.actionDef.where)
    }

    where = where.exprs.length > 0 ? where : null

    // Create select with columns
    const query: QueryOptions = {
      select: {},
      from: rowsetCV!.table!,
      where,
      orderBy: this.actionDef.orderBy
    }

    this.actionDef.columns.forEach((column, index) => {
      query.select[`c${index}`] = column.expr || null
    })

    let filename = localizeString(this.actionDef.filename, instanceCtx.locale) || "Download"

    // Replace ${date}
    filename = filename.replace("${date}", moment().format("YYYY-MM-DD"))

    // Run query
    const rows = await instanceCtx.database.query(
      query,
      instanceCtx.contextVars,
      getFilteredContextVarValues(instanceCtx)
    )

    function quote(input: string) {
      return '"' + (input + "").replace('"', '""') + '"'
    }

    // Convert to CSV
    function csvify(value: any, expr: Expr) {
      if (value == null) {
        return '""'
      }

      // Get type of expression
      const exprType = exprUtils.getExprType(expr)

      // Convert to string based on type
      switch (exprType) {
        case "text":
        case "id":
          return quote("" + value)
        case "boolean":
        case "enum":
        case "enumset":
        case "text[]":
          return quote(exprUtils.stringifyExprLiteral(expr, value, instanceCtx.locale))
        case "geometry":
          if (value.type == "Point") {
            return quote(`"${value.coordinates[1]} ${value.coordinates[0]}"`)
          }
          return quote(value.type)
        case "date":
          return quote(value)
        case "datetime":
          return quote(moment(value, moment.ISO_8601).format("YYYY-MM-DD HH:mm:ss"))
        case "image":
          return quote(`https://api.mwater.co/v3/images/${value.id}`)
        case "imagelist":
          return quote(_.map(value, (v: any) => `https://api.mwater.co/v3/images/${v.id}`).join("; "))
        default:
          return quote("" + value)
      }
    }

    let csv = this.actionDef.columns.map((c) => quote(localizeString(c.header) ?? "")).join(",") + "\n"

    for (const row of rows) {
      csv += this.actionDef.columns.map((c, ci) => csvify(row[`c${ci}`], c.expr || null)).join(",") + "\n"
    }

    const blob = new Blob([csv], { type: "text/csv" })
    FileSaver.saveAs(blob, filename + ".csv")
  }

  /** Render an optional property editor for the action. This may use bootstrap */
  renderEditor(props: RenderActionEditorProps): React.ReactElement<any> | null {
    const onChange = props.onChange as (actionDef: ExportDataActionDef) => void

    // Get rowset context variable
    const rowsetCV = props.contextVars.find((cv) => cv.id === this.actionDef.rowsetContextVarId)

    const handleAddColumn = () => {
      onChange(
        produce(this.actionDef, (draft) => {
          draft.columns.push({})
        })
      )
    }

    return (
      <div>
        <LabeledProperty label="Rowset">
          <PropertyEditor obj={this.actionDef} onChange={onChange} property="rowsetContextVarId">
            {(value, onChange) => (
              <ContextVarPropertyEditor
                value={value}
                onChange={onChange}
                contextVars={props.contextVars}
                types={["rowset"]}
              />
            )}
          </PropertyEditor>
        </LabeledProperty>
        <LabeledProperty label="Filename" help="Use ${date} for YYYY-MM-DD. Do not include extension">
          <PropertyEditor obj={this.actionDef} onChange={onChange} property="filename">
            {(value, onChange) => (
              <LocalizedTextPropertyEditor value={value || null} onChange={onChange} locale={props.locale} />
            )}
          </PropertyEditor>
        </LabeledProperty>
        {rowsetCV ? (
          <LabeledProperty label="Columns to Export">
            <PropertyEditor obj={this.actionDef} onChange={onChange} property="columns">
              {(columns, onColumnsChange) => (
                <ListEditor items={columns} onItemsChange={onColumnsChange}>
                  {(column: ExportColumn, onColumnChange: (value: ExportColumn) => void) => (
                    <ExportColumnEditor
                      value={column}
                      onChange={onColumnChange}
                      locale={props.locale}
                      schema={props.schema}
                      dataSource={props.dataSource}
                      table={rowsetCV.table!}
                    />
                  )}
                </ListEditor>
              )}
            </PropertyEditor>
            <button type="button" className="btn btn-link btn-sm" onClick={handleAddColumn}>
              <i className="fa fa-plus" /> Add Column
            </button>
          </LabeledProperty>
        ) : null}
        {rowsetCV ? (
          <LabeledProperty label="Filter">
            <PropertyEditor obj={this.actionDef} onChange={onChange} property="where">
              {(value: Expr, onChange) => (
                <ExprComponent
                  value={value}
                  onChange={onChange}
                  schema={props.schema}
                  dataSource={props.dataSource}
                  types={["boolean"]}
                  variables={createExprVariables(props.contextVars)}
                  table={rowsetCV!.table!}
                />
              )}
            </PropertyEditor>
          </LabeledProperty>
        ) : null}

        {rowsetCV ? (
          <LabeledProperty label="Ordering">
            <PropertyEditor obj={this.actionDef} onChange={onChange} property="orderBy">
              {(value, onChange) => (
                <OrderByArrayEditor
                  value={value}
                  onChange={onChange}
                  schema={props.schema}
                  dataSource={props.dataSource}
                  contextVars={props.contextVars}
                  table={rowsetCV!.table!}
                />
              )}
            </PropertyEditor>
          </LabeledProperty>
        ) : null}
      </div>
    )
  }
}

const ExportColumnEditor = (props: {
  value: ExportColumn
  onChange: (value: ExportColumn) => void
  locale: string
  schema: Schema
  dataSource: DataSource
  table: string
}) => {
  return (
    <div>
      <LabeledProperty label="Header">
        <PropertyEditor obj={props.value} onChange={props.onChange} property="header">
          {(value, onChange) => (
            <LocalizedTextPropertyEditor value={value || null} onChange={onChange} locale={props.locale} />
          )}
        </PropertyEditor>
      </LabeledProperty>
      <LabeledProperty label="Expression">
        <PropertyEditor obj={props.value} onChange={props.onChange} property="expr">
          {(value, onChange) => (
            <ExprComponent
              value={value || null}
              onChange={onChange}
              schema={props.schema}
              dataSource={props.dataSource}
              aggrStatuses={["individual"]}
              table={props.table}
            />
          )}
        </PropertyEditor>
      </LabeledProperty>
    </div>
  )
}
