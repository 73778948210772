import React, { useEffect, useState } from "react"
import { ReportStatus } from "../../../common/reports"
import { SiteDesign } from "../../../common/SiteDesign"
import { User } from "../../../common/User"
import { performJsonAPICall } from "../../api"
import { WidgetPage } from "../WidgetPage"
import { CriticalErrorsControl } from "./CriticalErrorsControl"
import { ResolveErrorsControl } from "./ResolveErrorsControl"

/** Allow user to do whatever the current state of the report requires */
export const ReportPage = (props: {
  siteDesign: SiteDesign
  loginUser: User
  viewUser: User // TODO which is the key one?
  controlId: number
}) => {
  const [reportStatus, setReportStatus] = useState<ReportStatus>()
  console.log("in report page getting status props.controlId is " + props.controlId)
  console.log("calling reload")

  function reload() {
    setReportStatus(undefined)
    performJsonAPICall<ReportStatus>("/api/get_report_status", { controlId: props.controlId })
      .then(setReportStatus)
      .catch((error) => alert("Error loading: " + error.message))
  }

  useEffect(reload, [props.controlId])

  if (!reportStatus) {
    return <div>Loading...</div>
  }

  switch (reportStatus.status) {
    case "Upload Error":
      return <div className="alert alert-danger">Upload Error: {reportStatus.errormsg}</div>
    case "Uploading":
      return <div className="alert alert-info">Report is still uploading...</div>
    // No longer used
    // case "Uploaded":
    //   return <ValidateControl
    //     reportStatus={reportStatus}
    //     controlId={props.controlId}
    //     reload={reload} />
    case "Critical Errors":
      return <CriticalErrorsControl reportStatus={reportStatus} reload={reload} />
    case "Resolve Errors":
      return <ResolveErrorsControl reportStatus={reportStatus} reload={reload} />
    case "Awaiting Review":
    case "Awaiting Approval":
    case "Awaiting Review 1":
    case "Awaiting Review 2":
    case "Approved":
    case "Request a new upload":
    case "Rejected":
      return (
        <WidgetPage
          widgetId="032cfe5d-598e-4a17-b331-f4a488e3aca1"
          siteDesign={props.siteDesign}
          loginUser={props.loginUser}
          viewUser={props.viewUser}
          contextVarValues={{
            "Control ID": reportStatus.control_id
          }}
        />
      )
    default:
      return <h1>Unknown status {reportStatus.status}</h1>
  }
}
