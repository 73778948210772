import React, { useCallback, useState } from "react"
import { Select } from "@mwater/react-library/lib/bootstrap"
import { ReportError, ReportStatus } from "../../../common/reports"
import { performJsonAPICall, useLoadData } from "../../api"

/** Control that displays critical errors */
export function CriticalErrorsControl(props: { reportStatus: ReportStatus; reload: () => void }) {
  const reportStatus = props.reportStatus

  // Load critical errors
  const [criticalErrors, error, reloadCriticalErrors] = useLoadData<ReportError[]>("/api/get_report_errors", {
    controlId: reportStatus.control_id
  })

  if (!criticalErrors) {
    return <div>Loading...</div>
  }

  /** Render a single critical error */
  function renderCriticalError(error: ReportError) {
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: error.error_message }} />
      </div>
    )
  }

  return (
    <div>
      <style>
        {`
        td {
          padding: 5px;
        }
      `}
      </style>
      <table className="upload">
        <tbody>
          <tr>
            <td className="text-end">
              <h4>Intervention</h4>
            </td>
            <td>
              {" "}
              {reportStatus.programid} {reportStatus.intervention_name}
            </td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Partner</h4>
            </td>
            <td> {reportStatus.partner_name}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Report Date</h4>
            </td>
            <td> {reportStatus.reportdate}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Control ID</h4>
            </td>
            <td> {reportStatus.control_id}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Report Type</h4>
            </td>
            <td> {reportStatus.upload_type}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Report Status</h4>
            </td>
            <td>
              {reportStatus.status} There were {criticalErrors.length} unique error(s) found in your file.
            </td>
          </tr>
        </tbody>
      </table>
      <p></p>
      <div>{criticalErrors.map(renderCriticalError)}</div>
    </div>
  );
}
