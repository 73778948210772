import React from "react"
import { ListEditorComponent } from "@mwater/react-library/lib/ListEditorComponent"
import {
  ActionDef,
  ActionDefEditor,
  ContextVar,
  DesignCtx,
  InstanceCtx,
  LabeledProperty,
  PropertyEditor,
  TableSelect
} from "@mwater/ui-builder"

/** Action that is defined to fire when a row is clicked
 * It injects a context variable "rowClickAction:<table id>" of type row
 */
export interface RowClickAction {
  /** Table that this action is for */
  table: string | null

  /** Action to perform when row is clicked */
  actionDef?: ActionDef | null
}

/** Validate row click actions */
export function validateRowClickActions(rowClickActions: RowClickAction[], designCtx: DesignCtx) {
  for (const rowClickAction of rowClickActions) {
    if (!rowClickAction.actionDef) {
      return "Action required"
    }
    if (!rowClickAction.table) {
      return "Table required"
    }

    const action = designCtx.actionLibrary.createAction(rowClickAction.actionDef)
    const cv: ContextVar = {
      id: `rowClickAction:${rowClickAction.table}`,
      name: "Row Clicked",
      type: "row",
      table: rowClickAction.table
    }

    const error = action.validate({
      ...designCtx,
      contextVars: designCtx.contextVars.concat([cv])
    })

    if (error) {
      return error
    }
  }

  return null
}

/** Perform appropriate row click action if present */
export async function performRowClickAction(
  instanceCtx: InstanceCtx,
  rowClickActions: RowClickAction[],
  tableId: string,
  rowId: any
) {
  // Find appropriate action that matches table
  const rowClickAction = rowClickActions.find((rca) => rca.table == tableId)
  if (!rowClickAction) {
    return
  }

  // Perform action (with context variable added)
  const action = instanceCtx.actionLibrary.createAction(rowClickAction.actionDef!)
  const cv: ContextVar = {
    id: `rowClickAction:${tableId}`,
    name: "Row Clicked",
    type: "row",
    table: tableId
  }

  await action.performAction({
    ...instanceCtx,
    contextVars: instanceCtx.contextVars.concat([cv]),
    contextVarValues: {
      ...instanceCtx.contextVarValues,
      [cv.id]: rowId
    }
  })
}

/** Edits a list of row click actions */
export function RowClickActionsEditor(props: {
  rowClickActions: RowClickAction[]
  onRowClickActionsChange: (rowClickActions: RowClickAction[]) => void
  designCtx: DesignCtx
}) {
  return (
    <ListEditorComponent
      items={props.rowClickActions}
      onItemsChange={props.onRowClickActionsChange}
      renderItem={(item, index, onItemChange) => (
        <RowClickActionEditor rowClickAction={item} onRowClickActionChange={onItemChange} designCtx={props.designCtx} />
      )}
      createNew={() => ({ table: null, actionDef: null })}
    />
  )
}

/** Edits a single row click action */
function RowClickActionEditor(props: {
  rowClickAction: RowClickAction
  onRowClickActionChange: (rowClickAction: RowClickAction) => void
  designCtx: DesignCtx
}) {
  function renderAction() {
    if (!props.rowClickAction.table) {
      return null
    }

    const cv: ContextVar = {
      id: `rowClickAction:${props.rowClickAction.table}`,
      name: "Row Clicked",
      type: "row",
      table: props.rowClickAction.table
    }

    return (
      <LabeledProperty label="When row clicked">
        <PropertyEditor obj={props.rowClickAction} onChange={props.onRowClickActionChange} property="actionDef">
          {(value, onChange) => (
            <ActionDefEditor
              value={value}
              onChange={onChange}
              designCtx={{ ...props.designCtx, contextVars: props.designCtx.contextVars.concat(cv) }}
            />
          )}
        </PropertyEditor>
      </LabeledProperty>
    )
  }

  return (
    <div>
      <LabeledProperty label="Table">
        <PropertyEditor obj={props.rowClickAction} onChange={props.onRowClickActionChange} property="table">
          {(value, onChange) => (
            <TableSelect
              schema={props.designCtx.schema}
              locale={props.designCtx.locale}
              value={value}
              onChange={onChange}
            />
          )}
        </PropertyEditor>
      </LabeledProperty>
      {renderAction()}
    </div>
  )
}
