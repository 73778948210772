import produce from "immer"
import _ from "lodash"
import React from "react"
import { FormGroup, TextInput, Select, Toggle } from "@mwater/react-library/lib/bootstrap"
import { ListEditorComponent } from "@mwater/react-library/lib/ListEditorComponent"
import { PropertyEditor, WidgetLibrary } from "@mwater/ui-builder"
import { SiteDesign, SiteRoute, SiteRouteParam } from "../../common/SiteDesign"

export function RoutesTab(props: { design: SiteDesign; onDesignChange: (design: SiteDesign) => void }) {
  const renderItem = (item: SiteRoute) => {
    const widget = props.design.widgetLibrary.widgets[item.widgetId]
    return (
      <div>
        {item.pattern} <i className="fa fa-arrow-right text-muted fa-fw" />{" "}
        {widget ? widget.name : "<No longer exists>"}{" "}
      </div>
    )
  }

  return (
    <ListEditorComponent<SiteRoute>
      createNew={() => ({})}
      items={props.design.routes || []}
      onItemsChange={(items) => props.onDesignChange({ ...props.design, routes: items })}
      renderEditor={(item, onChange) => (
        <RouteEditor route={item as SiteRoute} onChange={onChange} design={props.design} />
      )}
      renderItem={renderItem}
      validateItem={(route) => route.pattern != null && route.widgetId != null}
      addLabel="Add Route"
      getReorderableKey={(route) => JSON.stringify(route)}
    />
  )
}

const RouteEditor = (props: { design: SiteDesign; route: SiteRoute; onChange: (route: SiteRoute) => void }) => {
  // Create widget options
  const widgetOptions = _.sortByAll(Object.values(props.design.widgetLibrary.widgets), "group", "name").map((w) => ({
    label: (w.group ? `${w.group}: ` : "") + w.name,
    value: w.id
  }))

  // Determine which variables are needed
  const widget = props.route.widgetId ? props.design.widgetLibrary.widgets[props.route.widgetId] : null
  const neededVars = widget ? widget.contextVars : []
  const missingVars = neededVars.filter(
    (nv) => !(props.route.params || []).find((param) => param.contextVarId == nv.id)
  )

  return (
    <div>
      <FormGroup label="Pattern (e.g. '/system')">
        <TextInput
          value={props.route.pattern || null}
          onChange={(value) =>
            props.onChange(
              produce(props.route, (draft) => {
                draft.pattern = value!
              })
            )
          }
        />
      </FormGroup>
      <FormGroup label="Widget">
        <Select
          value={props.route.widgetId}
          onChange={(value) =>
            props.onChange(
              produce(props.route, (draft) => {
                draft.widgetId = value!
              })
            )
          }
          options={widgetOptions}
          nullLabel="Select..."
        />
      </FormGroup>

      {props.route.widgetId ? (
        <FormGroup key="params" label="Parameters" hint="Allows passing in variable from URL">
          <PropertyEditor obj={props.route} onChange={props.onChange} property="params">
            {(value, onChange) => (
              <ListEditorComponent
                items={value || []}
                onItemsChange={onChange}
                renderItem={(item) => renderRouteParamItem(item, props.route.widgetId, props.design.widgetLibrary)}
                createNew={() => ({})}
                renderEditor={(item, onChange) =>
                  renderRouteParamEditor(item, onChange, props.route.widgetId, props.design.widgetLibrary)
                }
                validateItem={(item) => validateRouteParam(item)}
              />
            )}
          </PropertyEditor>
        </FormGroup>
      ) : null}

      {missingVars.map((mv) => (
        <div className="text-info" key={mv.id}>
          Missing mapping for <b>{mv.name}</b> variable
        </div>
      ))}
    </div>
  )
}

/** Render a single item of a list of routes */
function renderRouteParamItem(param: SiteRouteParam, widgetId: string, widgetLibrary: WidgetLibrary) {
  const widget = widgetLibrary.widgets[widgetId]
  if (!widget) {
    return <div className="text-danger">Widget not found!</div>
  }

  const contextVar = widget.contextVars.find((cv) => cv.id == param.contextVarId)
  if (!contextVar) {
    return <div className="text-danger">Context variable {param.contextVarId} not found</div>
  }

  return (
    <div>
      {param.type == "query" ? "?" : ":"}
      {param.paramId} <i className="fa fa-arrow-right" /> {contextVar.name}
    </div>
  )
}

function renderRouteParamEditor(
  param: Partial<SiteRouteParam>,
  onChange: (param: Partial<SiteRouteParam>) => void,
  widgetId: string,
  widgetLibrary: WidgetLibrary
) {
  const widget = widgetLibrary.widgets[widgetId]
  if (!widget) {
    return <div className="text-danger">Widget not found!</div>
  }

  return (
    <div>
      <FormGroup key="Context variable" label="Map to variable">
        <PropertyEditor obj={param} onChange={onChange} property="contextVarId">
          {(value, onChange) => (
            <Select
              value={value}
              onChange={(v) => onChange(v || undefined)}
              nullLabel="Select variable..."
              options={widget.contextVars
                .filter((cv) => cv.type == "row" || cv.type == "number" || cv.type == "text")
                .map((cv) => ({ value: cv.id, label: cv.name }))}
            />
          )}
        </PropertyEditor>
      </FormGroup>

      <FormGroup key="Type" label="Type">
        <PropertyEditor obj={param} onChange={onChange} property="type">
          {(value, onChange) => (
            <Toggle
              value={value ?? null}
              onChange={(v: "path" | "query" | null) => onChange(v || undefined)}
              options={[
                { value: "path", label: "Path (e.g. /route/:xyz)" },
                { value: "query", label: "Query (e.g. /route?xyz=123)" }
              ]}
            />
          )}
        </PropertyEditor>
      </FormGroup>

      <FormGroup key="Parse As" label="Parse As" hint="Whether text or number value">
        <PropertyEditor obj={param} onChange={onChange} property="parseAs">
          {(value, onChange) => (
            <Toggle
              value={value ?? null}
              onChange={(v: "text" | "number" | null) => onChange(v || undefined)}
              options={[
                { value: "text", label: "Text" },
                { value: "number", label: "Number" }
              ]}
            />
          )}
        </PropertyEditor>
      </FormGroup>

      <FormGroup key="Parameter Id" label="Parameter Id">
        <PropertyEditor obj={param} onChange={onChange} property="paramId">
          {(value, onChange) => <TextInput value={value || ""} onChange={(v) => onChange(v || "")} />}
        </PropertyEditor>
      </FormGroup>
    </div>
  )
}

function validateRouteParam(param: Partial<SiteRouteParam>) {
  if (param.contextVarId == null) {
    alert("Context variable required")
    return false
  }
  if (param.paramId == null) {
    alert("Parameter id required")
    return false
  }
  if (param.parseAs == null) {
    alert("Parse As required")
    return false
  }
  if (param.type == null) {
    alert("Type required")
    return false
  }
  return true
}
