import React from "react"
import {
  DesignCtx,
  LabeledProperty,
  PropertyEditor,
  ControlBlock,
  RenderControlProps,
  ControlBlockDef
} from "@mwater/ui-builder"
import FillDownwardComponent from "@mwater/react-library/lib/FillDownwardComponent"
import { DashboardComponent, DashboardDesign, DirectDashboardDataSource } from "@mwater/visualization"
import { Column } from "@mwater/expressions"
import { FilterSelector } from "./FilterSelector"
import computeFilters from "./computeFilters"
import _ from "lodash"
import { handleRowClick } from './rowClickHandler'

/** Block that contains an mwater-visualization dashboard */
export interface SingleDashboardBlockDef extends ControlBlockDef {
  type: "waterorg:singledashboard"

  /** Context variable ids of rowsets to include as filters */
  filterContextVars?: string[]

  /** True to hide controls */
  hideControls?: boolean
}

export default class SingleDashboardBlock extends ControlBlock<SingleDashboardBlockDef> {
  apiUrl: string

  constructor(blockDef: SingleDashboardBlockDef, apiUrl: string) {
    super(blockDef)
    this.apiUrl = apiUrl
  }

  renderControl(ctx: RenderControlProps) {
    if (ctx.designMode) {
      return (
        <div style={{ textAlign: "center", padding: 20, border: "solid 1px #444", borderRadius: 10 }}>
          Dashboard Here in Preview
        </div>
      )
    }

    return <SingleDashboardInstance apiUrl={this.apiUrl} blockDef={this.blockDef} ctx={ctx} />
  }

  renderControlEditor(props: DesignCtx) {
    return (
      <div>
        {/* <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="hideControls">
        {(value, onChange) => <Checkbox value={value || false} onChange={onChange}>Hide Controls</Checkbox>}
      </PropertyEditor> */}

        <LabeledProperty label="Filters to Apply">
          <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="filterContextVars">
            {(value: string[] | undefined, onChange) => (
              <FilterSelector value={value} onChange={onChange} contextVars={props.contextVars} />
            )}
          </PropertyEditor>
        </LabeledProperty>
      </div>
    )
  }

  filterColumn(column: Column): boolean {
    return column.type == "json"
  }
}

const SingleDashboardInstance = (props: {
  blockDef: SingleDashboardBlockDef
  apiUrl: string
  ctx: RenderControlProps
}) => {
  const { ctx, blockDef } = props

  const defaultDesign: DashboardDesign = {
    items: { id: "root", type: "root", blocks: [] },
    layout: "blocks",
    quickfilters: [],
    implicitFiltersEnabled: false
  }

  // Bypass change and store current design in state to prevent overloading server
  const [design, setDesign] = React.useState<DashboardDesign>(props.ctx.value || defaultDesign)

  // Send updates periodically
  const updateDesign = React.useCallback(
    _.throttle(
      async (design: DashboardDesign) => {
        const txn = ctx.database.transaction()
        await txn.updateRow(ctx.rowContextVar!.table!, ctx.rowId, { [blockDef.column!]: design })
        await txn.commit()
      },
      4000,
      { leading: false, trailing: true }
    ),
    [props.ctx.rowId]
  )

  function handleDesignChange(design: DashboardDesign) {
    // Immediately update
    setDesign(design)

    // Send update
    updateDesign(design)
  }

  // Calculate filters to be applied
  const filters = computeFilters({
    schema: ctx.schema,
    contextVars: ctx.contextVars,
    contextVarValues: ctx.contextVarValues,
    filterContextVars: props.blockDef.filterContextVars,
    getFilters: ctx.getFilters
  })

  return (
    <FillDownwardComponent>
      <DashboardComponent
        design={design}
        onDesignChange={ctx.onChange ? handleDesignChange : undefined}
        dashboardDataSource={
          new DirectDashboardDataSource({
            schema: ctx.schema,
            dataSource: ctx.dataSource!,
            apiUrl: props.apiUrl
          })
        }
        filters={filters}
        schema={ctx.schema}
        dataSource={ctx.dataSource!}
        onRowClick={handleRowClick.bind(null, ctx.dataSource!)}
      />
    </FillDownwardComponent>
  )
}
