import React from "react"
import {
  DesignCtx,
  ControlBlock,
  ControlBlockDef,
  RenderControlProps,
  LabeledProperty,
  PropertyEditor
} from "@mwater/ui-builder"
import { Column } from "@mwater/expressions"
import { TabbedDashboardDesign, TabbedDashboardComponent } from "./TabbedDashboardComponent"
import { useState } from "react"
import computeFilters from "./computeFilters"
import { FilterSelector } from "./FilterSelector"
import _ from "lodash"
import { v4 } from "uuid"
import { handleRowClick } from './rowClickHandler'

/** Block that contains a tabbed mwater-visualization dashboard */
export interface TabbedDashboardBlockDef extends ControlBlockDef {
  type: "waterorg:tabbeddashboard"

  design: TabbedDashboardDesign

  /** Context variable ids of rowsets to include as filters */
  filterContextVars?: string[]
}

export default class TabbedDashboardBlock extends ControlBlock<TabbedDashboardBlockDef> {
  apiUrl: string

  constructor(blockDef: TabbedDashboardBlockDef, apiUrl: string) {
    super(blockDef)
    this.apiUrl = apiUrl
  }

  renderControl(props: RenderControlProps) {
    if (props.designMode) {
      return (
        <div style={{ textAlign: "center", padding: 20, border: "solid 1px #444", borderRadius: 10 }}>
          Dashboard Here in Preview
        </div>
      )
    }

    return <TabbedDashboardInstance apiUrl={this.apiUrl} blockDef={this.blockDef} ctx={props} />
  }

  renderControlEditor(props: DesignCtx) {
    return (
      <div>
        {/* <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="hideControls">
        {(value, onChange) => <Checkbox value={value || false} onChange={onChange}>Hide Controls</Checkbox>}
      </PropertyEditor> */}

        <LabeledProperty label="Filters to Apply">
          <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="filterContextVars">
            {(value: string[] | undefined, onChange) => (
              <FilterSelector value={value} onChange={onChange} contextVars={props.contextVars} />
            )}
          </PropertyEditor>
        </LabeledProperty>
      </div>
    )
  }

  filterColumn(column: Column): boolean {
    return column.type == "json"
  }
}

/** Helper to get current hash (within hash route. e.g. #/apple#foo => "foo") */
function getHash() {
  const hash = window.location.hash
  if (!hash) {
    return ""
  }
  const secondHash = hash.substr(1).split("#")[1]
  return secondHash || ""
}

function setHash(hash: string) {
  const existing = window.location.hash

  // Can't set if no existing
  if (!existing) {
    return
  }

  // Get part to preserve
  const firstPart = existing.substr(1).split("#")[0]
  window.location.hash = "#" + firstPart + "#" + hash
}

function TabbedDashboardInstance(props: {
  blockDef: TabbedDashboardBlockDef
  apiUrl: string
  ctx: RenderControlProps
}) {
  const { ctx, blockDef } = props

  const tabId = getHash()

  const defaultDesign: TabbedDashboardDesign = {
    tabs: [
      {
        id: v4(),
        name: "Untitled",
        design: {
          items: { id: "root", type: "root", blocks: [] },
          layout: "blocks",
          quickfilters: [],
          implicitFiltersEnabled: false
        }
      }
    ]
  }

  // Bypass change and store current design in state to prevent overloading server
  const [design, setDesign] = useState<TabbedDashboardDesign>(props.ctx.value || defaultDesign)

  // Send updates periodically
  const updateDesign = React.useCallback(
    _.debounce(
      async (design: TabbedDashboardDesign) => {
        const txn = ctx.database.transaction()
        await txn.updateRow(ctx.rowContextVar!.table!, ctx.rowId, { [blockDef.column!]: design })
        await txn.commit()
      },
      3000,
      { leading: false, trailing: true }
    ),
    [props.ctx.rowId]
  )

  function handleDesignChange(design: TabbedDashboardDesign) {
    // Immediately update
    setDesign(design)

    // Send update
    updateDesign(design)
  }

  // Calculate filters to be applied
  const filters = computeFilters({
    schema: props.ctx.schema,
    contextVars: props.ctx.contextVars,
    contextVarValues: props.ctx.contextVarValues,
    filterContextVars: props.blockDef.filterContextVars,
    getFilters: props.ctx.getFilters
  })

  return (
    <TabbedDashboardComponent
      apiUrl={props.apiUrl}
      schema={props.ctx.schema}
      dataSource={props.ctx.dataSource!}
      design={design}
      onDesignChange={ctx.onChange ? handleDesignChange : undefined}
      tabId={tabId}
      filters={filters}
      onTabIdChange={tabId => {
        setHash(tabId)
        // setTabId(tabId)
      }}
      onRowClick={handleRowClick.bind(null, props.ctx.dataSource!)}
      height="100vh" />
  )
}
