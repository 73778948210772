import { Expr, ExprUtils, Schema } from "@mwater/expressions"
import React, { useState } from "react"
import { OptionListComponent, ToggleEditComponent } from "@mwater/visualization"
import _ from "lodash"
import { SecureTable } from "../common/SiteDesign"
import { CustomTableSelectComponentFactoryContext } from "@mwater/expressions-ui"

// Core tables that are always visible
const coreTableIds = ["impact_data_internal_achieved", "wo_achibeneficiary", "wco_loans_active", "wo_programs", "wo_partnerorgs"]

interface TableSelectComponentProps {
  /** Schema of the database */
  schema: Schema;
  /** Current table id */
  value: string | null | undefined;
  /** Function to call when a new table id is selected */
  onChange: (value: string | null) => void;
  /** Optional expression for filtering tables.
   * Some table select components (not the default) can also perform filtering.
   * Include these props to enable this.
   */
  filter?: Expr;
  /** Function to call when the filter expression changes */
  onFilterChange?: (filter: Expr) => void;
}

/** Wrapper that adds Water.org table select component */
export class WaterOrgTableSelectWrapper extends React.Component<{
  amAdmin: boolean
}> {
  tableSelectElementFactory = (props: TableSelectComponentProps) => {
    return <WaterOrgTableSelectComponent {...props} amAdmin={this.props.amAdmin} />
  }

  render() {
    return <CustomTableSelectComponentFactoryContext.Provider value={this.tableSelectElementFactory}>
      {this.props.children}
    </CustomTableSelectComponentFactoryContext.Provider>
  }
}

function WaterOrgTableSelectComponent(props: {
  schema: Schema
  /** Current table id */
  value: string | null | undefined
  /** Newly selected table id */
  onChange: (tableId: string) => void
  /** Some table select components (not the default) can also perform filtering. Include these props to enable this */
  filter?: Expr
  onFilterChange?: (filter: Expr) => void
  
  amAdmin: boolean
}) {
  return <ToggleEditComponent
    forceOpen={props.value == null}
    label={props.value
      ? ExprUtils.localizeString(props.schema.getTable(props.value)?.name || "(not found)", "en")
      : <i>Select...</i>
    }
    editor={(onClose: () => void) => {
      return <SelectTableEditor
        onClose={onClose}
        schema={props.schema}
        onChange={props.onChange}
        amAdmin={props.amAdmin}
      />
    }}
  />
}

function SelectTableEditor(props: {
  schema: Schema
  onClose: () => void
  /** Newly selected table id */
  onChange: (tableId: string) => void
  amAdmin: boolean
}) {
  // True when advanced tables are open
  const [advanced, setAdvanced] = useState(false)

  let tableIds = coreTableIds.slice()
  if (advanced) {
    // Add other options
    const otherTables = _.sortBy(props.schema.getTables().filter(
      (table: SecureTable) => (
        !table.deprecated 
        && !coreTableIds.includes(table.id)
        && (props.amAdmin || !table.internal))
    ), table => ExprUtils.localizeString(table.name, "en"))
    
    tableIds = tableIds.concat(otherTables.map(table => table.id))
  }
  
  const options = tableIds
    .map(tableId => props.schema.getTable(tableId)!)
    .map(table => ({
      name: ExprUtils.localizeString(table.name, "en"),
      desc: ExprUtils.localizeString(table.desc, "en"),
      onClick: () => {
        props.onClose()
        props.onChange(table.id)
      }
    }))

  return <div>
    <OptionListComponent
      hint="Select source to get data from"
      items={options}
    />
    { !advanced ?
      <button className="btn btn-sm btn-link" onClick={() => setAdvanced(true)}>
        Show All Data Sources
      </button>
    : null }
  </div>
}
