import { useEffect, useMemo, useState } from "react"
import { ClientDataSource } from "../ClientDataSource"
import React from "react"
import { createSchema } from "../../common/utils"
import { SiteDesign } from "../../common/SiteDesign"
import { performJsonAPICall } from "../api"
import LoadingComponent from "@mwater/react-library/lib/LoadingComponent"
import { TabbedDashboardComponent, TabbedDashboardDesign } from "../blocks/TabbedDashboardComponent"

/** Displays a password protected external dashboard that doesn't require login */
export function ExternalDashboardPage(props: {
  siteDesign: SiteDesign

  /** id of dashboard */
  dashboardId: string
}) {
  const apiUrl = window.location.protocol + "//" + window.location.host + "/mwater/"
  const schema = useMemo(() => createSchema(props.siteDesign), [])
  const dataSource = useMemo(() => new ClientDataSource(), [])
  const [tabId, setTabId] = useState<string>()
  
  const [dashboard, setDashboard] = useState<TabbedDashboardDesign>()

  useEffect(() => {
    const password = prompt("Enter password for dashboard")
    if (!password) {
      alert("Password required")
      return
    }

    performJsonAPICall("/api/get_external_dashboard", {
      dashboardId: props.dashboardId,
      password
    }).then((dashboard: any) => {
      setDashboard(dashboard)
    }).catch((err) => { alert(err.message)} )
  }, [props.dashboardId])

  if (!dashboard) {
    return <LoadingComponent/>
  }

  return <TabbedDashboardComponent
    apiUrl={apiUrl}
    schema={schema}
    dataSource={dataSource}
    design={dashboard}
    tabId={tabId}
    onTabIdChange={setTabId}
    height="calc(100vh - 52px)"
  />
}