import React from "react"
import ReactDOM from "react-dom"
import ErrorBoundary from "@mwater/ui-builder/lib/designer/ErrorBoundary";
import App from "./App"
import { setMapTilerApiKey } from "@mwater/visualization"
import { defaultT } from "ez-localize"

import "./index.scss"

// Setup jQuery
const jquery = require("jquery")
;(global as any).jQuery = jquery
require("bootstrap")

// Setup localization (for mwater code)
;(window as any).T = defaultT

// Setup MapTiler API Key
setMapTilerApiKey("XbTMu46NEQGwOFPY09nN")

ReactDOM.render(
  <ErrorBoundary>
    <App/>
  </ErrorBoundary>
, document.getElementById("root"))
