import _ from "lodash"
import { SecureTable, SecureTableValidation } from "../../common/SiteDesign"
import React from "react"
import { Schema, DataSource, Variable, flattenContents, localizeString } from "@mwater/expressions"
import { PropertyListComponent, FilterExprComponent, ExprComponent } from "@mwater/expressions-ui"
import { FormGroup, TextInput, Select, Checkbox } from "@mwater/react-library/lib/bootstrap"
import TabbedComponent from "@mwater/react-library/lib/TabbedComponent"
import produce from "immer"
import { LocalizedTextPropertyEditor, ListEditor, LabeledProperty, PropertyEditor } from "@mwater/ui-builder"

export function TableEditor(props: {
  schema: Schema
  dataSource: DataSource
  table: SecureTable
  onChange: (table: SecureTable) => void
  onDelete: () => void
}) {
  const { table, onChange, schema, dataSource } = props

  const flatProperties = flattenContents(table.contents)

  const settingsTab = (
    <div>
      <FormGroup label="Name">
        <LocalizedTextPropertyEditor
          value={table.name}
          onChange={(val) =>
            onChange(
              produce(table, (draft) => {
                draft.name = val!
              })
            )
          }
          locale="en"
        />
      </FormGroup>
      <FormGroup label="Description">
        <LocalizedTextPropertyEditor
          value={table.desc}
          onChange={(val) =>
            onChange(
              produce(table, (draft) => {
                draft.desc = val!
              })
            )
          }
          locale="en"
        />
      </FormGroup>
      <Checkbox
        value={table.deprecated || false}
        onChange={(val) =>
          onChange(
            produce(table, (draft) => {
              draft.deprecated = val
            })
          )
        }
      >
        Deprecated
      </Checkbox>
      <FormGroup label="Primary Key">
        <TextInput
          value={table.primaryKey as string}
          onChange={(val) =>
            onChange(
              produce(table, (draft) => {
                draft.primaryKey = val!
              })
            )
          }
        />
      </FormGroup>
      <FormGroup label="Ordering">
        <Select
          value={table.ordering || null}
          nullLabel="None"
          options={flatProperties.map((p) => ({ label: p.name.en, value: p.id }))}
          onChange={(val) =>
            onChange(
              produce(table, (draft) => {
                draft.ordering = val || undefined
              })
            )
          }
        />
      </FormGroup>
      <FormGroup label="Label">
        <Select
          value={table.label || null}
          nullLabel="None"
          options={flatProperties.filter((p) => p.type == "text").map((p) => ({ label: p.name.en, value: p.id }))}
          onChange={(val) =>
            onChange(
              produce(table, (draft) => {
                draft.label = val || undefined
              })
            )
          }
        />
      </FormGroup>
      
      <Checkbox
        value={table.internal || false} 
        onChange={(val) =>
          onChange(
            produce(table, (draft) => {
              draft.internal = val
            })
          )}
      >Internal table (only show to admins in selector)</Checkbox>

      <FormGroup label="SQL" hint="Special SQL override">
        <TextInput
          value={table.sql || ""}
          onChange={(val) =>
            onChange(
              produce(table, (draft) => {
                draft.sql = val || undefined
              })
            )
          }
        />
      </FormGroup>
      <FormGroup label="Notes" hint="Not visible to user">
        <textarea
          value={table.notes || ""}
          className="form-control"
          rows={5}
          onChange={(ev) =>
            onChange(
              produce(table, (draft) => {
                draft.notes = ev.target.value
              })
            )
          }
        />
      </FormGroup>

      {/* <CanViewExprEditor
        key="canViewExpr"
        table={table}
        onChange={onChange}
        schema={schema}
        dataSource={dataSource}
        exprVariables={props.exprVariables}
      /> */}
      <ValidationsEditor
        key="validations"
        table={table}
        onChange={onChange}
        schema={schema}
        dataSource={dataSource}
      />
    </div>
  )

  const propertiesTab = (
    <PropertyListComponent
      schema={schema}
      dataSource={dataSource}
      properties={table.contents}
      table={table.id}
      onChange={(ps) =>
        onChange(
          produce(table, (draft) => {
            draft.contents = ps
          })
        )
      }
      features={["idField", "idType", "joinType", "expr", "section", "unique", "sql", "reverseSql", "required"]}
    />
  )

  // <button type="button" className="btn btn-link" onClick={handleChangeId}><i className="fa fa-pencil"/></button>
  // function handleChangeId() {
  //   const newId = prompt("ID of table", table.id)
  //   if (newId) {
  //     onChange(produce(table, draft => { draft.id = newId }))
  //   }
  // }

  const tabs = [
    { id: "properties", label: "Properties", elem: propertiesTab },
    { id: "settings", label: "Settings", elem: settingsTab }
  ]

  return (
    <div>
      <button className="btn btn-link btn-sm" style={{ float: "right" }} onClick={props.onDelete}>
        Delete Table
      </button>
      <h4>
        {table.id} - <span className="text-muted">{localizeString(table.name)}</span>
      </h4>
      <TabbedComponent initialTabId="properties" tabs={tabs} />
    </div>
  );
}

/** Edits permissions */
function CanViewExprEditor(props: {
  schema: Schema
  dataSource: DataSource
  table: SecureTable
  onChange: (table: SecureTable) => void

  /** Variables which are present for expressions */
  exprVariables: Variable[]
}) {
  const { table, onChange, schema, dataSource } = props

  // Can't set expressions until table id is stable
  if (!table.id) {
    return null
  }

  return (
    <div>
      <FormGroup label="View Filter" hint="Expression which, if present, must be true for row to be visible">
        <FilterExprComponent
          addLabel="Add Condition"
          schema={schema}
          dataSource={dataSource}
          table={table.id}
          value={table.canViewExpr}
          onChange={(val) => onChange({ ...table, canViewExpr: val })}
        />
      </FormGroup>
    </div>
  )
}

/** Edits validations */
const ValidationsEditor = (props: {
  schema: Schema
  dataSource: DataSource
  table: SecureTable
  onChange: (table: SecureTable) => void
}) => {
  const { table, onChange, schema, dataSource } = props

  // Can't set expressions until table id is stable
  if (!table.id) {
    return null
  }

  const handleValidationsChange = (validations: SecureTableValidation[]) => {
    onChange({ ...table, validations: validations })
  }

  // Adds a new validation
  const handleAddValidation = () => {
    handleValidationsChange(
      (table.validations || []).concat([
        { when: ["add", "post_update", "pre_update", "remove"], expr: null, message: null }
      ])
    )
  }

  return (
    <FormGroup label="Validations" hint="Sets permissions">
      <ListEditor items={table.validations || []} onItemsChange={handleValidationsChange}>
        {(item, onItemChange) => (
          <ValidationEditor
            value={item}
            onChange={onItemChange}
            schema={schema}
            dataSource={dataSource}
            table={table}
          />
        )}
      </ListEditor>
      <button type="button" className="btn btn-link btn-sm" onClick={handleAddValidation}>
        + Add Validation
      </button>
    </FormGroup>
  )
}

/** Allows editing of an coded expression */
export const ValidationEditor = (props: {
  table: SecureTable
  value: SecureTableValidation
  onChange: (validation: SecureTableValidation) => void
  schema: Schema
  dataSource: DataSource
}) => {
  return (
    <div>
      <LabeledProperty label="When" key="when">
        <PropertyEditor obj={props.value} onChange={props.onChange} property="when">
          {(value, onChange) => {
            const toggle = (key: any, v: boolean) => {
              if (v) {
                onChange(_.union(value, [key]))
              } else {
                onChange(_.difference(value, [key]))
              }
            }
            return (
              <div>
                <Checkbox inline={true} value={value.includes("add")} onChange={toggle.bind(null, "add")}>
                  Add
                </Checkbox>
                <Checkbox inline={true} value={value.includes("pre_update")} onChange={toggle.bind(null, "pre_update")}>
                  Before Update
                </Checkbox>
                <Checkbox
                  inline={true}
                  value={value.includes("post_update")}
                  onChange={toggle.bind(null, "post_update")}
                >
                  After Update
                </Checkbox>
                <Checkbox inline={true} value={value.includes("remove")} onChange={toggle.bind(null, "remove")}>
                  Remove
                </Checkbox>
              </div>
            )
          }}
        </PropertyEditor>
      </LabeledProperty>
      <LabeledProperty label="Expression which must be true" key="expr">
        <PropertyEditor obj={props.value} onChange={props.onChange} property="expr">
          {(value, onChange) => (
            <ExprComponent
              value={value}
              onChange={onChange}
              schema={props.schema}
              dataSource={props.dataSource}
              types={["boolean"]}
              table={props.table.id}
            />
          )}
        </PropertyEditor>
      </LabeledProperty>
      <LabeledProperty label="Message when validation fails" key="message">
        <PropertyEditor obj={props.value} onChange={props.onChange} property="message">
          {(value, onChange) => <TextInput value={value} onChange={onChange} placeholder="Optional message" />}
        </PropertyEditor>
      </LabeledProperty>
    </div>
  )
}
