import { PageStackDisplay, Page } from "@mwater/ui-builder"
import { useMemo } from "react"
import { ClientDataSource } from "../ClientDataSource"
import React from "react"
import { useCreateBaseCtx } from "../uiBuilderSetup"
import { createSchema } from "../../common/utils"
import { SiteDesign } from "../../common/SiteDesign"
import { createGlobalContextVarValues } from "../../common/variables"
import { User } from "../../common/User"

/** Displays a single widget in a page stack */
export function WidgetPage(props: {
  siteDesign: SiteDesign

  /** ID of the widget to display. It can be found in faint grey text at the bottom of the edit pane on the right */
  widgetId: string

  /** Current login user */
  loginUser: User

  /** Current view user (different from login user if crossed over) */
  viewUser: User

  /** Values of any context variables (except global ones) that the widget
   * needs. Indexed by context variable name, as that is more human-readable.
   */
  contextVarValues?: { [contextVarName: string]: any }
}) {
  const { siteDesign, widgetId } = props

  // Create schema
  const schema = useMemo(() => createSchema(siteDesign), [siteDesign])
  const dataSource = useMemo(() => new ClientDataSource(), [])

  const baseCtx = useCreateBaseCtx({
    schema,
    dataSource,
    locale: "en",
    widgetLibrary: siteDesign.widgetLibrary
  })

  const initialPage: Page = useMemo(() => {
    const globalContextVarValues = createGlobalContextVarValues(props.loginUser, props.viewUser)

    const contextVarValues = { ...globalContextVarValues }

    // Look up widget
    const widget = siteDesign.widgetLibrary.widgets[widgetId]
    if (!widget) {
      throw new Error("Widget not found")
    }

    // Add in passed in variables
    for (const widgetContextVar of widget.contextVars) {
      if (!(widgetContextVar.name in (props.contextVarValues || {}))) {
        throw new Error(`"Context var value for ${widgetContextVar.name} not present`)
      }

      // Set value based on name
      contextVarValues[widgetContextVar.id] = (props.contextVarValues || {})[widgetContextVar.name]
    }

    return {
      type: "normal",
      widgetId: widgetId,
      database: baseCtx.database,
      contextVarValues: contextVarValues
    }
  }, [widgetId, baseCtx.database, props.contextVarValues])

  // TODO handle navigating away
  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <PageStackDisplay
        key={widgetId + ":" + JSON.stringify(props.contextVarValues)}
        baseCtx={baseCtx}
        initialPage={initialPage}
      />
    </div>
  )
}
