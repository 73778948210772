import _ from "lodash"
import { SecureTable } from "../../common/SiteDesign"
import React, { useMemo, useState } from "react"
import { Schema, DataSource, localizeString } from "@mwater/expressions"
import produce from "immer"
import { TableEditor } from "./TableEditor"
import { createGlobalVariables } from "../../common/variables"

export const TablesEditor = (props: {
  tables: SecureTable[]
  onChange: (tables: SecureTable[]) => void
  schema: Schema
  dataSource: DataSource
}) => {
  /** Index in tables, not display order which is sorted */
  const [selectedIndex, setSelectedIndex] = useState(() => {
    const firstTable = _.sortBy(props.tables, (t) => t.id)[0]
    return firstTable ? props.tables.findIndex((t) => t.id == firstTable.id) : -1
  })

  /** Search string */
  const [search, setSearch] = useState("")

  const handleAdd = () => {
    const tableId = prompt("New table id")
    if (!tableId) {
      return
    }

    // Select new table
    setSelectedIndex(props.tables.length)

    props.onChange(
      produce(props.tables, (draft) => {
        draft.push({
          id: tableId,
          name: { _base: "en", en: "Untitled" },
          contents: [],
          validations: []
        })
      })
    )
  }

  const handleDelete = (index: number) => {
    if (!confirm("Delete table?")) {
      return
    }

    props.onChange(
      produce(props.tables, (draft) => {
        draft.splice(index, 1)
      })
    )
  }

  // Create schema with variables
  const schemaWithVariables = useMemo(() => {
    return props.schema.addVariables(createGlobalVariables(), {})
  }, [props.schema])

  /** Render currently selected table */
  function renderTable() {
    if (selectedIndex >= props.tables.length || selectedIndex < 0) {
      return null
    }

    const table = props.tables[selectedIndex]

    return (
      <TableEditor
        key={table.id}
        schema={schemaWithVariables}
        dataSource={props.dataSource}
        table={table}
        onChange={(table) =>
          props.onChange(
            produce(props.tables, (draft) => {
              draft[selectedIndex] = table
            })
          )
        }
        onDelete={() => {
          handleDelete(selectedIndex)
        }}
      />
    )
  }

  function renderSearch() {
    return (
      <div style={{ position: "relative", width: "100%", paddingBottom: 10 }}>
        <i
          className="fa fa-search"
          style={{ position: "absolute", right: 8, top: 10, color: "#AAA", pointerEvents: "none" }}
        />
        <input
          type="text"
          className="form-control"
          style={{ width: "100%" }}
          value={search}
          onChange={(ev) => {
            setSearch(ev.target.value)
          }}
        />
      </div>
    )
  }

  const selectedTableId = selectedIndex >= 0 && props.tables[selectedIndex] ? props.tables[selectedIndex].id : null

  // Create sorted list of tables
  const sortedTables = _.sortBy(props.tables, (t) => t.id)

  const filteredTables = sortedTables.filter(
    (t) =>
      !search || t.id.toLowerCase().includes(search) || (localizeString(t.name) || "").toLowerCase().includes(search)
  )

  return (
    <div style={{ position: "relative" }}>
      <div key="left" style={{ position: "absolute", top: 0, left: 0, bottom: 0, width: 300 }}>
        {renderSearch()}
        <div className="list-group">
          {filteredTables.map((table) => {
            return (
              <a
                className={table.id == selectedTableId ? "list-group-item active" : "list-group-item"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedIndex(props.tables.findIndex((t) => t.id == table.id))
                }}
              >
                <div style={{ textDecoration: table.deprecated ? "line-through" : undefined }}>
                  {table.id} - {localizeString(table.name)}
                </div>
              </a>
            )
          })}
        </div>
        <button type="button" className="btn btn-link" onClick={handleAdd}>
          <i className="fa fa-plus" /> Add Table
        </button>
      </div>

      <div style={{ marginLeft: 300, marginTop: 5, paddingLeft: 10, paddingRight: 10 }}>{renderTable()}</div>
    </div>
  )
}
