import { BaseCtx, WidgetLibrary, ActionLibrary, BlockFactory, defaultBlockPaletteEntries, registerExtraCodedPackage } from "@mwater/ui-builder"
import { Schema, DataSource } from "@mwater/expressions"
// import DashboardBlock, { DashboardBlockDef } from "./blocks/DashboardBlock";
import React, { useMemo } from "react"
import { createDatabase } from "./ClientDatabase"
import TabbedDashboardBlock, { TabbedDashboardBlockDef } from "./blocks/TabbedDashboardBlock"
import SingleDashboardBlock, { SingleDashboardBlockDef } from "./blocks/SingleDashboardBlock"
import { ExportDataAction, ExportDataActionDef } from "./blocks/ExportDataAction"
import { defaultT } from "ez-localize"
import { TinyMCEBlockDef, TinyMCEBlock } from "./blocks/TinyMCEBlock"
import { createGlobalContextVars } from "../common/variables"
import * as api from "./api"
import FileSaver from "file-saver"
import VisualizationWidgetBlock, { VisualizationWidgetBlockDef } from "./blocks/VisualizationWidgetBlock"

// Setup coded block
registerExtraCodedPackage("lodash", () => import("lodash"))
registerExtraCodedPackage("react-select", () => import("react-select"))
registerExtraCodedPackage("moment", () => import("moment"))
registerExtraCodedPackage("api", () => Promise.resolve(api))
registerExtraCodedPackage("file-saver", () => Promise.resolve(FileSaver))

export function useCreateBaseCtx(options: {
  schema: Schema
  dataSource: DataSource
  widgetLibrary: WidgetLibrary
  locale: string
}): BaseCtx {
  const database = useCreateDatabase(options.schema, options.dataSource)
  const actionLibrary = useMemo(() => createActionLibrary(), [])
  const createBlock = useMemo(
    () => createBlockFactory({ schema: options.schema, dataSource: options.dataSource }).createBlock,
    [options.schema, options.dataSource]
  )

  const baseCtx: BaseCtx = {
    locale: options.locale,
    schema: options.schema,
    dataSource: options.dataSource,
    widgetLibrary: options.widgetLibrary,
    actionLibrary,
    createBlock,
    database,
    // TODO /** Locale object to use for formatting */
    // formatLocale?: FormatLocaleObject;
    /** Global context variables that are passed to all blocks */
    globalContextVars: createGlobalContextVars(),
    T: defaultT
  }

  return baseCtx
}

/** Creates a database with a hook to the API to get updates */
const useCreateDatabase = (schema: Schema, dataSource: DataSource) => {
  const database = useMemo(() => createDatabase(schema, dataSource), [schema, dataSource])
  return database
}

const createActionLibrary = () => {
  const actionLibrary = new ActionLibrary()
  actionLibrary.registerCustomAction(
    "waterorg:ExportData",
    "Export Data as CSV",
    () =>
      ({
        type: "waterorg:ExportData",
        filename: null,
        rowsetContextVarId: null,
        columns: [],
        where: null
      } as ExportDataActionDef),
    (actionDef) => new ExportDataAction(actionDef as ExportDataActionDef)
  )
  return actionLibrary
}

const createBlockFactory = (options: { schema: Schema; dataSource: DataSource }) => {
  const blockFactory = new BlockFactory()

  blockFactory.registerCustomBlock("waterorg:tinyMCE", (blockDef: any) => new TinyMCEBlock(blockDef))

  // TODO
  blockFactory.registerCustomBlock(
    "waterorg:tabbeddashboard",
    (blockDef: any) => new TabbedDashboardBlock(blockDef, window.location.protocol + "//" + window.location.host + "/mwater/")
  )

  blockFactory.registerCustomBlock(
    "waterorg:singledashboard",
    (blockDef: any) => new SingleDashboardBlock(blockDef, window.location.protocol + "//" + window.location.host + "/mwater/")
  )

  blockFactory.registerCustomBlock(
    "mwater-visualization:widget",
    (blockDef: any) =>
      new VisualizationWidgetBlock({
        blockDef,
        apiUrl: window.location.protocol + "//" + window.location.host + "/mwater/",
        client: undefined,
        namedStrings: {}
      })
  )

  return blockFactory
}

/** Create entries for block palette in designer */
export const createBlockPaletteEntries = () => {
  const blockPaletteEntries = defaultBlockPaletteEntries.slice()
  // blockPaletteEntries.push({
  //   title: "Dashboard Widget",
  //   blockDef: {
  //     id: "",
  //     type: "waterorg:dashboard",
  //     dashboardDesign: { items: { id: "root", type: "root", blocks: [] }, layout: "blocks" }
  //   } as DashboardBlockDef,
  //   elem: <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>Dashboard</div>
  // })

  blockPaletteEntries.push({
    title: "Rich Text Control",
    blockDef: {
      id: "",
      type: "waterorg:tinyMCE"
    } as TinyMCEBlockDef,
    elem: <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>Rich Text</div>
  })

  blockPaletteEntries.push({
    title: "Tabbed Dashboard Widget",
    blockDef: {
      id: "",
      type: "waterorg:tabbeddashboard"
    } as TabbedDashboardBlockDef,
    elem: <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>Tabbed Dashboard</div>
  })

  blockPaletteEntries.push({
    title: "Single Dashboard Widget",
    blockDef: {
      id: "",
      type: "waterorg:singledashboard"
    } as SingleDashboardBlockDef,
    elem: <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>Single Dashboard</div>
  })

  blockPaletteEntries.push({
    title: "Chart Widget",
    blockDef: {
      id: "",
      type: "mwater-visualization:widget",
      widgetType: "LayeredChart",
      widgetDesign: {},
      minimumWidth: 800
    } as VisualizationWidgetBlockDef,
    elem: (
      <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>
        <i className="fa fa-bar-chart" />
      </div>
    )
  })
  blockPaletteEntries.push({
    title: "Map Widget",
    blockDef: {
      id: "",
      type: "mwater-visualization:widget",
      widgetType: "Map",
      widgetDesign: { baseLayer: "bing_road", layerViews: [], filters: {}, bounds: { w: -40, n: 25, e: 40, s: -25 } },
      minimumWidth: 800
    } as VisualizationWidgetBlockDef,
    elem: (
      <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>
        <i className="fa fa-map" />
      </div>
    )
  })
  blockPaletteEntries.push({
    title: "Pivot Widget",
    blockDef: {
      id: "",
      type: "mwater-visualization:widget",
      widgetType: "PivotChart",
      widgetDesign: {},
      autoHeight: true
    } as VisualizationWidgetBlockDef,
    elem: (
      <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>
        <i className="fa fa-magic" />
      </div>
    )
  })
  blockPaletteEntries.push({
    title: "Calendar Widget",
    blockDef: {
      id: "",
      type: "mwater-visualization:widget",
      widgetType: "CalendarChart",
      widgetDesign: {}
    } as VisualizationWidgetBlockDef,
    elem: (
      <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>
        <i className="fa fa-calendar" />
      </div>
    )
  })
  blockPaletteEntries.push({
    title: "Text Widget (not localizable)",
    blockDef: {
      id: "",
      type: "mwater-visualization:widget",
      widgetType: "Text",
      widgetDesign: {},
      autoHeight: true
    } as VisualizationWidgetBlockDef,
    elem: (
      <div style={{ textAlign: "center", color: "#AAA", fontSize: 18 }}>
        <i className="fa fa-align-left" />
      </div>
    )
  })


  return blockPaletteEntries
}
