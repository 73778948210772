import { useEffect, useState } from "react"

/** Makes a JSON call to the API
 * Expects server to return 200 with result as JSON, or 4xx error with plain text error message
 * @returns result of call
 * @throws APIError if error occurs
 * @param path e.g. "/api/some_call"
 * @param args JSON serializable args that will be passed as the body
 */
export async function performJsonAPICall<T>(path: string, args: any): Promise<T> {
  let response
  try {
    response = await fetch(path, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(args)
    })

    if (response.ok) {
      return await response.json()
    }
  } catch (err: any) {
    // No connection
    throw new Error("Unable to contact server")
  }

  const message = await response.text()
  throw new Error(message)
}

/** Convenience React hook to load data from a JSON route (one that takes POST request with parameters
 * in the body as serialized JSON and returns JSON string or 400 error with error message)
 * @returns [result, error object, reload function]
 */
export function useLoadData<T = any>(url: string, params?: any): [T | undefined, any, () => void] {
  const [data, setData] = useState<T>()
  const [error, setError] = useState<any>()

  function loadData() {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((d) => setData(d))
          .catch((err) => setError(err))
      } else {
        response
          .text()
          .then((err) => setError(new Error(err)))
          .catch((err) => setError(err))
      }
    })
  }

  useEffect(() => {
    loadData()
  }, [url, JSON.stringify(params)])

  return [data, error, loadData]
}
