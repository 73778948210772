import React, { useState } from "react"
import { FormGroup } from "@mwater/react-library/lib/bootstrap"
import { User } from "../../common/User"

/** Page to login */
export const LoginPage = (props: { onLogin: (user: User) => void }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [busy, setBusy] = useState(false)

  const handleLogin = async () => {
    setBusy(true)
    try {
      const res = await fetch("/api/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password })
      })

      if (!res.ok) {
        alert("Login failed. Please check your username and password")
        return
      }

      // Get the login
      const user = (await res.json()) as User
      props.onLogin(user)
    } catch (err: any) {
      alert("Unable to connect to server")
    } finally {
      setBusy(false)
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-8 offset-2">
          <div className="pb-2 mt-4 mb-4 border-bottom">
            <h3>Login</h3>
          </div>
          <div className="d-grid pb-1">
            <a className="btn btn-secondary" href="/login_oauth">
              <i className="fa fa-sign-in" /> Login as Staff
            </a>
          </div>
          <hr />
          <div className="card mb-3">
            <div className="card-header">Partner Login</div>
            <div className="card-body">
              <FormGroup label="Username" key="username">
                <input
                  className="form-control"
                  type="text"
                  value={username}
                  onChange={(ev) => {
                    setUsername(ev.target.value)
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key == "Enter") {
                      handleLogin()
                    }
                  }}
                />
              </FormGroup>
              <FormGroup label="Password" key="password">
                <input
                  className="form-control"
                  type="password"
                  value={password}
                  onChange={(ev) => {
                    setPassword(ev.target.value)
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key == "Enter") {
                      handleLogin()
                    }
                  }}
                />
              </FormGroup>
              <div className="d-grid pb-1">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleLogin}
                  disabled={busy || !username || !password}
                >
                  <i className="fa fa-sign-in" /> Login as Partner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
