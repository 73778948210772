import { Variable } from "@mwater/expressions"
import { ContextVar } from "@mwater/ui-builder"
import { User } from "./User"

/** Creates global variables which are present to expressions on the server for validation
 * These are only available in the table editor and widgets.
 */
export function createGlobalVariables(): Variable[] {
  const userVariable: Variable = {
    id: "login_user",
    type: "id",
    idTable: "wo_users",
    name: { _base: "en", en: "Login User" }
  }

  const userRolesVariable: Variable = {
    id: "login_user_roles",
    type: "id[]",
    idTable: "roles",
    name: { _base: "en", en: "Login User Roles" }
  }

  const userLocationsVariable: Variable = {
    id: "login_user_locations",
    type: "id[]",
    idTable: "countries",
    name: { _base: "en", en: "Login User Locations" }
  }

  return [userVariable, userRolesVariable, userLocationsVariable]
}

/** Creates global variable values which are present to expressions on the server for validation
 * These are only available in the table editor and are not available to widgets.
 */
export function createGlobalVariableValues(loginUser: User): { [variableId: string]: any } {
  return {
    login_user: { type: "literal", valueType: "id", idTable: "wo_users", value: loginUser.user_id },
    login_user_roles: { type: "literal", valueType: "id[]", idTable: "roles", value: loginUser.roles },
    login_user_locations: { type: "literal", valueType: "id[]", idTable: "countries", value: loginUser.locations }
  }
}

/** Context variables that are present to all widgets */
export function createGlobalContextVars(): ContextVar[] {
  return [
    { id: "login_user", type: "id", idTable: "wo_users", name: "Login User" },
    { id: "login_user_roles", type: "id[]", idTable: "roles", name: "Login User Roles" },
    { id: "login_user_locations", type: "id[]", idTable: "countries", name: "Login User Locations" },
    { id: "view_user", type: "id", idTable: "wo_users", name: "Current User" },
    { id: "view_user_roles", type: "id[]", idTable: "roles", name: "Current User Roles" },
    { id: "view_user_locations", type: "id[]", idTable: "countries", name: "Current User Locations" }
  ]
}

/** Context variable values that are present to all widgets */
export function createGlobalContextVarValues(loginUser: User, viewUser: User): { [contextVarId: string]: any } {
  return {
    login_user: { type: "literal", valueType: "id", idTable: "wo_users", value: loginUser.user_id },
    login_user_roles: { type: "literal", valueType: "id[]", idTable: "roles", value: loginUser.roles },
    login_user_locations: { type: "literal", valueType: "id[]", idTable: "countries", value: loginUser.locations },
    view_user: { type: "literal", valueType: "id", idTable: "wo_users", value: viewUser.user_id },
    view_user_roles: { type: "literal", valueType: "id[]", idTable: "roles", value: viewUser.roles },
    view_user_locations: { type: "literal", valueType: "id[]", idTable: "countries", value: viewUser.locations }
  }
}
