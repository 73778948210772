import React, { useCallback, useState } from "react"
import { Select } from "@mwater/react-library/lib/bootstrap"
import { ReportError, ReportStatus } from "../../../common/reports"
import { performJsonAPICall, useLoadData } from "../../api"
import Async from "react-select/async"
import { string } from "yargs"

/** Control that allows resolving of errors. Displayed for reports in Resolve Errors status */
export function ResolveErrorsControl(props: { reportStatus: ReportStatus; reload: () => void }) {
  const reportStatus = props.reportStatus

  // Load resolvable errors
  const [resolvableErrors, error, reloadResolvableErrors] = useLoadData<ReportError[]>("/api/get_report_errors", {
    controlId: reportStatus.control_id
  })

  // Store corrected values by error id (as a string). If not in the lookup, or value is null, it hasn't been corrected
  const [corrections, setCorrections] = useState<{ [errorId: string]: string | null }>({})

  // Get a list of regions
  const [regions] = useLoadData<{ value: string, label: string }[]>("/api/get_report_available_regions", { controlId: reportStatus.control_id })

  const [submitting, setSubmitting] = useState(false)

  if (!resolvableErrors || !regions) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div className="alert alert-danger">{error.message}</div>
  }

  async function handleReject() {
    if (!confirm("Request new upload?")) {
      return
    }
    try {
      await performJsonAPICall("/api/request_new_upload", { controlId: reportStatus.control_id, notes: "" })

      // Reload page
      window.location.reload()
    } catch (err: any) {
      alert(err.message)
    }
  }

  async function submitChanges() {
    setSubmitting(true)
    try {
      // Send corrections
      await performJsonAPICall("/api/fix_report_resolvable_errors", { controlId: reportStatus.control_id, corrections })

      // Reload report status
      props.reload()
    } catch (err: any) {
      alert("Error: " + err.message)
    } finally {
      setSubmitting(false)
    }
  }

  /** Render a single resolvable error */
  function renderResolvableError(error: ReportError) {
    return (
      <tr key={error.error_id}>
        <td style={{ padding: 10, width: "70%" }}>
          <span dangerouslySetInnerHTML={{ __html: error.error_message }} />
        </td>
        <td>
          <ResolvableErrorControl
            error={error}
            correctedValue={corrections[error.error_id + ""] || null}
            onCorrection={(value) => {
              setCorrections({ ...corrections, [error.error_id + ""]: value })
            }}
            regions={regions!}
          />
        </td>
      </tr>
    )
  }

  return (
    <div>
      <style>
        {`
        td {
          padding: 5px;
        }
      `}
      </style>
      <table className="upload">
        <tbody>
          <tr>
            <td className="text-end">
              <h4>Intervention</h4>
            </td>
            <td>
              {" "}
              {reportStatus.programid} {reportStatus.intervention_name}
            </td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Partner</h4>
            </td>
            <td> {reportStatus.partner_name}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Report Date</h4>
            </td>
            <td> {reportStatus.reportdate}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Control ID</h4>
            </td>
            <td> {reportStatus.control_id}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Report Type</h4>
            </td>
            <td> {reportStatus.upload_type}</td>
          </tr>
          <tr>
            <td className="text-end">
              <h4>Report Status</h4>
            </td>
            <td>
              {reportStatus.status} There were {resolvableErrors.length} unique error(s) found in your file. You may
              elect to resolve them here or cancel your upload
            </td>
          </tr>
        </tbody>
      </table>
      <p></p>
      <table>
        <tbody>{resolvableErrors.map(renderResolvableError)}</tbody>
      </table>
      <button type="button" className="btn btn-primary" onClick={submitChanges} disabled={submitting}>
        Submit Changes
      </button>
      &nbsp;
      <button type="button" className="btn btn-secondary" onClick={handleReject}>
        Request a new upload
      </button>
      { submitting ?
        <div className="alert alert-info">Submitting changes, please wait. This may take several minutes...</div>
      : null }
      {/* <pre>{JSON.stringify(resolvableErrors, null, 2)}</pre> */}
    </div>
  );
}

/** Control that allows fixing a resolvable error */
function ResolvableErrorControl(props: {
  error: ReportError

  /** Corrected value if one has been set */
  correctedValue: string | null

  /** Call to set a corrected value */
  onCorrection: (correctedValue: string | null) => void

  /** Regions for a region1 or region2 error */
  regions: { label: string; value: string }[]
}) {
  const error = props.error
  const corrected = props.correctedValue != null
  const value = corrected ? props.correctedValue : error.error_value
  const format = error.error_format

  if (format == "lookup") {
    if (
      error.error_column.toLowerCase() == "region1" ||
      error.error_column.toLowerCase() == "region2" ||
      error.error_column.toLowerCase() == "region3"
    ) {
      return <SelectRegionControl {...props} />
    }
    return (
      <Select
        value={value || null}
        onChange={props.onCorrection}
        options={error.acceptable_values.split("|").map((v) => ({ label: v, value: v }))}
        nullLabel="Select Value"
      />
    )
  } else if (format == "yob") {
    return (
      <input
        type="text"
        className="form-control"
        value={value || ""}
        onChange={(ev) => props.onCorrection(ev.target.value)}
      />
    )
  } else if (format == "alphanumeric") {
    return (
      <input
        type="text"
        className="form-control"
        value={value || ""}
        onChange={(ev) => props.onCorrection(ev.target.value)}
      />
    )
  } else if (format == "date") {
    // TODO
    return (
      <input
        type="text"
        className="form-control"
        value={value || ""}
        onChange={(ev) => props.onCorrection(ev.target.value)}
      />
    )
  } else if (format == "multi") {
    // TODO
    return (
      <Select
        value={value || null}
        onChange={props.onCorrection}
        options={error.acceptable_values.split("|").map((v) => ({ label: v, value: v }))}
        nullLabel="Select Value"
      />
    )
  } else if (format == "numeric") {
    // TODO
    return (
      <input
        type="text"
        className="form-control"
        value={value || ""}
        onChange={(ev) => props.onCorrection(ev.target.value)}
      />
    )
  } else if (format == "required") {
    // should never land here, if a column is required and not present the report goes into critical error status
  }

  return <div>{`Format ${format} not supported`}</div>
}

/** Control that allows fixing a resolvable error */
function SelectRegionControl(props: {
  error: ReportError

  /** Corrected value if one has been set */
  correctedValue: string | null

  /** Call to set a corrected value */
  onCorrection: (correctedValue: string | null) => void

  /** Regions for a region1 or region2 error */
  regions: { label: string; value: string }[]
}) {
  // Callback that react-select uses to get values
  const loadOptions = useCallback((input: string, callback: any) => {
    // Filter matches
    const matches = props.regions.filter((r) => r.label.toLowerCase().includes(input.toLowerCase())).slice(0, 200)
    callback(matches)
  }, [])

  /* Note: React select was unusably slow with 7000+ options, hence the async version */

  const currentValue = props.correctedValue ? props.regions.find((r) => r.value == props.correctedValue) : null
  return (
    <Async
      value={currentValue}
      placeholder="Type to search for replacement value"
      loadOptions={loadOptions}
      onChange={(ev) => {
        props.onCorrection(ev ? ev.value : null)
      }}
      defaultOptions={true}
      closeMenuOnScroll={true}
      menuPortalTarget={document.body}
    />
  )
}
