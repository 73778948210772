import { DataSourceDatabase, Transaction, Mutation } from "@mwater/ui-builder"
import { Schema, DataSource } from "@mwater/expressions"
import uuid from "uuid"
import { performJsonAPICall } from "./api"

/** Create a database that can be updated */
export function createDatabase(schema: Schema, dataSource: DataSource) {
  let database: DataSourceDatabase

  const transactionHandler = () => {
    return new ClientTransaction(database)
  }
  database = new DataSourceDatabase(schema, dataSource, transactionHandler)
  return database
}

/** Implements a transaction. Sends to server on commit */
class ClientTransaction implements Transaction {
  mutations: Mutation[]
  database: DataSourceDatabase

  constructor(database: DataSourceDatabase) {
    this.database = database
    this.mutations = []
  }

  addRow(table: string, values: { [column: string]: any }) {
    const primaryKey = uuid()

    this.mutations.push({
      type: "add",
      table: table,
      primaryKey: primaryKey,
      values: values
    })

    return Promise.resolve(primaryKey)
  }

  updateRow(table: string, primaryKey: any, updates: { [column: string]: any }) {
    this.mutations.push({
      type: "update",
      table: table,
      primaryKey: primaryKey,
      updates: updates
    })
    return Promise.resolve()
  }

  removeRow(table: string, primaryKey: any) {
    this.mutations.push({
      type: "remove",
      table: table,
      primaryKey: primaryKey
    })
    return Promise.resolve()
  }

  async commit() {
    const primaryKeys = await performJsonAPICall<any[]>("/api/perform_transaction", { mutations: this.mutations })
    this.database.refresh()
    return primaryKeys
  }
}
