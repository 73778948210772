import {
  DesignCtx,
  ControlBlockDef,
  ControlBlock,
  RenderControlProps,
  LabeledProperty,
  PropertyEditor
} from "@mwater/ui-builder"
import { default as React } from "react"
import { Column } from "@mwater/expressions"
import { Editor } from "@tinymce/tinymce-react"
import { Select } from "@mwater/react-library/lib/bootstrap"

/** Block that contains a TinyMCE component */
export interface TinyMCEBlockDef extends ControlBlockDef {
  type: "waterorg:tinyMCE"

  /** Height in pixels */
  height?: number
}

export class TinyMCEBlock extends ControlBlock<TinyMCEBlockDef> {
  constructor(blockDef: TinyMCEBlockDef) {
    super(blockDef)
  }

  renderControl(props: RenderControlProps) {
    return (
      <Editor
        key={`${this.blockDef.height || 200}`}
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        value={props.value}
        onEditorChange={props.onChange}
        init={{
          menubar: false,
          height: this.blockDef.height
        }}
      />
    )
  }

  renderControlEditor(props: DesignCtx) {
    return (
      <div>
        <LabeledProperty label="Height">
          <PropertyEditor obj={this.blockDef} onChange={props.store.replaceBlock} property="height">
            {(value, onChange) => (
              <Select
                value={value || 200}
                onChange={(height) => {
                  onChange(height!)
                }}
                options={[
                  { value: 100, label: "100px" },
                  { value: 150, label: "150px" },
                  { value: 200, label: "200px" },
                  { value: 250, label: "250px" },
                  { value: 300, label: "300px" },
                  { value: 400, label: "400px" }
                ]}
              />
            )}
          </PropertyEditor>
        </LabeledProperty>
      </div>
    )
  }

  filterColumn(column: Column): boolean {
    return column.type == "text"
  }
}
