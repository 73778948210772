import { DataSource } from "@mwater/expressions"

export function handleRowClick(dataSource: DataSource, tableId: string, rowId: any) {
  // Open intervention if an intervention is clicked
  if (tableId === "wo_programs") {
    window.open(`#/interventions/${rowId}`, "_blank")
  }

  // Open intervention if an intervention outcome is clicked
  if (tableId === "wo_program_outcomes") {
    // Look up intervention
    dataSource.performQuery({
      type: "query",
      selects: [
        {
          type: "select",
          expr: {
            type: "field",
            tableAlias: "main",
            column: "intervention"
          },
          alias: "intervention"
        }
      ],
      from: {
        type: "table",
        table: tableId,
        alias: "main"
      },
      where: {
        type: "op",
        op: "=",
        exprs: [
          {
            type: "field",
            tableAlias: "main",
            column: "id"
          },
          {
            type: "literal",
            value: rowId
          }
        ]
      }
    }, (error, rows) => {
      if (error || rows.length === 0) {
        alert("Error loading data");
        return;
      }
      window.open(`#/interventions/${rows[0].intervention}`, "_blank");
    })
  }

  if (tableId === "wop_funding_packages") {
    window.open(`#/funding_packages/${rowId}`, "_blank");
  }

  if (tableId === "wo_achibeneficiary") {
    // Look up intervention
    dataSource.performQuery({
      type: "query",
      selects: [
        {
          type: "select",
          expr: {
            type: "scalar",
            expr: {
              type: "field",
              tableAlias: "programs",
              column: "gid"
            },
            from: {
              type: "table",
              table: "wo_programs",
              alias: "programs"
            },
            where: {
              type: "op",
              op: "=",
              exprs: [
                {
                  type: "field",
                  tableAlias: "programs",
                  column: "programid"
                },
                {
                  type: "field",
                  tableAlias: "main",
                  column: "programid"
                }
              ]
            }
          },
          alias: "intervention"
        }
      ],
      from: {
        type: "table",
        table: tableId,
        alias: "main"
      },
      where: {
        type: "op",
        op: "=",
        exprs: [
          {
            type: "field",
            tableAlias: "main",
            column: "record_id"
          },
          {
            type: "literal",
            value: rowId
          }
        ]
      }
    }, (error, rows) => {
      if (error || rows.length === 0) {
        alert("Error loading data")
        return
      }
      window.open(`#/interventions/${rows[0].intervention}`, "_blank")
    });
  }

  if (tableId === "wco_loans_active") {
    // Look up intervention
    dataSource.performQuery({
      type: "query",
      selects: [
        {
          type: "select",
          expr: {
            type: "field",
            tableAlias: "main",
            column: "programid"
          },
          alias: "intervention"
        }
      ],
      from: {
        type: "table",
        table: tableId,
        alias: "main"
      },
      where: {
        type: "op",
        op: "=",
        exprs: [
          {
            type: "field",
            tableAlias: "main",
            column: "loans_seq_id"
          },
          {
            type: "literal",
            value: rowId
          }
        ]
      }
    }, (error, rows) => {
      if (error || rows.length === 0) {
        alert("Error loading data")
        return
      }
      window.open(`#/interventions/${rows[0].intervention}`, "_blank")
    })
  }
}
