//import React, { useId, useMemo, useState } from "react"
import React, { CSSProperties, useMemo, useState } from "react"
import ActionCancelModal from "@mwater/react-library/lib/ActionCancelModalComponent"
import { FormGroup, TextInput, Toggle } from "@mwater/react-library/lib/bootstrap"
import { SiteDesign } from "../common/SiteDesign"
import { performJsonAPICall, useLoadData } from "./api"
import { User } from "../common/User"
import { createSchema } from "../common/utils"
import { createDatabase } from "./ClientDatabase"
import { ClientDataSource } from "./ClientDataSource"
import _ from "lodash"

/** Modal that allows tagging a user */
export function UserTaggingModal(props: {
  onClose: () => void
  onUserList: (viewUser: User) => void
  loginUser: User
  viewUser: User
  siteDesign: SiteDesign
}) {

  const [tag_severity, setSeverity] = useState<"Urgent" | "Nonurgent" | null>("Nonurgent")
  const [message, setMessage] = useState("")
  const [username, setUsername] = useState<string | null>(null)
  const [sending, setSending] = useState(false)

  // Create schema, data source and database
  const schema = useMemo(() => createSchema(props.siteDesign), [])
  const dataSource = useMemo(() => new ClientDataSource(), [])
  const database = useMemo(() => createDatabase(schema, dataSource), [])

  // Position the modal
  const [posX, setPosX] = useState(window.innerWidth - 660)
  const [posY, setPosY] = useState(80)

  const [isDragging, setIsDragging] = useState(false)
  const [dragStartX, setDragStartX] = useState(0)
  const [dragStartY, setDragStartY] = useState(0)

  const handleDragStart = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
    setDragStartX(event.clientX - posX)
    setDragStartY(event.clientY - posY)
  }

  const handleDragMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      setPosX(event.clientX - dragStartX)
      setPosY(event.clientY - dragStartY)
    }
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  // Load list of all users
  const [allUsers] = useLoadData<User[]>("/api/get_crossover_users")

  async function handleSend() {
    try {
      setSending(true)

      //console.log('uername is ' + username);
      // Create record to add
      const txn = database.transaction()
      await txn.addRow("wop_notifications", {
        notification_date: new Date().toISOString().slice(0, 10),
        tag_severity: tag_severity,
        severity: 'Tags',
        type: 'Tagging',
        status: 'Tagging',
        category: 'User Tag',
        message,
        username,  //verify this is getting set
        notified_by: props.loginUser.user_id,
        url: window.location.href,
        debug_info: ""
      })
      await txn.commit()
      props.onClose()
    } catch (err: any) {
      alert("Error tagging a user: " + err.message)
    } finally {
      setSending(false)
    }
  }

  const modalStyle: CSSProperties = {
    position: 'fixed',
    zIndex: 1000,
    width: '600px',
    top: `${posY}px`,
    left: `${posX}px`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)'
  }

  return (
    <div className="modal-content" style={modalStyle}>
      <div 
        className="modal-header"
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      >
        <h5 className="modal-title">Tag a User</h5>
        <button type="button" className="btn-close" aria-label="Close" onClick={props.onClose}></button>
      </div>
      <div className="modal-body">
        <div className="usertagmodal">
          <div className="usertagmodal-dialog">
            <FormGroup label="User">
              <div className="row">
                <div className="col">
                  <UserListControl loginUser={props.loginUser} allUsers={allUsers} onUserList={props.onUserList} />
                </div>
                <div className="col">
                  <Toggle options={
                    [
                      { value: "Urgent", label: "Urgent" },
                      { value: "Nonurgent", label: "Nonurgent" }
                    ]}
                    size="lg"
                    value={tag_severity} onChange={setSeverity}
                  />
                </div>
              </div>
            </FormGroup>

            <FormGroup label="Tag Details">
              <textarea
                className="form-control"
                value={message}
                onChange={(ev) => setMessage(ev.target.value)}
                rows={5}
                placeholder={`What action would you like the user to take?`}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={props.onClose}>Cancel</button>
        <button type="button" className="btn btn-primary" onClick={handleSend} disabled={sending}>
          {sending ? "Sending..." : "Submit Tag"}
        </button>
      </div>
    </div>
  )

  /** Crossover control */
  function UserListControl(props: {
    loginUser: User
    allUsers: User[] | undefined
    onUserList: (viewUser: User) => void
  }) {

    const users = _.sortBy(props.allUsers || [], 'name') || []

    return (
      <select value={username || ""} name="username" className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={e => setUsername(e.target.value)}>
        <option>Please select a user to tag.</option>
        {users.map((user) => (
          <option key={user.user_id} value={user.user_id}>
            {user.name}
          </option>
        ))}
      </select>
    )

  }

}
