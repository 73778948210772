import _ from "lodash"
import moment from "moment"
import React from "react"
import { useEffect, useMemo, useState } from "react"
import LoadingComponent from "@mwater/react-library/lib/LoadingComponent"
import { Database } from "@mwater/ui-builder"
import { SiteDesign } from "../../../common/SiteDesign"
import { createSchema } from "../../../common/utils"
import { createDatabase } from "../../ClientDatabase"
import { ClientDataSource } from "../../ClientDataSource"
import { Checkbox, NumberInput, Toggle } from "@mwater/react-library/lib/bootstrap"
import "./ImpactTrackerPage.css"
import { DateControl } from "./DateControl"
import produce from "immer"
import { performJsonAPICall } from "../../api"
import {
  ImpactTrackerSummary,
  Actual,
  Forecast,
  Target,
  Snapshot,
  SnapshotData,
  Period,
  calculatePeriods
} from "../../../common/impactTracker"
import { User } from "../../../common/User"

type LoanType = "water" | "sanitation" | "water & sanitation" | "water quality"

/** Impact tracker page that allows setting targets and forecasts */
export function ImpactTrackerPage(props: {
  siteDesign: SiteDesign
  interventionId: number
  partnerId: number
  snapshot?: string
  loginUser: User
}) {
  const [dateMode, setDateMode] = useState<"month" | "quarter">("quarter")

  const [snapshots, setSnapshots] = useState<Snapshot[]>()

  const [summaryData, setSummaryData] = useState<ImpactTrackerSummary>()

  // How differences are computed
  const [differenceMode, setDifferenceMode] = useState<"target" | "forecast">("forecast")

  // Create schema, data source and database
  const schema = useMemo(() => createSchema(props.siteDesign), [])
  const dataSource = useMemo(() => new ClientDataSource(), [])
  const database = useMemo(() => createDatabase(schema, dataSource), [])

  // Error message if error
  const [loadError, setLoadError] = useState<string>()

  const [forecasts, setForecasts] = useState<Forecast[]>()
  const [targets, setTargets] = useState<Target[]>()
  const [actuals, setActuals] = useState<Actual[]>()
  const [impactModel, setImpactModel] = useState<ImpactModel>()
  const [intervention, setIntervention] = useState<Intervention>()
  const [countryAttributes, setCountryAttributes] = useState<CountryAttribute[]>()
  const [currency, setCurrency] = useState<"local" | "usd">("local")

  /** Changed forecast values (loans number indexed by start date of month e.g. 2020-03-01) */
  const [changedForecastLoans, setChangedForecastLoans] = useState<{ [date: string]: number }>({})

  /** Changed target values (loans number indexed by start date of month e.g. 2020-03-01) */
  const [changedTargetLoans, setChangedTargetLoans] = useState<{ [date: string]: number }>({})

  /** Changed start date */
  const [changedStartDate, setChangedStartDate] = useState<string>()

  /** Changed end date */
  const [changedEndDate, setChangedEndDate] = useState<string>()

  /** Changed average household size */
  const [changedAverageHouseholdSize, setChangedAverageHouseholdSize] = useState<number>()

  /** Changed average loan size */
  const [changedAverageLoanSize, setChangedAverageLoanSize] = useState<number>()

  /** Which edit mode enabled */
  const [editMode, setEditMode] = useState<"targets" | "forecasts">()

  /** Which top area mode (summary or forecast) */
  const [topAreaMode, setTopAreaMode] = useState<"summary" | "forecast">("summary")

  /** Which periods are expanded, indexed by startDate */
  const [expandedPeriods, setExpandedPeriods] = useState<{ [startDate: string]: boolean }>({})

  /** Whether historical data is hidden */
  const [hideHistorical, setHideHistorical] = useState(false)

  /** True when saving */
  const [saving, setSaving] = useState(false)

  // Whether impact adjustment is allowed (only Admin or Insight Admin)
  const allowImpactAdjustment = props.loginUser.roles.includes(2) || props.loginUser.roles.includes(9)

  function refreshSnapshots() {
    performJsonAPICall<Snapshot[]>("/api/get_impact_tracker_snapshots", {
      interventionId: props.interventionId,
      partnerId: props.partnerId
    })
      .then((r) => setSnapshots(r))
      .catch((error) => setLoadError("Error loading snapshots"))
  }

  useEffect(refreshSnapshots, [])

  function refreshData() {
    setActuals(undefined)
    setForecasts(undefined)
    setTargets(undefined)
    setSummaryData(undefined)
    setIntervention(undefined)
    setImpactModel(undefined)
    setCountryAttributes(undefined)

    if (props.snapshot) {
      // Get all data from snapshot
      performJsonAPICall<SnapshotData>("/api/get_impact_tracker_snapshot_data", {
        interventionId: props.interventionId,
        partnerId: props.partnerId,
        snapshot: props.snapshot
      })
        .then((results) => {
          console.log(results)
          setActuals(results.actuals)
          setTargets(results.targets)
          setForecasts(results.forecasts)
        })
        .catch((error) => setLoadError("Error loading summary"))
    } else {
      getActuals(database, props.interventionId, props.partnerId)
        .then((results) => setActuals(results))
        .catch((error) => setLoadError("Error loading actuals"))

      getForecasts(database, props.interventionId, props.partnerId)
        .then((results) => setForecasts(results))
        .catch((error) => setLoadError("Error loading forecast"))

      getTargets(database, props.interventionId, props.partnerId)
        .then((results) => setTargets(results))
        .catch((error) => setLoadError("Error loading targets"))
    }

    performJsonAPICall<ImpactTrackerSummary>("/api/get_impact_tracker_summary", {
      interventionId: props.interventionId,
      partnerId: props.partnerId,
      snapshot: props.snapshot
    })
      .then((results) => setSummaryData(results))
      .catch((error) => setLoadError("Error loading summary"))

    getImpactModel(database, props.interventionId, props.partnerId)
      .then((result) => setImpactModel(result))
      .catch((error) => setLoadError("Error loading impact model"))

    getIntervention(database, props.interventionId)
      .then((result) => setIntervention(result))
      .catch((error) => setLoadError("Error loading intervention"))

    getCountryAttributes(database, props.partnerId)
      .then((result) => setCountryAttributes(result))
      .catch((error) => setLoadError("Error loading country attributes"))
  }

  // Load data
  useEffect(() => {
    refreshData()
  }, [props.interventionId, props.partnerId, props.snapshot])

  /** Gets the target for a period (summing up if quarter) */
  function getTarget(period: Period): Target {
    let loans = 0
    let people = 0
    let capital = 0

    // Get averages
    const averageHouseholdSize =
      changedAverageHouseholdSize != null ? changedAverageHouseholdSize : impactModel!.averageHouseholdSize
    const averageLoanSize = changedAverageLoanSize != null ? changedAverageLoanSize : impactModel!.averageLoanSize

    // For each month in period
    for (const month of period.months) {
      // Check for override
      if (changedTargetLoans[month]) {
        loans += changedTargetLoans[month]
        people += changedTargetLoans[month] * averageHouseholdSize
        capital += changedTargetLoans[month] * averageLoanSize
      } else {
        // Find match
        const match = targets!.find((t) => t.month == month)
        if (match) {
          loans += match.loans || 0
          // If average size changed, use that
          people +=
            changedAverageHouseholdSize != null ? (match.loans || 0) * changedAverageHouseholdSize : match.people || 0
          capital += changedAverageLoanSize != null ? (match.loans || 0) * changedAverageLoanSize : match.capital || 0
        }
      }
    }

    // Create aggregate target
    return {
      month: period.start,
      loans,
      people,
      capital
    }
  }

  /** Gets the forecast for a period (summing up if quarter) */
  function getForecast(period: Period): Forecast {
    let loans = 0
    let people = 0
    let capital = 0

    // Get averages
    const averageHouseholdSize =
      changedAverageHouseholdSize != null ? changedAverageHouseholdSize : impactModel!.averageHouseholdSize
    const averageLoanSize = changedAverageLoanSize != null ? changedAverageLoanSize : impactModel!.averageLoanSize

    // For each month in period
    for (const month of period.months) {
      // Check for override
      if (changedForecastLoans[month]) {
        loans += changedForecastLoans[month]
        people += changedForecastLoans[month] * averageHouseholdSize
        capital += changedForecastLoans[month] * averageLoanSize
      } else {
        // Find match
        const match = forecasts!.find((t) => t.month == month)
        if (match) {
          loans += match.loans || 0
          // If average size changed, use that
          people +=
            changedAverageHouseholdSize != null ? (match.loans || 0) * changedAverageHouseholdSize : match.people || 0
          capital += changedAverageLoanSize != null ? (match.loans || 0) * changedAverageLoanSize : match.capital || 0
        }
      }
    }

    // Create aggregate forecast
    return {
      month: period.start,
      loans,
      people,
      capital
    }
  }

  /** Gets the actual for a period (summing up if quarter) */
  function getActual(period: Period, loanType: LoanType | null): Actual {
    // Find all in period
    let matches = actuals!.filter((f) => f.month >= period.start && f.month <= period.end)

    // Filter by loan type
    if (loanType == "water") {
      matches = matches.filter((a) => [2, 16, 23, 25].includes(a.beneficiaryType!))
    } else if (loanType == "water & sanitation") {
      matches = matches.filter((a) => [5, 6, 18, 19].includes(a.beneficiaryType!))
    } else if (loanType == "sanitation") {
      matches = matches.filter((a) => [3, 4, 17, 21].includes(a.beneficiaryType!))
    } else if (loanType == "water quality") {
      matches = matches.filter((a) => [13, 20].includes(a.beneficiaryType!))
    }

    // Create aggregate actual
    return {
      month: period.start,
      loans: _.sum(matches.map((f) => f.loans)),
      people: _.sum(matches.map((f) => f.people)),
      capital: _.sum(matches.map((f) => f.capital))
    }
  }

  /** Force recalculation of all forecast */
  function recalculateAllForecasts() {
    // Indicate that all forecasts and targets have changed
    setChangedForecastLoans(produce(changedForecastLoans, (draft) => {
      for (const forecast of forecasts!) {
        // If present and not already changed
        if (changedForecastLoans[forecast.month] == null && forecast.loans != null) {
          draft[forecast.month] = forecast.loans
        }
      }
    }))
  }

  /** Force recalculation of all target */
  function recalculateAllTargets() {
    setChangedTargetLoans(produce(changedTargetLoans, (draft) => {
      for (const target of targets!) {
        // If present and not already changed
        if (changedTargetLoans[target.month] == null && target.loans != null) {
          draft[target.month] = target.loans
        }
      }
    }))
  }

  /** Changing this requires recalculating all forecasts and targets */
  function changeAverageHouseholdSize(value: number | undefined) {
    setChangedAverageHouseholdSize(value)

    // No value means reset
    if (value == null) {
      return
    }

    // All calculations need to be redone 
    recalculateAllForecasts()
    recalculateAllTargets()
  }

  /** Changing this requires recalculating all forecasts and targets */
  function changeAverageLoanSize(value: number | undefined) {
    setChangedAverageLoanSize(value)

    // No value means reset
    if (value == null) {
      return
    }

    // All calculations need to be redone 
    recalculateAllForecasts()
    recalculateAllTargets()
  }

  /** Reset all changes */
  function resetChanges() {
    setEditMode(undefined)
    setChangedForecastLoans({})
    setChangedTargetLoans({})
    setChangedStartDate(undefined)
    setChangedEndDate(undefined)
    setChangedAverageHouseholdSize(undefined)
    setChangedAverageLoanSize(undefined)
    setTopAreaMode("summary")
  }

  async function handleSave() {
    // First create a snapshot (https://github.com/water-dot-org/new-water-portal/issues/682)
    await createSnapshot("Before saving changes")

    // Create new impact model to update
    let newImpactModel: ImpactModel | null = null

    // Get averages
    const averageHouseholdSize =
      changedAverageHouseholdSize != null ? changedAverageHouseholdSize : impactModel!.averageHouseholdSize
    const averageLoanSize = changedAverageLoanSize != null ? changedAverageLoanSize : impactModel!.averageLoanSize

    // If changed or no id (needs creation)
    if (
      !impactModel!.id ||
      changedStartDate ||
      changedEndDate ||
      changedAverageLoanSize ||
      changedAverageHouseholdSize
    ) {
      newImpactModel = {
        ...impactModel,
        startDate: changedStartDate || impactModel!.startDate,
        endDate: changedEndDate || impactModel!.endDate,
        averageLoanSize,
        averageHouseholdSize
      }
    }
    
    // Reload forecasts and targets
    const forecasts = await getForecasts(database, props.interventionId, props.partnerId)
    const targets = await getTargets(database, props.interventionId, props.partnerId)

    // Gather all changes to forecasts
    const changedForecasts: Forecast[] = []
    for (const month in changedForecastLoans) {
      const existingForecast = forecasts!.find((f) => f.month == month)
      changedForecasts.push({
        id: existingForecast ? existingForecast.id : undefined,
        month,
        loans: changedForecastLoans[month],
        people: changedForecastLoans[month] * averageHouseholdSize,
        capital: changedForecastLoans[month] * averageLoanSize
      })
    }

    // Gather all changes to targets
    const changedTargets: Target[] = []
    for (const month in changedTargetLoans) {
      const existingTarget = targets!.find((f) => f.month == month)
      changedTargets.push({
        id: existingTarget ? existingTarget.id : undefined,
        month,
        loans: changedTargetLoans[month],
        people: changedTargetLoans[month] * averageHouseholdSize,
        capital: changedTargetLoans[month] * averageLoanSize
      })
    }

    try {
      setSaving(true)
      await saveChanges(
        database,
        intervention!,
        props.partnerId,
        newImpactModel,
        changedForecasts,
        changedTargets
      )
      resetChanges()
      refreshData()
    } catch (error: any) {
      alert("Error saving changes: " + error.message)
    }
    finally {
      setSaving(false)
    }
  }

  function handleCancel() {
    if (!confirm("Cancel changes?")) {
      return
    }
    resetChanges()
  }

  function handleCreateSnapshot() {
    const notes = prompt("Enter optional notes for snapshot")
    if (notes == null) {
      return
    }

    createSnapshot(notes).then(() => {
      alert("Successfully created snapshot")
    }).catch((error) => alert("Failed to create snapshot: " + error.message))
}

  async function createSnapshot(notes: string) {
    await performJsonAPICall("/api/create_impact_tracker_snapshot", {
      interventionId: props.interventionId,
      partnerId: props.partnerId,
      notes
    })

    refreshSnapshots()
  }

  if (loadError) {
    return <div className="alert alert-danger">{loadError}</div>
  }

  if (!actuals || !forecasts || !targets || !impactModel || !intervention || !summaryData || !snapshots || !countryAttributes) {
    return <LoadingComponent />
  }

  /** Render edit/save/cancel links and buttons */
  function renderEditSave() {
    if (props.snapshot) {
      return null
    }

    function editForecasts() {
      setEditMode("forecasts")
      setTopAreaMode("forecast")
    }

    function editTargets() {
      setEditMode("targets")
      setTopAreaMode("forecast")
    }

    if (!editMode) {
      return (
        <div key="edit" style={{ textAlign: "right" }}>
          <button type="button" className="btn btn-link" onClick={editForecasts}>
            <i className="fa fa-pencil" /> Edit Forecasts
          </button>
          &nbsp;
          <button type="button" className="btn btn-link" onClick={editTargets}>
            <i className="fa fa-pencil" /> Edit Targets
          </button>
        </div>
      )
    }

    return (
      <div key="save" style={{ textAlign: "right" }}>
        <button type="button" className="btn btn-primary" onClick={handleSave} disabled={saving}>
          Save Changes
        </button>
        &nbsp;
        <button type="button" className="btn btn-secondary" onClick={handleCancel}>
          Cancel Changes
        </button>
      </div>
    );
  }

  function renderTopSummaryTable() {
    return (
      <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: 0 }}>
        <thead>
          <tr>
            <th />
            <th className="right-align">Number of Loans</th>
            <th className="right-align">People Reached</th>
            <th className="right-align">Capital Mobilized Local</th>
            <th className="right-align">Capital Mobilized USD</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Targets (all)</td>
            <td className="right-align">{formatInt(summaryData!.targetLoans)}</td>
            <td className="right-align">{formatOneDecimal(summaryData!.targetPeople)}</td>
            <td className="right-align">{formatCurrency(summaryData!.targetCapital)}</td>
            <td className="right-align">{formatCurrency(summaryData!.targetCapitalUsd)}</td>
          </tr>
          <tr>
            <td>Targets (to date)</td>
            <td className="right-align">{formatInt(summaryData!.targetToDateLoans)}</td>
            <td className="right-align">{formatOneDecimal(summaryData!.targetToDatePeople)}</td>
            <td className="right-align">{formatCurrency(summaryData!.targetToDateCapital)}</td>
            <td className="right-align">{formatCurrency(summaryData!.targetToDateCapitalUsd)}</td>
          </tr>
          <tr>
            <td>Actuals (to date)</td>
            <td className="right-align">{formatInt(summaryData!.actualLoans)}</td>
            <td className="right-align">{formatOneDecimal(summaryData!.actualPeople)}</td>
            <td className="right-align">{formatCurrency(summaryData!.actualCapital)}</td>
            <td className="right-align">{formatCurrency(summaryData!.actualCapitalUsd)}</td>
          </tr>
          {summaryData!.disqualLoans
            ? [
              <tr>
                <td>Disqualifications</td>
                <td className="right-align red">{formatInt(summaryData!.disqualLoans)}</td>
                <td className="right-align red">{formatOneDecimal(summaryData!.disqualPeople)}</td>
                <td className="right-align red">{formatCurrency(summaryData!.disqualCapital)}</td>
                <td className="right-align red">{formatCurrency(summaryData!.disqualCapitalUsd)}</td>
              </tr>,
              <tr>
                <td>Adjusted Actuals</td>
                <td className="right-align">{formatInt(summaryData!.actualLoans + summaryData!.disqualLoans)}</td>
                <td className="right-align">
                  {formatOneDecimal(summaryData!.actualPeople + summaryData!.disqualPeople)}
                </td>
                <td className="right-align">
                  {formatCurrency(summaryData!.actualCapital + summaryData!.disqualCapital)}
                </td>
                <td className="right-align">
                  {formatCurrency(summaryData!.actualCapitalUsd + summaryData!.disqualCapitalUsd)}
                </td>
              </tr>
            ]
            : null}
          <tr>
            <td>% Achieved against Targets</td>
            <td className="right-align">
              {formatPercentAchieved(
                summaryData!.actualLoans + (summaryData!.disqualLoans || 0),
                summaryData!.targetLoans
              )}
            </td>
            <td className="right-align">
              {formatPercentAchieved(
                summaryData!.actualPeople + (summaryData!.disqualPeople || 0),
                summaryData!.targetPeople
              )}
            </td>
            <td className="right-align">
              {formatPercentAchieved(
                summaryData!.actualCapital + (summaryData!.disqualCapital || 0),
                summaryData!.targetCapital
              )}
            </td>
            <td className="right-align">
              {formatPercentAchieved(
                summaryData!.actualCapitalUsd + (summaryData!.disqualCapitalUsd || 0),
                summaryData!.targetCapitalUsd
              )}
            </td>
          </tr>
          <tr>
            <td>Calculation: Targets - Actuals</td>
            <td className="right-align">
              {formatInt(summaryData!.targetLoans - (summaryData!.actualLoans + (summaryData!.disqualLoans || 0)))}
            </td>
            <td className="right-align">
              {formatOneDecimal(
                summaryData!.targetPeople - (summaryData!.actualPeople + (summaryData!.disqualPeople || 0))
              )}
            </td>
            <td className="right-align">
              {formatCurrency(
                summaryData!.targetCapital - (summaryData!.actualCapital + (summaryData!.disqualCapital || 0))
              )}
            </td>
            <td className="right-align">
              {formatCurrency(
                summaryData!.targetCapitalUsd - (summaryData!.actualCapitalUsd + (summaryData!.disqualCapitalUsd || 0))
              )}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  /** Render box that contains either table or editable */
  function renderTopArea() {
    if (topAreaMode == "summary") {
      return (
        <div className="top-area">
          <div key="header" style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
            <h4>WaterCredit Portfolio Impact Summary</h4>
            <a onClick={() => setTopAreaMode("forecast")}>Forecast Tool</a>
          </div>
          {renderTopSummaryTable()}
        </div>
      )
    } else {
      return (
        <div className="top-area">
          <div key="header" style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
            <h4>Forecast Tool</h4>
            <a onClick={() => setTopAreaMode("summary")}>View Impact Summary</a>
          </div>
          <div>
            Please click on the Edit icons in top right corner, enter your forecast target data, edit data and select
            save.
          </div>
          <div
            key="edits"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              justifyItems: "right",
              alignItems: "center",
              columnGap: 10,
              rowGap: 5
            }}
          >
            <div>Impact Start Date:</div>
            <DateControl
              value={changedStartDate || impactModel!.startDate}
              onChange={editMode != null ? setChangedStartDate : undefined}
            />
            <div>Impact End Date:</div>
            <DateControl
              value={changedEndDate || impactModel!.endDate}
              onChange={editMode != null ? setChangedEndDate : undefined}
            />
            <div>Average User / Household Size per Loan:</div>
            <NumberInput
              value={
                changedAverageHouseholdSize != null ? changedAverageHouseholdSize : impactModel!.averageHouseholdSize
              }
              onChange={editMode != null ? (value) => changeAverageHouseholdSize(value || undefined) : undefined}
              decimal={true}
            />
            <div>Average Loan Amount (Local Currency):</div>
            <NumberInput
              value={changedAverageLoanSize != null ? changedAverageLoanSize : impactModel!.averageLoanSize}
              onChange={editMode != null ? (value) => changeAverageLoanSize(value || undefined) : undefined}
              decimal={true}
            />
          </div>
          {editMode == "forecasts" ?
            <button className="btn btn-secondary btn-sm" onClick={recalculateAllForecasts}>Recalculate All Forecasts</button>
            : null}
          {editMode == "targets" ?
            <button className="btn btn-secondary btn-sm" onClick={recalculateAllTargets}>Recalculate All Targets</button>
            : null}
        </div>
      );
    }
  }

  // Determine periods (from start and end date)
  const periods = calculatePeriods(
    changedStartDate || impactModel.startDate,
    changedEndDate || impactModel.endDate,
    dateMode
  )

  /** Render either value or editable box */
  function renderTargetLoans(period: Period) {
    // Simple case
    if (editMode != "targets") {
      return formatInt(getTarget(period).loans)
    }

    function handleChange(value: number | null) {
      if (value == null) {
        setChangedTargetLoans(
          produce(changedTargetLoans, (draft) => {
            // Clear targets
            for (const month of period.months) {
              delete draft[month]
            }
          })
        )
      } else {
        setChangedTargetLoans(
          produce(changedTargetLoans, (draft) => {
            // Spread over months
            const splitValue = Math.round(value / period.months.length)
            for (const month of period.months) {
              draft[month] = splitValue
            }

            // Last month needs to handle remainder
            const remainder = value - (splitValue * period.months.length)
            draft[period.months[period.months.length - 1]] += remainder
            draft[period.months[period.months.length - 1]] = Math.round(draft[period.months[period.months.length - 1]])
          })
        )
      }
    }

    return <NumberInput decimal={true} value={getTarget(period).loans} onChange={handleChange} />
  }

  /** Render either value or editable box */
  function renderForecastLoans(period: Period) {
    // Simple case (past or not editable)
    if (editMode != "forecasts" || period.end < new Date().toISOString()) {
      return formatInt(getForecast(period).loans)
    }

    function handleChange(value: number | null) {
      if (value == null) {
        setChangedForecastLoans(
          produce(changedForecastLoans, (draft) => {
            // Clear targets
            for (const month of period.months) {
              delete draft[month]
            }
          })
        )
      } else {
        setChangedForecastLoans(
          produce(changedForecastLoans, (draft) => {
            // Spread over months
            const splitValue = Math.round(value / period.months.length)
            for (const month of period.months) {
              draft[month] = splitValue
            }

            // Last month needs to handle remainder
            const remainder = value - (splitValue * period.months.length)
            draft[period.months[period.months.length - 1]] += remainder
            draft[period.months[period.months.length - 1]] = Math.round(draft[period.months[period.months.length - 1]])
          })
        )
      }
    }

    return <NumberInput decimal={true} value={getForecast(period).loans} onChange={handleChange} />
  }

  function formatLocalOrUsdCurrency(value: number | null, period: Period) {
    if (value == null) {
      return null
    }

    if (currency == "local") {
      return formatCurrency(value)
    } 
    
    // Get exchange rate
    let exchrate = null

    const year = parseInt(period.start.substr(0, 4))

    for (const countryAttribute of countryAttributes!) {
      if (countryAttribute.country_year <= year) {
        if (period.start.substring(5, 7) <= "06") {
          exchrate = countryAttribute.exchrate
        }
        else {
          exchrate = countryAttribute.exchrate2
        }
        break
      }
    }

    if (exchrate == null) {
      return "NO EXCHANGE RATE"
    }

    return "$" + formatCurrency(value / exchrate)
  }

  /** Render the Yx Mx plus range */
  function renderPeriodDateSummary(period: Period) {
    const startDate = changedStartDate || impactModel!.startDate

    if (dateMode == "month") {
      const month =
        moment(period.start, "YYYY-MM-DD").diff(moment(startDate, "YYYY-MM-DD").startOf("month"), "months") + 1
      return (
        <div key="date" className="period-summary">
          <div key="m">M{month}</div>
          <div key="range">{moment(period.start, "YYYY-MM-DD").format("MMM YY")}</div>
        </div>
      )
    } else {
      const year = parseInt(period.start.substr(0, 4)) - parseInt(startDate.substr(0, 4)) + 1
      const quarter = moment(period.start, "YYYY-MM-DD").quarter()
      return (
        <div key="date" className="period-summary">
          <div key="ym">
            Y{year} Q{quarter}
          </div>
          <div key="range">
            {moment(period.start, "YYYY-MM-DD").format("MMM YY")} - {moment(period.end, "YYYY-MM-DD").format("MMM YY")}
          </div>
        </div>
      )
    }
  }

  function renderCollapsedPeriod(period: Period) {
    // TODO keys
    return [
      <tr key={period.start + ":header"}>
        <td className="subheader">Period / Date</td>
        <td className="subheader" />
        <td className="subheader">Target</td>
        <td className="subheader">Forecast</td>
        <td className="subheader">Actuals</td>
        <td className="subheader">Difference</td>
        <td />
        <td className="subheader">Target</td>
        <td className="subheader">Forecast</td>
        <td className="subheader">Actuals</td>
        <td className="subheader">Difference</td>
        <td />
        <td className="subheader">Target</td>
        <td className="subheader">Forecast</td>
        <td className="subheader">Actuals</td>
        <td className="subheader">Difference</td>
      </tr>,
      <tr key={period.start + ":data"} className="period-bottom">
        <td className="period">
          {!props.snapshot ? (
            <a
              style={{ verticalAlign: "top", marginRight: 5 }}
              onClick={() => setExpandedPeriods({ ...expandedPeriods, [period.start]: true })}
            >
              <i className="fa fa-plus-circle" />
            </a>
          ) : null}
          {renderPeriodDateSummary(period)}
        </td>
        <td className="border-right" />
        <td className="number">{renderTargetLoans(period)}</td>
        <td className="number">{renderForecastLoans(period)}</td>
        <td className="number">{formatInt(getActual(period, null).loans)}</td>
        <td className="number">
          {formatInt(
            getActual(period, null).loans -
            ((differenceMode == "forecast" ? getForecast(period).loans : getTarget(period).loans) || 0)
          )}
        </td>
        <td className="gap" />
        <td className="number border-left">{formatOneDecimal(getTarget(period).people)}</td>
        <td className="number">{formatOneDecimal(getForecast(period).people)}</td>
        <td className="number">{formatOneDecimal(getActual(period, null).people)}</td>
        <td className="number">
          {formatOneDecimal(
            getActual(period, null).people -
            ((differenceMode == "forecast" ? getForecast(period).people : getTarget(period).people) || 0)
          )}
        </td>
        <td className="gap" />
        <td className="number border-left">{formatLocalOrUsdCurrency(getTarget(period).capital, period)}</td>
        <td className="number">{formatLocalOrUsdCurrency(getForecast(period).capital, period)}</td>
        <td className="number">{formatLocalOrUsdCurrency(getActual(period, null).capital, period)}</td>
        <td className="number">
          {formatLocalOrUsdCurrency(
            getActual(period, null).capital -
            ((differenceMode == "forecast" ? getForecast(period).capital : getTarget(period).capital) || 0)
          , period)}
        </td>
      </tr>
    ]
  }

  function renderExpandedPeriod(period: Period) {
    // TODO keys
    return [
      <tr key={period.start + ":header"}>
        <td className="subheader">Period / Date</td>
        <td className="subheader" />
        <td className="subheader">Target</td>
        <td className="subheader">Forecast</td>
        <td className="subheader">Actuals</td>
        <td className="subheader">Difference</td>
        <td />
        <td className="subheader">Target</td>
        <td className="subheader">Forecast</td>
        <td className="subheader">Actuals</td>
        <td className="subheader">Difference</td>
        <td />
        <td className="subheader">Target</td>
        <td className="subheader">Forecast</td>
        <td className="subheader">Actuals</td>
        <td className="subheader">Difference</td>
      </tr>,
      <tr key={period.start + ":water"}>
        <td rowSpan={5} className="period">
          <a
            style={{ verticalAlign: "top", marginRight: 5 }}
            onClick={() => setExpandedPeriods({ ...expandedPeriods, [period.start]: false })}
          >
            <i className="fa fa-minus-circle" />
          </a>
          {renderPeriodDateSummary(period)}
        </td>
        <td className="border-right">Water</td>
        <td className="number" />
        <td className="number" />
        <td className="number">{formatInt(getActual(period, "water").loans)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatOneDecimal(getActual(period, "water").people)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatLocalOrUsdCurrency(getActual(period, "water").capital, period)}</td>
        <td className="number" />
      </tr>,
      <tr key={period.start + ":sanitation"}>
        <td className="border-right">Sanitation</td>
        <td className="number" />
        <td className="number" />
        <td className="number">{formatInt(getActual(period, "sanitation").loans)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatOneDecimal(getActual(period, "sanitation").people)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatLocalOrUsdCurrency(getActual(period, "sanitation").capital, period)}</td>
        <td className="number" />
      </tr>,
      <tr key={period.start + ":waterandsanitation"}>
        <td className="border-right">Water &amp; Sanitation</td>
        <td className="number" />
        <td className="number" />
        <td className="number">{formatInt(getActual(period, "water & sanitation").loans)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatOneDecimal(getActual(period, "water & sanitation").people)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatLocalOrUsdCurrency(getActual(period, "water & sanitation").capital, period)}</td>
        <td className="number" />
      </tr>,
      <tr key={period.start + ":waterquality"}>
        <td className="border-right">Water Quality</td>
        <td className="number" />
        <td className="number" />
        <td className="number">{formatInt(getActual(period, "water quality").loans)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatOneDecimal(getActual(period, "water quality").people)}</td>
        <td className="number" />
        <td />
        <td className="number border-left" />
        <td className="number" />
        <td className="number">{formatLocalOrUsdCurrency(getActual(period, "water quality").capital, period)}</td>
        <td className="number" />
      </tr>,
      <tr key={period.start + ":data"} className="period-bottom">
        <td className="border-right">Subtotal</td>
        <td className="number">{renderTargetLoans(period)}</td>
        <td className="number">{renderForecastLoans(period)}</td>
        <td className="number">{formatInt(getActual(period, null).loans)}</td>
        <td className="number">
          {formatInt(
            getActual(period, null).loans -
            ((differenceMode == "forecast" ? getForecast(period).loans : getTarget(period).loans) || 0)
          )}
        </td>
        <td className="gap" />
        <td className="number border-left">{formatOneDecimal(getTarget(period).people)}</td>
        <td className="number">{formatOneDecimal(getForecast(period).people)}</td>
        <td className="number">{formatOneDecimal(getActual(period, null).people)}</td>
        <td className="number">
          {formatOneDecimal(
            getActual(period, null).people -
            ((differenceMode == "forecast" ? getForecast(period).people : getTarget(period).people) || 0)
          )}
        </td>
        <td className="gap" />
        <td className="number border-left">{formatLocalOrUsdCurrency(getTarget(period).capital, period)}</td>
        <td className="number">{formatLocalOrUsdCurrency(getForecast(period).capital, period)}</td>
        <td className="number">{formatLocalOrUsdCurrency(getActual(period, null).capital, period)}</td>
        <td className="number">
          {formatLocalOrUsdCurrency(
            getActual(period, null).capital -
            ((differenceMode == "forecast" ? getForecast(period).capital : getTarget(period).capital) || 0)
            , period)
          }
        </td>
      </tr>
    ]
  }

  function renderBigTable(visiblePeriods: Period[]) {
    return (
      <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: 0 }}>
        <tbody>
          <tr key="header 1">
            <td colSpan={2} />
            <td colSpan={4} className="rounded-header">
              {dateMode == "quarter" ? "Quarterly View" : "Monthly View"}
            </td>
            <td style={{ width: 10 }} />
            <td colSpan={4} className="rounded-header">
              {dateMode == "quarter" ? "Quarterly View" : "Monthly View"}
            </td>
            <td style={{ width: 10 }} />
            <td colSpan={4} className="rounded-header">
              {dateMode == "quarter" ? "Quarterly View" : "Monthly View"}
            </td>
          </tr>
          <tr key="header 2">
            <td colSpan={2}></td>
            <th colSpan={4} className="second-header">
              Number of Loans
            </th>
            <th />
            <th colSpan={4} className="second-header">
              People Reached
            </th>
            <th />
            <th colSpan={4} className="second-header">
              Capital Mobilized
            </th>
          </tr>
          {visiblePeriods.map((period) =>
            expandedPeriods[period.start] && !props.snapshot
              ? renderExpandedPeriod(period)
              : renderCollapsedPeriod(period)
          )}
        </tbody>
      </table>
    )
  }

  function renderSnapshots() {
    // Hide if editing
    if (editMode) {
      return null
    }

    if (props.snapshot) {
      return (
        <a
          href={`#/impact_tracker?intervention=${props.interventionId}&partner=${props.partnerId}`}
          className="btn btn-primary"
        >
          Return To Current Targets
        </a>
      )
    }

    return (
      <div>
        <div className="btn-group">
          <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
            View Target History
          </button>
          <ul className="dropdown-menu">
            {snapshots!.map((snapshot) => {
              return (
                <li>
                  <a
                    href={`#/impact_tracker?intervention=${props.interventionId}&partner=${props.partnerId}&snapshot=${snapshot.id}`}
                    className="dropdown-item"
                  >
                    {snapshot.date}
                    <br />
                    {snapshot.notes}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        &nbsp;
        <button type="button" className="btn btn-secondary" onClick={handleCreateSnapshot}>
          Create Snapshot
        </button>
        &nbsp;
        {allowImpactAdjustment && (
        <a
          className="btn btn-secondary"
          href={`#/impact_adjustments?intervention=${props.interventionId}&partner=${props.partnerId}`}
        >
          Impact Adjustment
          </a>
        )}
      </div>
    );
  }

  const snapshot = snapshots.find((s) => s.id == props.snapshot)

  // Add border if snapshot
  return (
    <div style={{ border: props.snapshot ? "dashed 3px #AAA" : undefined, padding: props.snapshot ? 10 : undefined }}>
      <h2>
        WaterCredit Portfolio Report Impact Tracking (from partner uploads): {intervention.code}
        {snapshot ? (
          <span>
            {" "}
            Viewing {snapshot.notes} {snapshot.date}
          </span>
        ) : null}
      </h2>
      <div>
        The purpose of this page is to track the actual impact achieved against targets and forecasts. Water.org staff
        make updates to the targets and remaining forecasts on this page and monitor the impact as it is achieved. The
        data in this page only pertains to the WaterCredit portfolio report uploads. If you have impact that will come
        in from outside the WaterCredit Portfolio Report upload, that impact must be entered in the Manual Impact Table.
      </div>
      {renderSnapshots()}
      {renderEditSave()}
      {renderTopArea()}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto 1fr auto auto",
          paddingBottom: 10,
          alignItems: "center",
          gap: 10
        }}
      >
        <Toggle
          value={dateMode}
          size="sm"
          allowReset={false}
          onChange={(v) => setDateMode(v as "month" | "quarter")}
          options={[
            { value: "quarter", label: "View By Quarter" },
            { value: "month", label: "View By Month" }
          ]}
        />
        <Checkbox value={hideHistorical} onChange={setHideHistorical}>
          Hide Historical Data
        </Checkbox>
        <div className="text-muted">Historical forecast data may not be edited</div>
        <div />
        <Toggle
          value={currency}
          size="sm"
          allowReset={false}
          onChange={(v) => setCurrency(v as "local" | "usd")}
          options={[
            { value: "local", label: "Local Currency" },
            { value: "usd", label: "USD" }
          ]}
        />
        <Toggle
          value={differenceMode}
          size="sm"
          allowReset={false}
          onChange={(v) => setDifferenceMode(v as "forecast" | "target")}
          options={[
            { value: "forecast", label: "View Differences by Forecast" },
            { value: "target", label: "View Differences by Targets" }
          ]}
        />
      </div>
      {hideHistorical ? null : renderBigTable(periods.filter((p) => p.end < new Date().toISOString()))}
      <br />
      {renderBigTable(periods.filter((p) => p.end >= new Date().toISOString()))}
    </div>
  )
}

/** Gets actual achieved results, excluding double-counting */
async function getActuals(database: Database, interventionId: number, partnerId: number): Promise<Actual[]> {
  // Sum by month actuals
  return (await database.query(
    {
      select: {
        month: {
          type: "op",
          op: "yearmonth",
          table: "wo_achibeneficiary",
          exprs: [{ type: "field", table: "wo_achibeneficiary", column: "dateachieved" }]
        },
        beneficiaryType: { type: "field", table: "wo_achibeneficiary", column: "beneficiarytypeid" },
        loans: {
          type: "op",
          op: "sum",
          table: "wo_achibeneficiary",
          exprs: [{ type: "field", table: "wo_achibeneficiary", column: "wssloans" }]
        },
        people: {
          type: "op",
          op: "sum",
          table: "wo_achibeneficiary",
          exprs: [{ type: "field", table: "wo_achibeneficiary", column: "achieved" }]
        },
        capital: {
          type: "op",
          op: "sum",
          table: "wo_achibeneficiary",
          exprs: [{ type: "field", table: "wo_achibeneficiary", column: "principal_local" }]
        }
      },
      from: "wo_achibeneficiary",
      where: {
        type: "op",
        table: "wo_achibeneficiary",
        op: "and",
        exprs: [
          {
            type: "op",
            op: "=",
            table: "wo_achibeneficiary",
            exprs: [
              { type: "field", table: "wo_achibeneficiary", column: "programid" },
              { type: "literal", valueType: "id", idTable: "wo_programs", value: interventionId }
            ]
          },
          {
            type: "op",
            op: "=",
            table: "wo_achibeneficiary",
            exprs: [
              { type: "field", table: "wo_achibeneficiary", column: "partner" },
              { type: "literal", valueType: "id", idTable: "wo_partnerorgs", value: partnerId }
            ]
          },
          {
            type: "op",
            op: "=",
            table: "wo_achibeneficiary",
            exprs: [
              { type: "field", table: "wo_achibeneficiary", column: "actualorprojected" },
              { type: "literal", valueType: "enum", value: "true" }
            ]
          },
          // Exclude double counting
          {
            type: "op",
            op: "or",
            table: "wo_achibeneficiary",
            exprs: [
              {
                type: "op",
                op: "=",
                table: "wo_achibeneficiary",
                exprs: [
                  { type: "field", table: "wo_achibeneficiary", column: "adjustment" },
                  { type: "literal", valueType: "enum", value: "disqual" }
                ]
              },
              {
                type: "op",
                op: "is null",
                table: "wo_achibeneficiary",
                exprs: [{ type: "field", table: "wo_achibeneficiary", column: "adjustment" }]
              }
            ]
          },
          // Exclude manual
          {
            type: "op",
            op: "<>",
            table: "wo_achibeneficiary",
            exprs: [
              { type: "field", table: "wo_achibeneficiary", column: "loantype" },
              { type: "literal", valueType: "enum", value: "M" }
            ]
          }
        ]
      }
    },
    [],
    {}
  )) as Actual[]
}

/** Loads forecast achieved results */
async function getForecasts(database: Database, interventionId: number, partnerId: number): Promise<Forecast[]> {
  // Get forecasts
  return (await database.query(
    {
      select: {
        id: { type: "id", table: "wo_achibeneficiary" },
        month: {
          type: "op",
          table: "wo_achibeneficiary",
          op: "yearmonth",
          exprs: [{ type: "field", table: "wo_achibeneficiary", column: "dateachieved" }]
        },
        loans: { type: "field", table: "wo_achibeneficiary", column: "wssloans" },
        people: { type: "field", table: "wo_achibeneficiary", column: "achieved" },
        capital: { type: "field", table: "wo_achibeneficiary", column: "principal_local" }
      },
      from: "wo_achibeneficiary",
      where: {
        type: "op",
        table: "wo_achibeneficiary",
        op: "and",
        exprs: [
          {
            type: "op",
            op: "=",
            table: "wo_achibeneficiary",
            exprs: [
              { type: "field", table: "wo_achibeneficiary", column: "programid" },
              { type: "literal", valueType: "id", idTable: "wo_programs", value: interventionId }
            ]
          },
          {
            type: "op",
            op: "=",
            table: "wo_achibeneficiary",
            exprs: [
              { type: "field", table: "wo_achibeneficiary", column: "partner" },
              { type: "literal", valueType: "id", idTable: "wo_partnerorgs", value: partnerId }
            ]
          },
          {
            type: "op",
            op: "=",
            table: "wo_achibeneficiary",
            exprs: [
              { type: "field", table: "wo_achibeneficiary", column: "actualorprojected" },
              { type: "literal", valueType: "enum", value: "false" }
            ]
          },
          // Now no longer exclude manual forecasts
          // // Exclude manual
          // {
          //   type: "op",
          //   op: "is null",
          //   table: "wo_achibeneficiary",
          //   exprs: [
          //     { type: "field", table: "wo_achibeneficiary", column: "loantype" }
          //   ]
          // }
        ]
      }
    },
    [],
    {}
  )) as Forecast[]
}

/** Loads impact model data */
async function getImpactModel(database: Database, interventionId: number, partnerId: number): Promise<ImpactModel> {
  const impactModel = (
    await database.query(
      {
        select: {
          id: { type: "id", table: "wo_planbeneficiary_model" },
          startDate: { type: "field", table: "wo_planbeneficiary_model", column: "startdate" },
          endDate: { type: "field", table: "wo_planbeneficiary_model", column: "enddate" },
          averageLoanSize: { type: "field", table: "wo_planbeneficiary_model", column: "averageloansize" },
          averageHouseholdSize: { type: "field", table: "wo_planbeneficiary_model", column: "householdsize" }
        },
        from: "wo_planbeneficiary_model",
        where: {
          type: "op",
          table: "wo_planbeneficiary_model",
          op: "and",
          exprs: [
            {
              type: "op",
              op: "=",
              table: "wo_planbeneficiary_model",
              exprs: [
                { type: "field", table: "wo_planbeneficiary_model", column: "programid" },
                { type: "literal", valueType: "id", idTable: "wo_programs", value: interventionId }
              ]
            },
            {
              type: "op",
              op: "=",
              table: "wo_planbeneficiary_model",
              exprs: [
                { type: "field", table: "wo_planbeneficiary_model", column: "partner" },
                { type: "literal", valueType: "id", idTable: "wo_partnerorgs", value: partnerId }
              ]
            }
          ]
        }
      },
      [],
      {}
    )
  )[0] as ImpactModel

  // If none found, use intervention data
  if (!impactModel) {
    const intervention = await getIntervention(database, interventionId)
    return {
      startDate: intervention.startDate,
      endDate: intervention.endDate,
      averageHouseholdSize: 0,
      averageLoanSize: 0
    }
  }
  return impactModel
}

/** Gets targets */
async function getTargets(database: Database, interventionId: number, partnerId: number): Promise<Target[]> {
  return (await database.query(
    {
      select: {
        id: { type: "id", table: "wo_planbeneficiary" },
        month: {
          type: "op",
          op: "yearmonth",
          table: "wo_planbeneficiary",
          exprs: [{ type: "field", table: "wo_planbeneficiary", column: "dateplanned" }]
        },
        loans: { type: "field", table: "wo_planbeneficiary", column: "loans" },
        people: { type: "field", table: "wo_planbeneficiary", column: "planned" },
        capital: { type: "field", table: "wo_planbeneficiary", column: "capital" }
      },
      from: "wo_planbeneficiary",
      where: {
        type: "op",
        table: "wo_planbeneficiary",
        op: "and",
        exprs: [
          {
            type: "op",
            op: "=",
            table: "wo_planbeneficiary",
            exprs: [
              { type: "field", table: "wo_planbeneficiary", column: "programid" },
              { type: "literal", valueType: "id", idTable: "wo_programs", value: interventionId }
            ]
          },
          {
            type: "op",
            op: "=",
            table: "wo_planbeneficiary",
            exprs: [
              { type: "field", table: "wo_planbeneficiary", column: "partner" },
              { type: "literal", valueType: "id", idTable: "wo_partnerorgs", value: partnerId }
            ]
          }
        ]
      }
    },
    [],
    {}
  )) as Target[]
}

async function getIntervention(database: Database, interventionId: number): Promise<Intervention> {
  return (
    await database.query(
      {
        select: {
          id: { type: "id", table: "wo_programs" },
          code: { type: "field", table: "wo_programs", column: "programid" },
          startDate: { type: "field", table: "wo_programs", column: "startdate" },
          endDate: { type: "field", table: "wo_programs", column: "enddate" },
          primaryCountry: { type: "field", table: "wo_programs", column: "primary_country" }
        },
        from: "wo_programs",
        where: {
          type: "op",
          table: "wo_programs",
          op: "=",
          exprs: [
            { type: "id", table: "wo_programs" },
            { type: "literal", valueType: "id", idTable: "wo_programs", value: interventionId }
          ]
        }
      },
      [],
      {}
    )
  )[0] as Intervention
}

/** Gets exchange rates by year for the first country of the partner. 
 * Puts them in descending order by year.
 */
async function getCountryAttributes(database: Database, partnerId: number): Promise<CountryAttribute[]> {
  // Get country id
  const countryId = (await database.query(
    {
      select: {
        country: { type: "field", table: "wo_partnerorgs", column: "_first_country" }
      }, 
      from: "wo_partnerorgs",
      where: {
        type: "op",
        table: "wo_partnerorgs",
        op: "=",
        exprs: [
          { type: "id", table: "wo_partnerorgs" },
          { type: "literal", valueType: "id", idTable: "wo_partnerorgs", value: partnerId }
        ]
      }
    }, 
    [], 
    {}
  ))[0].country

  return (await database.query(
    {
      select: {
        country_year: { type: "field", table: "wop_country_attrib", column: "country_year" },
        exchrate: { type: "field", table: "wop_country_attrib", column: "exchrate" },
        exchrate2: { type: "field", table: "wop_country_attrib", column: "exchrate2" }
      },
      from: "wop_country_attrib",
      where: {
        type: "op",
        table: "wop_country_attrib",
        op: "=",
        exprs: [
          { type: "field", table: "wop_country_attrib", column: "country_id" },
          { type: "literal", valueType: "id", idTable: "countries", value: countryId }
        ]
      },
      orderBy: [
        {expr: {type: "field", table: "wop_country_attrib", column: "country_year"}, dir: "desc"}
      ]
    }, 
    [], 
    {}
  )) as CountryAttribute[]
}

/** Intervention information */
interface Intervention {
  /** gid of the intervention */
  id: number

  code: string

  startDate: string

  endDate: string

  /** Primary country of the intervention */
  primaryCountry: number
}

/** Fields from wo_planbeneficiary_model */
interface ImpactModel {
  /** Primary key in wo_planbeneficiary_model. undefined if not inserted yet TODO */
  id?: number

  startDate: string

  endDate: string

  averageLoanSize: number

  averageHouseholdSize: number
}

/** Information about exchange rates by year for the first country of the partner. */
interface CountryAttribute {
  country_year: number
  exchrate: number
  exchrate2: number
}

function formatInt(value: number | null) {
  if (value == null) {
    return ""
  }
  return Number(value).toLocaleString("en", { maximumFractionDigits: 0 })
}

function formatOneDecimal(value: number | null) {
  if (value == null) {
    return ""
  }
  return Number(value).toLocaleString("en", { maximumFractionDigits: 1 })
}

function formatCurrency(value: number | null) {
  if (value == null) {
    return ""
  }
  return Number(value).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

function formatPercentAchieved(value: number | null, target: number | null) {
  if (value == null || target == null) {
    return ""
  }

  const result = (value * 100) / target
  return Number(result).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%"
}

/** Save changes to database */
async function saveChanges(
  database: Database,
  intervention: Intervention,
  partnerId: number,
  newImpactModel: ImpactModel | null,
  changedForecasts: Forecast[],
  changedTargets: Target[]
) {
  const txn = database.transaction()

  if (newImpactModel) {
    // Update if exists
    if (newImpactModel.id) {
      await txn.updateRow("wo_planbeneficiary_model", newImpactModel.id, {
        startdate: newImpactModel.startDate,
        enddate: newImpactModel.endDate,
        averageloansize: newImpactModel.averageLoanSize,
        householdsize: newImpactModel.averageHouseholdSize
      })
    } else {
      await txn.addRow("wo_planbeneficiary_model", {
        programid: intervention.id,
        partner: partnerId,
        startdate: newImpactModel.startDate,
        enddate: newImpactModel.endDate,
        averageloansize: newImpactModel.averageLoanSize,
        householdsize: newImpactModel.averageHouseholdSize
      })
    }
  }

  for (const forecast of changedForecasts) {
    // Find existing forecasts for this partner, program, and date
    const existingForecasts = await database.query({
      select: {
        id: { type: "id", table: "wo_achibeneficiary" }
      },
      from: "wo_achibeneficiary",
      where: {
        type: "op",
        table: "wo_achibeneficiary",
        op: "and",
        exprs: [
          { type: "op", op: "=", table: "wo_achibeneficiary", exprs: [
            { type: "field", table: "wo_achibeneficiary", column: "programid" },
            { type: "literal", valueType: "id", idTable: "wo_programs", value: intervention.id }
          ]},
          { type: "op", op: "=", table: "wo_achibeneficiary", exprs: [
            { type: "field", table: "wo_achibeneficiary", column: "partner" },
            { type: "literal", valueType: "id", idTable: "wo_partnerorgs", value: partnerId }
          ]},
          { type: "op", op: "=", table: "wo_achibeneficiary", exprs: [
            { type: "field", table: "wo_achibeneficiary", column: "actualorprojected" },
            { type: "literal", valueType: "enum", value: "false" }
          ]},
          { type: "op", op: "=", table: "wo_achibeneficiary", exprs: [
            { type: "field", table: "wo_achibeneficiary", column: "dateachieved" },
            { type: "literal", valueType: "date", value: forecast.month }
          ]}
        ]
      },
    }, [], {})

    // Delete existing forecasts
    for (const existingForecast of existingForecasts) {
      console.log("Deleting forecast", existingForecast.id)
      await txn.removeRow("wo_achibeneficiary", existingForecast.id)
    }

    // Add the new forecast row
    await txn.addRow("wo_achibeneficiary", {
      programid: intervention.id,
      partner: partnerId,
      actualorprojected: "false",
      dateachieved: forecast.month,
      wssloans: forecast.loans,
      achieved: forecast.people,
      principal_local: forecast.capital,
      beneficiarytypeid: 0, // WSS Forecast Loans
      improvement_where: 11, // Unknown
      country: intervention.primaryCountry,
    })
  }

  for (const target of changedTargets) {
    // Query existing targets
    const existingTargets = await database.query({
      select: {
        id: { type: "id", table: "wo_planbeneficiary" }
      },
      from: "wo_planbeneficiary",
      where: {
        type: "op",
        table: "wo_planbeneficiary",
        op: "and",
        exprs: [
          { type: "op", op: "=", table: "wo_planbeneficiary", exprs: [
            { type: "field", table: "wo_planbeneficiary", column: "programid" },
            { type: "literal", valueType: "id", idTable: "wo_programs", value: intervention.id }
          ]},
          { type: "op", op: "=", table: "wo_planbeneficiary", exprs: [
            { type: "field", table: "wo_planbeneficiary", column: "partner" },
            { type: "literal", valueType: "id", idTable: "wo_partnerorgs", value: partnerId }
          ]},
          { type: "op", op: "=", table: "wo_planbeneficiary", exprs: [
            { type: "field", table: "wo_planbeneficiary", column: "dateplanned" },
            { type: "literal", valueType: "date", value: target.month }
          ]}
        ]
      },
    }, [], {})

    // Delete existing targets
    for (const existingTarget of existingTargets) {
      console.log("Deleting target", existingTarget.id)
      await txn.removeRow("wo_planbeneficiary", existingTarget.id)
    }

    // Add the new target row
    await txn.addRow("wo_planbeneficiary", {
      programid: intervention.id,
      partner: partnerId,
      dateplanned: target.month,
      loans: target.loans,
      planned: target.people,
      capital: target.capital,
      beneficiarytypeid: 0 // WSS Forecast Loans
    })
  }

  await txn.commit()
}
