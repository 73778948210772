import _ from "lodash"
import uuid from "uuid"
import React from "react"

import FillDownwardComponent from "@mwater/react-library/lib/FillDownwardComponent"
import { DirectDashboardDataSource, DashboardComponent, JsonQLFilter, DashboardDesign } from "@mwater/visualization"
import ReorderableListComponent from "@mwater/react-library/lib/reorderable/ReorderableListComponent"
import { Schema, DataSource } from "@mwater/expressions"

interface TabDesign {
  id: string
  name: string
  design: DashboardDesign
}

export interface TabbedDashboardDesign {
  tabs: TabDesign[]
}

/** Multiple tabbed dashboards. Uses only direct dashboard data source which it creates internally.
 */
export class TabbedDashboardComponent extends React.Component<{
  apiUrl: string

  design: TabbedDashboardDesign
  /** If not set, readonly */
  onDesignChange?: (design: TabbedDashboardDesign) => void

  schema: Schema
  dataSource: DataSource

  /** Optional height in pixels or CSS */
  height?: any
  /** Current tab */
  tabId?: string

  /** Called with new tab id */
  onTabIdChange: (tabId: string) => void

  /** Extra buttons to add to title */
  extraTitleButtons?: React.ReactNode[]

  /** optional array of filters */
  filters?: JsonQLFilter[]

  /** Called with (tableId, rowId) when item is clicked */
  onRowClick?: (tableId: string, rowId: any) => void
}> {
  // Replaces blank tabId with first tab
  getActiveTabId = () => {
    var ref
    return this.props.tabId || ((ref = this.props.design.tabs[0]) != null ? ref.id : void 0)
  }

  handleDesignChange = (index: number, design: DashboardDesign) => {
    var tabs
    tabs = this.props.design.tabs.slice()
    tabs[index] = _.extend({}, tabs[index], {
      design: design
    })
    this.props.onDesignChange!(
      _.extend({}, this.props.design, {
        tabs: tabs
      })
    )
  }

  handleAddTab = () => {
    var tab, tabs
    tabs = this.props.design.tabs.slice()
    // Add new dashboard
    tab = {
      id: uuid(),
      name: "Untitled",
      design: {
        items: {
          id: "root",
          type: "root",
          blocks: []
        },
        layout: "blocks"
      }
    } as TabDesign
    tabs.push(tab)
    this.props.onDesignChange!(
      _.extend({}, this.props.design, {
        tabs: tabs
      })
    )
    // Select new tab
    this.props.onTabIdChange(tab.id)
  }

  handleRemoveTab = (index: number, ev: React.MouseEvent<HTMLSpanElement>) => {
    var tabs
    // Prevent tab click from hitting
    ev.stopPropagation()
    if (!confirm("Permanently remove this tab? This cannot be undone!")) {
      return
    }
    tabs = this.props.design.tabs.slice()
    tabs.splice(index, 1)
    this.props.onDesignChange!(
      _.extend({}, this.props.design, {
        tabs: tabs
      })
    )
  }

  handleReorder = (tabs: TabDesign[]) => {
    var base
    if (this.props.onDesignChange) {
      this.props.onDesignChange(
        _.extend({}, this.props.design, {
          tabs: tabs.filter((t) => t.id != "_add")
        })
      )
    }
  }

  handleTabChange = (tab: TabDesign, newTab: TabDesign) => {
    var tabIndex, tabs

    // Find index of tab being changed
    tabIndex = _.indexOf(this.props.design.tabs, tab)
    tabs = this.props.design.tabs.slice()
    tabs[tabIndex] = newTab
    this.props.onDesignChange!(
      _.extend({}, this.props.design, {
        tabs: tabs
      })
    )
  }

  handleTabClick = (tab: TabDesign) => {
    var name, newTab
    // If already on tab, rename if editable
    if (tab.id === this.getActiveTabId() && this.props.onDesignChange != null) {
      name = window.prompt("Enter new name for tab", tab.name)
      if (name) {
        newTab = { ...tab, name }
        return this.handleTabChange(tab, newTab)
      }
    } else {
      // Select tab
      return this.props.onTabIdChange(tab.id)
    }
  }

  handleCopyTab = (tab: TabDesign) => {
    // Copy to local storage clipboard
    return window.localStorage.setItem("clipboard.dashboard", JSON.stringify(tab))
  }

  handlePasteTab = (tab: TabDesign) => {
    var newTab
    newTab = JSON.parse(window.localStorage.getItem("clipboard.dashboard")!)
    // Set old id
    newTab.id = tab.id
    // Replace tab
    return this.handleTabChange(tab, newTab)
  }

  canPasteTab = (tab: TabDesign) => {
    var ref, ref1
    // Can paste if has clipboard and empty tab and can change
    return (
      this.props.onDesignChange != null &&
      window.localStorage.getItem("clipboard.dashboard") &&
      ((ref = tab.design.items) != null ? ((ref1 = ref.blocks) != null ? ref1.length : void 0) : void 0) === 0
    )
  }

  renderTab = (
    tab: TabDesign,
    index: number,
    connectDragSource: any,
    connectDragPreview: any,
    connectDropTarget: any
  ) => {
    var active
    // Special case
    if (tab.id === "_add") {
      return (
        <li key="_add" className="nav-item">
          <a className="nav-link" onClick={this.handleAddTab}>
            <span className="fas fa-plus" />
          </a>
        </li>
      );
    }

    // Determine if active
    active = this.getActiveTabId() === tab.id
    return connectDragPreview(
      connectDropTarget(
        connectDragSource(
          <li key={tab.id} className="nav-item">
            <a className={active ? "nav-link active" : "nav-link"} onClick={this.handleTabClick.bind(null, tab)} style={{ cursor: active ? "text" : "pointer" }}>
              {tab.name}
              {active && this.props.onDesignChange ? (
                <span
                  className="text-muted"
                  onClick={this.handleRemoveTab.bind(null, index)}
                  style={{ cursor: "pointer", paddingLeft: 5 }}
                >
                  <span className="fa fa-times" />
                </span>
              ) : null}
            </a>
          </li>
        )
      )
    )
  }

  renderTabs() {
    return (
      <div>
        <ReorderableListComponent
          items={
            this.props.onDesignChange != null
              ? this.props.design.tabs.concat({ id: "_add", name: "", design: undefined as any as DashboardDesign } as TabDesign)
              : this.props.design.tabs
          }
          onReorder={this.handleReorder}
          renderItem={this.renderTab}
          getItemId={(item) => item.id}
          listId="console-tabs"
          key="tabs"
          element={<ul key="tabs" className="nav nav-tabs" style={{ marginBottom: 10 }} />}
        />
      </div>
    )
  }

  // Render tab content of active tab
  renderTabContent() {
    var elem, extraTitleButtonsElem, tab, tabIndex

    // Get active tab
    tabIndex = _.findIndex(this.props.design.tabs, {
      id: this.getActiveTabId()
    })

    if (tabIndex === -1) {
      return null
    }

    tab = this.props.design.tabs[tabIndex]

    // Add copy/paste functionality
    extraTitleButtonsElem = this.props.extraTitleButtons || []
    extraTitleButtonsElem.push(
      <a key="copytab" className="btn btn-link btn-sm" onClick={this.handleCopyTab.bind(null, tab)}>
        <span className="fas fa-copy" /> Copy Tab
      </a>
    )

    if (this.canPasteTab(tab)) {
      extraTitleButtonsElem.push(
        <a key="pastetab" className="btn btn-link btn-sm" onClick={this.handlePasteTab.bind(null, tab)}>
          <span className="fas fa-paste" /> Paste Tab
        </a>
      )
    }

    elem = (
      <DashboardComponent
        design={tab.design}
        onDesignChange={this.props.onDesignChange ? this.handleDesignChange.bind(null, tabIndex) : undefined}
        schema={this.props.schema}
        dataSource={this.props.dataSource}
        dashboardDataSource={
          new DirectDashboardDataSource({
            schema: this.props.schema,
            dataSource: this.props.dataSource,
            apiUrl: this.props.apiUrl
          })
        }
        extraTitleButtonsElem={extraTitleButtonsElem}
        filters={this.props.filters}
        onRowClick={this.props.onRowClick}
      />
    )

    if (this.props.height) {
      elem = (
        <div style={{ height: this.props.height }} key={tab.id}>
          {elem}
        </div>
      )
    } else {
      elem = <FillDownwardComponent key={tab.id}>{elem}</FillDownwardComponent>
    }
    return elem
  }

  render() {
    return (
      <div>
        {this.renderTabs()}
        {this.renderTabContent()}
      </div>
    )
  }
}

// function MyInterventionsToggle(props: {
//   onlyMyInterventions: boolean
//   onChange: (onlyMyInterventions: boolean) => void
// }) {
//   return <div style={{ float: "right" }}>
//     <div className="btn-group">
//       <button type="button" className={ props.onlyMyInterventions ? "btn btn-primary active" : "btn btn-default"} onClick={() => props.onChange(true)}>
//         Only My Interventions
//       </button>
//       <button type="button" className={ props.onlyMyInterventions ? "btn btn-primary active" : "btn btn-default"} onClick={() => props.onChange(false)}>
//         "All Interventions"
//       </button>
//     </div>
//   </div>
// }
