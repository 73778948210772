import { DataSource, Row } from "@mwater/expressions"
import { JsonQLQuery } from "@mwater/jsonql"
import { performJsonAPICall } from "./api"
import LRU from 'lru-cache'
import canonicalJson from "canonical-json"
import { queue, QueueObject } from "async"

/** DataSource that uses API and caches results */
export class ClientDataSource extends DataSource {
  cache: LRU<string, Row[]>
  queue: QueueObject<{ query: JsonQLQuery, cb: (error: any, rows?: Row[]) => void }>

  constructor() {
    super()
    
    // Create query cache (cache by canonical string. each item contains rows)
    this.cache = new LRU({
      max: 100,
      maxAge: 5 * 60 * 1000
    })

    // Create queue for processing requests to the server
    this.queue = queue<{ query: JsonQLQuery, cb: (error: any, rows?: Row[]) => void }>((task, taskCb) => {
      this.callServer(task.query)
        .then((rows) => {
          task.cb(null, rows)
          taskCb()
        })
        .catch((err) => { 
          task.cb(err)
          taskCb()
        })
    }, 4)
  }

  performQuery(query: JsonQLQuery): Promise<Row[]>
  performQuery(query: JsonQLQuery, cb: (error: any, rows: Row[]) => void): void
  performQuery(query: JsonQLQuery, cb?: (error: any, rows: Row[]) => void): Promise<Row[]> | void {
    if (!cb) {
      return this.internalPerformQuery(query)
    } 
    this.performQuery(query)
      .then((rows) => {
        cb(null, rows)
      })
      .catch((err) => cb(err, []))
  }

  private async internalPerformQuery(query: JsonQLQuery): Promise<Row[]> {
    const key = canonicalJson(query)

    // Check cache first
    let rows = this.cache.get(key)
    if (rows) {
      return rows
    }

    // Add query to queue
    return new Promise((resolve, reject) => {
      this.queue.push({ query, cb: (err, rows) => {
        if (err) {
          reject(err)
        } else {
          resolve(rows!)
        }
      }})
    })
  }

  /** Call server to get rows */
  private async callServer(query: JsonQLQuery): Promise<Row[]> {
    const key = canonicalJson(query)
    const rows = await performJsonAPICall<Row[]>("/api/jsonql_query", { jsonql: query })
    this.cache.set(key, rows)
    return rows
  }

  getImageUrl(imageId: string, height: number): string {
    const apiUrl = "https://api.mwater.co/v3/"

    const url = height ? apiUrl + `images/${imageId}?h=${height}` : apiUrl + `images/${imageId}`
    return url
  }

  getImageUploadUrl(imageId: string): string {
    const apiUrl = "https://api.mwater.co/v3/"

    const url = apiUrl + `images/${imageId}`
    return url
  }

  clearCache(): void {
    this.cache.reset()
  }

  getCacheExpiry(): number {
    return 0
  }
}
